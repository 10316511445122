// @flow

import './PromotedPosts.scss'
import * as React from "react";
import {map, keys} from 'lodash';
import PromotedPost from "./PromotedPost";
import {ContentColumn} from "@upgrowth/react-fulcrum";
import { firebind } from '../services/database'

const PromotedPosts = ({promotedPosts}) => {
  const postIds = keys(promotedPosts).sort().reverse()

  const posts = map(postIds, (postId) => {
    return <PromotedPost key={postId} groupId={promotedPosts[postId]} postId={postId} />
  })

  return (
      <ContentColumn className='PromotedPosts'>
        <h5 className='section-title'>Promoted posts</h5>
        <div className="Posts">
          { posts }
        </div>
      </ContentColumn>
  )
}

export default firebind(PromotedPosts, {
  promotedPosts: () => `promotedPosts`
})
