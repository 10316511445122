// @flow

import './Post.scss'
import {
  Avatar,
  Button,
  Card,
  Column,
  Flex,
  Row
} from '@upgrowth/react-fulcrum'
import * as React from 'react'
import pluralize from 'pluralize'
import TimeAgo from 'react-timeago'
import { keys, values, maxBy } from 'lodash'
import { backgroundWithGradient } from '../groups/GroupProfile'
import { FaStar } from 'react-icons/fa'
import { withRouter } from 'react-router-dom'
import { firebind, withUserId } from '../services/database'
import ModerationTools from '../messages/ModerationTools'
import Linkify from 'react-linkify'
import { GroupRoles } from '../types/types'
import YoutubeEmbed from '../compositions/YoutubeEmbed/YoutubeEmbed'
import BestReply from './BestReply'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'

const linkProps = {
  target: '_blank',
  rel: 'noopener noreferrer nofollow'
}

class Post extends React.Component {
  state = {
    showingBestReply: false
  }

  render() {
    const {
      groupId,
      postId,
      post,
      group,
      memberCount,
      author,
      isAdmin,
      isGlobalMod,
      userId,
      role,
      history,
      mostLikedReplies
    } = this.props
    if (!post || !group || !author) {
      return null
    }

    let bestReplyExists = false

    if (keys(mostLikedReplies).length > 0) {
      const mostLikedReply = maxBy(values(mostLikedReplies), 'likeCount')
      bestReplyExists =
        typeof mostLikedReply !== 'undefined' && mostLikedReply.likeCount > 0
    }

    const { photo } = group
    const { name } = author
    const subtitle = pluralize('member', memberCount, true)
    const { updatedAt, promoted } = post

    const showGroup = () => {
      history.push(`/groups/${groupId}`)
    }

    const showMessageThread = () => {
      history.push(`/groups/${groupId}/threads/${postId}`)
    }

    return (
      <Card className="Post">
        <Column
          className="header"
          style={backgroundWithGradient(window.rewriteImageUrl(photo))}
        >
          <Row onClick={showGroup}>
            <div className="title">{group.name}</div>
            <Flex />
            {promoted && <FaStar className="star" size={18} />}
          </Row>
          <div className="subtitle">{subtitle}</div>
        </Column>
        <div className="container" onClick={showMessageThread}>
          <div className="inset">
            <Row className="author">
              <Avatar
                size={40}
                name={name}
                img={window.rewriteImageUrl(author.avatar)}
              />
              <Column>
                <div className="title">{name}</div>
              </Column>
            </Row>
            <ModerationTools
              userId={post.from}
              groupId={groupId}
              isPinned={post.promoted}
              mod={
                isAdmin === true ||
                isGlobalMod === true ||
                role === GroupRoles.mod
              }
              admin={isAdmin === true}
              role={post.role}
              messageId={postId}
              mine={post.from === userId}
            />

            <p className="subtitle">
              <TimeAgo date={updatedAt} /> &#x2022; {post.likeCount || 0} like
              {post.likeCount !== 1 ? 's' : ''}
            </p>

            <div className="post-content">
              <Linkify properties={linkProps}>
                {<FroalaEditorView model={post.body} />}
              </Linkify>
              <YoutubeEmbed postBody={post.body} />
            </div>
          </div>
          {this.state.showingBestReply && (
            <BestReply groupId={groupId} threadId={postId} />
          )}
        </div>
        {bestReplyExists && (
          <Button
            onClick={e => {
              e.stopPropagation()
              this.setState({
                showingBestReply: !this.state.showingBestReply
              })
            }}
          >
            {this.state.showingBestReply
              ? 'Hide most liked reply'
              : 'Show most liked reply'}
          </Button>
        )}
      </Card>
    )
  }
}

export default withUserId(
  withRouter(
    firebind(Post, {
      group: ({ groupId }) => `groups/details/${groupId}`,
      memberCount: ({ groupId, group }) =>
        group && `groups/count/${group.type}/${groupId}/count`,
      author: ({ post }) => post && `users/${post.from}/profile`,
      role: ({ groupId, userId }) =>
        groupId && userId && `groups/members/${groupId}/${userId}`,
      isAdmin: ({ userId }) => userId && `users/${userId}/standing/admin`,
      isGlobalMod: ({ userId }) =>
        userId && `users/${userId}/standing/globalModerator`,
      mostLikedReplies: ({ groupId, postId }) => ({
        ref: `groups/replies/${groupId}/${postId}`,
        queries: {
          orderByChild: 'likeCount',
          limitToLast: 1
        }
      })
    })
  )
)
