// @flow
import React, { useState, useEffect } from 'react'
import DateTimePicker from 'react-datetime-picker'
import uuidv4 from 'uuid/v4'
import { withUserId } from '@upgrowth/firekit'
import { firebind } from '@upgrowth/firekit'
import { withParams } from '@upgrowth/reactkit'
import { Form, Field, Modal, Button } from '@upgrowth/react-fulcrum'
import { Redirect } from '@upgrowth/reactkit/lib/router'

import { updateDB } from '../services/database'
import { getFunction } from '../services/functions'
import AdminAndModeratorOnly from '../services/AdminAndModeratorOnly'

import Screen from '../compositions/Screen'
import EditableProfilePicture from '../compositions/EditableProfilePicture'

import styles from './events.module.scss'

function EventsEditor({ isAdmin, myGroups, userId, eventId, details }) {
  const [redirectToAllEvents, setRedirectToAllEvents] = useState(false)

  const [eventName, setEventName] = useState('')
  const [eventLocation, setEventLocation] = useState('')
  const [eventBody, setEventBody] = useState('')
  const [eventTime, setEventTime] = useState('')
  const [eventGroup, setEventGroup] = useState('')
  const [eventExternalLink, setEventExternalLink] = useState('')
  const [eventAttachments, setEventAttachments] = useState('')
  const [eventPhoto, setEventPhoto] = useState('')
  const [groups, setGroups] = useState([])

  useEffect(() => {
    if (details && Object.keys(details).length) {
      setEventName(details.name || '')
      setEventLocation(details.location || '')
      setEventBody(details.body || '')
      setEventExternalLink(details.externalLink || '')
      setEventTime(details.time ? new Date(details.time) : '')
      setEventGroup(details.group || '')
      setEventAttachments(details.attachments || '')
      setEventPhoto(details.photo || '')
    }
  }, [
    details,
    setEventName,
    setEventLocation,
    setEventBody,
    setEventExternalLink,
    setEventTime,
    setEventGroup,
    setEventAttachments,
    setEventPhoto
  ])

  useEffect(() => {
    // If the user is an administrator, we retrieve all groups
    if (isAdmin === true) {
      getFunction('getGroups')()
        .then(({ data: groups }) => {
          setGroups(
            groups.map(({ groupId, name }) => ({
              label: name,
              value: groupId
            }))
          )
        })
        .catch(console.error)
    }
    // Otherwise, just return the groups that the user is in
    else if (myGroups) {
      setGroups(
        Object.keys(myGroups).map(key => ({
          label: myGroups[key].name,
          value: key
        }))
      )
    }
  }, [myGroups, setGroups, isAdmin])

  const updateEvent = async () => {
    // If the id `new` is used, generate an event with a unique id.
    const id = eventId === 'new' ? uuidv4() : eventId

    const newEvent = {
      id,
      attachments: eventAttachments.image || '',
      body: eventBody,
      externalLink: eventExternalLink,
      group: eventGroup,
      location: eventLocation,
      name: eventName,
      photo: eventPhoto,
      saved: details.saved || [],
      time: eventTime.toJSON(),
      owner: userId
    }

    const updates = {
      [`events/${id}`]: newEvent
    }

    // Create an event post only if it is a new event
    if (eventId === 'new') {
      const location = window.location
      const postBody =
        `<div style="margin-bottom:16px;"><b>${eventName}</b></div>` +
        `<div style="margin-bottom:16px;">${eventBody}</div>` +
        `<div><a href="${location.protocol}//${location.host}/events/${id}">Link</a></div>`

      const eventPost = {
        at: Date.now(),
        body: postBody,
        from: userId,
        promoted: true,
        role: 'user',
        updatedAt: Date.now()
      }

      updates[`groups/threads/${eventGroup}/${id}`] = eventPost
    }

    await updateDB('/', updates).then(data => {
      setRedirectToAllEvents(true)
    })
  }

  const deleteEvent = async () => {
    closeConfirm()
    await updateDB('/', {
      [`events/${eventId}`]: null
    }).then(() => setRedirectToAllEvents(true))
  }

  const [showConfirm, setShowConfirm] = useState(false)
  const openConfirm = () => setShowConfirm(true)
  const closeConfirm = () => setShowConfirm(false)

  if (redirectToAllEvents) return <Redirect name="allEvents" />

  const canSubmit =
    eventBody &&
    eventExternalLink &&
    eventGroup &&
    eventLocation &&
    eventTime &&
    eventName

  const exists = Boolean(details && Object.keys(details).length)

  return (
    <Screen>
      <AdminAndModeratorOnly>
        <div className={styles.editor}>
          <Form onSubmit={updateEvent}>
            {/* image */}
            <EditableProfilePicture
              value={eventPhoto}
              onChange={setEventPhoto}
            />

            {/* name */}
            <Field
              style={{ width: 'calc(100% + 24px)', marginRight: '-24px' }}
              type="text"
              value={eventName}
              onChange={setEventName}
              label="Name"
              placeholder="Event name"
            />

            {/* time */}
            <Field
              label="Date and time"
              style={{ width: 'calc(100% + 24px)', marginRight: '-24px' }}
            >
              <DateTimePicker
                disableClock={true}
                format={'y-MM-dd h:mm:ss a'}
                value={eventTime}
                onChange={e => setEventTime(e)}
              />
            </Field>

            {/* location */}
            <Field
              style={{ width: 'calc(100% + 24px)', marginRight: '-24px' }}
              type="text"
              value={eventLocation}
              onChange={setEventLocation}
              label="Location"
              placeholder="Event location"
            />

            {/* external link */}
            <Field
              style={{ width: 'calc(100% + 24px)', marginRight: '-24px' }}
              type="text"
              value={eventExternalLink}
              onChange={setEventExternalLink}
              label="External Link"
              placeholder="External link URL"
            />

            {/* group */}
            <Field
              style={{ width: 'calc(100% + 24px)', marginRight: '-24px' }}
              type="select"
              onChange={setEventGroup}
              value={eventGroup}
              placeholder="Select group"
              label="Group"
              options={groups}
            />

            {/* body */}
            <div className={styles.body}>
              <Field
                label="Body"
                type="textarea"
                value={eventBody}
                onChange={setEventBody}
              />
            </div>

            <div className={styles.actions}>
              <Button
                className={`shape-pill ${canSubmit ? '' : styles.disabled}`}
                type="submit"
              >
                {exists ? 'Save event' : 'Create event'}
              </Button>
              {exists && (
                <Button className="shape-pill reject" onClick={openConfirm}>
                  Delete event
                </Button>
              )}
            </div>
          </Form>
        </div>
      </AdminAndModeratorOnly>
      <Modal
        className={styles.deleteEventModal}
        isOpen={showConfirm}
        onRequestClose={closeConfirm}
        appElement={document.body}
        closeElement=""
      >
        <h3>Delete event</h3>
        <div>
          Are you sure you want to delete this event? This action cannot be
          undone.
        </div>
        <div className={styles.deleteActions}>
          <Button className="shape-pill" onClick={closeConfirm}>
            Cancel
          </Button>
          <Button className="shape-pill reject" onClick={deleteEvent}>
            Delete
          </Button>
        </div>
      </Modal>
    </Screen>
  )
}

export default withUserId(
  withParams(
    firebind(EventsEditor, {
      isAdmin: ({ userId }) => `users/${userId}/standing/admin`,
      allGroups: `groups/details`,
      myGroups: ({ userId }) => `users/${userId}/groups`,
      details: ({ eventId }) => `events/${eventId}`
    })
  )
)
