// @flow

import * as React from 'react'
import { map, isEmpty } from 'lodash'
import { RouteObserver } from '@upgrowth/reactkit'
import type { UserId } from '../types/types'

const { ga, gtag } = window

// type User = {
//   id: string,
//   email?: string,
//   [string]: mixed,
// }
type Event = {
  category: string,
  label?: string,
  value?: number,
  [string]: mixed
}

type Strategy = {
  page(page: string): void,
  event(name: string, event: Event): void,
  identify(userId: UserId): void,
  anonymise(): void
}

// const Events = {
//   friendships: ['request', 'accept', 'reject', 'search', 'unfriend'],
//   groupMemberships: ['join', 'leave'],
//   messaging: ['groupMessage', 'friendMessage'],
//   location: ['share', 'unshare'],
//   profile: ['avatar', 'onboarded']
// }

/**
 * Google Analytics tracking strategy
 */
class GaStrategy {
  constructor(trackingId, ga4MeasurementId) {
    if (trackingId) {
      console.log('Initialising GA with ', trackingId)
      ga('create', trackingId, 'auto')
      ga('send', 'pageview')
    } else {
      console.log('No GA tracking ID configured')
    }

    if (ga4MeasurementId) {
      console.log('Initialising GA4 with ', ga4MeasurementId)
      gtag('js', new Date())
      gtag('config', ga4MeasurementId)
    } else {
      console.log('No GA4 Measurement ID configured')
    }
  }

  page(page: string) {
    ga('set', 'page', page)
    ga('send', 'pageview')
  }

  event(name: string, event: Event) {
    ga('send', 'event', event.category, name, event.label, event.value)
    gtag('event', name, {
      event_category: event.category,
      event_label: event.label,
      event_value: event.value
    })
  }

  identify(userId: UserId) {
    ga('set', 'userId', userId)
  }

  anonymise() {
    ga('set', 'userId', null)
  }
}

class Floodgate {
  promise: Promise<any>
  open: () => void
  abort: () => void

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.open = resolve
      this.abort = reject
    })
  }

  dam(f: any => any) {
    return (...args: any[]) => {
      const promise: Promise<any> = this.promise.then(() => f(...args))
      this.promise = promise
      return promise
    }
  }
}

class Track {
  strategies: { [string]: Strategy } = {}
  ready: Floodgate = new Floodgate()

  //ready: Promise<any> = Promise.resolve()

  // init(strategy: string, config: any) {
  //   console.log(`Initialising ${strategy} tracking`)
  //   const strat = this.strategies[strategy];
  //   if (strat) {
  //     strat.init(config);
  //   } else {
  //     console.log(`Strategy ${strategy} unknown`)
  //   }
  // }

  page = this.ready.dam((page: string) => {
    try {
      map(this.strategies, strategy => strategy.page(page))
    } catch (err) {
      console.error('Tracking page view failed: ', err)
    }
  })

  event = this.ready.dam((name: string, event: Event) => {
    try {
      map(this.strategies, strategy => strategy.event(name, event))
    } catch (err) {
      console.error('Tracking event failed: ', err)
    }
  })

  identify = this.ready.dam((userId: UserId) => {
    try {
      map(this.strategies, strategy => strategy.identify(userId))
    } catch (err) {
      console.error('Tracking identify user failed: ', err)
    }
  })

  anonymise = this.ready.dam(() => {
    try {
      map(this.strategies, strategy => strategy.anonymise())
    } catch (err) {
      console.error('Tracking anonymis user failed: ', err)
    }
  })

  addStrategy(name: string, strategy: Strategy) {
    this.strategies[name] = strategy
  }

  async after(promise: Promise<any>, name: string, event: Event) {
    const output = await promise
    this.event(name, event)
    return output
  }
}

const track = new Track()
export default track

export async function connect(trackingId: string, ga4MeasurementId: string) {
  if (isEmpty(trackingId)) {
    console.info('No Google Analytics tracking ID set')
    return
  }

  if (isEmpty(ga4MeasurementId)) {
    console.info('No Google Analytics 4 Measurement ID set')
    return
  }

  track.addStrategy('ga', new GaStrategy(trackingId, ga4MeasurementId))
  track.ready.open()
}

/**
 * Page tracking with v4 react-router sucks - a component which you stick
 * at the root of the router and will track any time props change.
 *
 * <Router><TrackPageView>... routes</TrackPageView></Router>
 */

export const TrackPageView = ({ children }: { children: React.Node }) => (
  <RouteObserver
    onRouteChange={location => {
      track.page(location)
    }}
    children={children}
  />
)
