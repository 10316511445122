import * as React from 'react'
import Chatbox from '../messages/Chatbox'
import { postThread, replyToThread } from '../services/messaging'
import { Button, Navbar } from '@upgrowth/react-fulcrum'
import { isEmpty } from 'lodash'
import type { GroupRole } from '../types/types'
import { GroupRoles } from '../types/types'
import { getDB } from '../services/database'
import { joinGroup } from '../services/groups'

const joinGroupWithDetails = async (groupId: string) => {
  const { name, type } = await getDB(`groups/details/${groupId}`)
  return joinGroup(groupId, name, type)
}

const GroupChatbox = ({
  groupId,
  threadId,
  role,
  count
}: {
  groupId: string,
  threadId?: string,
  role: GroupRole,
  count: number
}) => {
  const loading = role === undefined

  if (loading) return null

  const isMember = !isEmpty(role)

  if (isMember) {
    if (role === GroupRoles.banned) {
      return <div className="Chatbox banned">BANNED</div>
    } else {
      return (
        <Chatbox
          onSend={(body, attachments) =>
            threadId
              ? replyToThread(groupId, threadId, body, role, count, attachments)
              : postThread(groupId, body, role, attachments)
          }
        />
      )
    }
  } else {
    return (
      <Navbar className="Chatbox-join" variant="light">
        <Button onClick={() => joinGroupWithDetails(groupId)}>
          Join Group
        </Button>
      </Navbar>
    )
  }
}

export default GroupChatbox
