// @flow

import * as React from 'react'
import './Conversation.scss'

// Implemented with some help from
// https://www.pubnub.com/tutorials/react/chat-message-history-and-infinite-scroll/#scroll-bottom

type Props = { children: React.Node, contextId: string, onUpdate: () => any, onScroll?: (atBottom: boolean) => any }
export default class Conversation extends React.Component<Props> {
    scrollView: HTMLElement
    props: Props
    scrollLocked = true

    componentDidMount() {
        this.props.onScroll && this.scrollView.addEventListener('scroll', this.handleScroll, {passive: true})
    }

    componentWillUnmount() {
        this.scrollView && this.scrollView.removeEventListener('scroll', this.handleScroll, {passive: true})
    }

    handleScroll = () => {
        const {onScroll} = this.props
        this.scrollLocked = this.isScrollAtBottom()
        onScroll && onScroll(this.isScrollAtBottom())
    }

    scrollToBottom() {
        window.requestAnimationFrame(() => {
            const scrollHeight = this.scrollView.scrollHeight
            const height = this.scrollView.clientHeight
            const maxScrollTop = scrollHeight - height
            this.scrollView.scrollTop = Math.max(maxScrollTop, 0)
        })
    }

    scrollToTop() {
        this.scrollView.scrollTop = 0
    }

    isScrollAtBottom() {
        const scrollPos = this.scrollView.scrollTop
        const scrollBottom = this.scrollView.scrollHeight - this.scrollView.clientHeight
        return (scrollBottom <= 0) || (scrollPos >= scrollBottom)
    }

    UNSAFE_componentWillUpdate(nextProps: Props) {
        if (nextProps.contextId !== this.props.contextId) {
            this.scrollLocked = true
        }
        this.updated = React.Children.count(nextProps.children) !== React.Children.count(this.props.children)
        // if (this.updated) {
        //   this.scrollLocked = this.isScrollAtBottom()
        // }
    }

    componentDidUpdate() {
        if (this.scrollLocked && !this.isScrollAtBottom()) {
            this.scrollToBottom()
        }
        this.props.onUpdate && this.props.onUpdate()
    }

    render() {
        return (
            <div ref={scrollView => this.scrollView = scrollView} className="Conversation">
                {this.props.children}
            </div>
        )
    }
}
