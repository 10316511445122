//@flow
import classnames from 'classnames'
import * as React from 'react'
import { Button, Card } from '@upgrowth/react-fulcrum'
import TimeAgo from 'react-timeago'
import './Message.scss'
import Linkify from 'react-linkify'
import type { GroupRole } from '../types/types'
import { Message as MessageType } from '../types/types'
import ProfilePreview from '../compositions/ProfilePreview'
import AddFriendButton from '../compositions/AddFriendButton'
import LikeButton from './LikeButton'
import { Link } from '@upgrowth/reactkit'
import { FaComment } from 'react-icons/fa'
import { withRouter } from 'react-router-dom'
import { resolveRoute } from '../routes'
import { stopPropagation } from '../services/util'
import { firebind, withUserId } from '../services/database'
import { isEmpty, map, size } from 'lodash'
import Mention from './Mention'
import { MODERATOR_ROLE } from '../types/types'
import YoutubeEmbed from '../compositions/YoutubeEmbed/YoutubeEmbed'

import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'

const linkProperties = {
  target: '_blank',
  rel: 'noopener noreferrer nofollow'
}

type MessageProps = MessageType & {
  userId: string,
  className: string,
  alwaysHeader?: boolean,
  neverHeader?: boolean,
  role?: GroupRole,
  children?: React.Node,
  thread?: boolean,
  threadId?: string,
  replyCount?: number,
  lastUpdated?: number,
  groupId?: string,
  isModerator?: boolean,
  promoted?: boolean,
  noVote?: boolean,
  simple?: boolean,
  likeCount?: number,
  bestPost?: boolean
}

const OnClickLink = withRouter(
  ({ name, children, history, hasThread, groupId, threadId }) => {
    return hasThread ? (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(resolveRoute(name, { groupId, threadId }))}
      >
        {children}
      </div>
    ) : (
        children
      )
  }
)

const Message = ({
  body,
  attachments = {},
  at,
  from,
  className,
  userId,
  alwaysHeader,
  neverHeader,
  role,
  children,
  groupId,
  threadId,
  replyId,
  thread,
  updatedAt,
  likeCount,
  noVote,
  simple,
  replyCount,
  bestPost,
  threadRole
}: MessageProps) => {
  const showingHeader = alwaysHeader || !neverHeader
  const showFriendName = size(attachments.mentions) < 5

  const isFromMe = from === userId
  const actions = isFromMe ? null : <AddFriendButton friendId={from} />

  const isMessageAuthorMod = threadRole === MODERATOR_ROLE
  return (
    <Card
      className={classnames(
        'Message',
        {
          thread,
          showingHeader,
          mentioned: attachments && attachments.mentioned,
          simple
        },
        className,
        bestPost ? 'best-post' : isFromMe ? 'me' : role
      )}
    >
      <OnClickLink
        name="replies"
        hasThread={thread}
        groupId={groupId}
        threadId={threadId}
      >
        {showingHeader && (
          <ProfilePreview
            simple={simple}
            onClick={stopPropagation}
            userId={from}
            role={role}
            linkAvatar
            actions={actions}
            isMod={isMessageAuthorMod}
            showMod
          />
        )}
        {children}
        <div className="message-details">
          <TimeAgo date={at} />
          {!noVote && (
            <>
              {' '}
              &#x2022; {likeCount || 0} like{likeCount !== 1 ? 's' : ''}
            </>
          )}
        </div>
        <div className="message-body">
          {attachments && attachments.mentioned ? (
            <Link {...attachments.mentioned} name="replies">
              I mentioned you in this thread
            </Link>
          ) : (
              <Linkify properties={linkProperties}>
                {body && <FroalaEditorView model={body} />}
              </Linkify>
            )}
          <YoutubeEmbed postBody={body} />
        </div>
        {attachments && (
          <div className="attachments">
            {attachments.image && (
              <img
                className="attached-image"
                alt="Attachment"
                src={window.rewriteUrl(attachments.image)}
              />
            )}
            {!isEmpty(attachments.mentions) && (
              <div className="mentions">
                <h5>Tagged Friends</h5>
                {map(attachments.mentions, (userId, i) => (
                  <Mention userId={userId} key={i} showName={showFriendName} />
                ))}
              </div>
            )}
          </div>
        )}
        <div className="message-footer">
          <div className="message-actions">
            {!noVote && !simple && (
              <LikeButton
                groupId={groupId}
                threadId={threadId}
                replyId={replyId}
                userId={userId}
              />
            )}
            {thread && (
              <Button
                className="shape-pill reply"
                as={Link}
                name="replies"
                groupId={groupId}
                threadId={threadId}
              >
                Reply
              </Button>
            )}
          </div>
          {thread && (
            <div className="reply-count">
              <FaComment className="comment-icon" />
              <div className="replies">{replyCount}</div>
              {replyCount > 0 && (
                <div className="last-updated">
                  (<TimeAgo date={updatedAt} />)
                </div>
              )}
            </div>
          )}
        </div>
      </OnClickLink>
    </Card>
  )
}

export default withUserId(
  firebind(Message, {
    profile: ({ userId }) => userId && `users/${userId}/profile`,
    isAdmin: ({ userId }) => userId && `users/${userId}/standing/admin`,
    threadRole: ({ groupId, from }) =>
      groupId && from && `groups/members/${groupId}/${from}`
  })
)
