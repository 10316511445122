// @flow

import * as firebaseKey from 'firebase-key'
import { forEach, isEmpty, size } from 'lodash'
import Waterbase from './Waterbase'

const loading = Symbol('Loading')

const interval = 30 * 1000 // 30 seconds

export default class Subscription {
  listeners: { [id: string]: (any) => any } = {}
  intervalId: ?any
  lastValue: any = loading
  waterbase: Waterbase
  query: {}
  path: string

  constructor (waterbase, path, query) {
    this.waterbase = waterbase
    this.path = path
    this.query = query
  }

  sub = (callback: (any) => any) => {
    const id = firebaseKey.key()
    this.listeners[id] = callback
    if (size(this.listeners) === 1) { this.start() }
    const unsubCallback = () => this.unsub(id)
    if (this.lastValue !== loading) {
      console.log("Returning last value for path", this.path, this.lastValue)
      callback(this.lastValue)
    } else {
      unsubCallback.loadPromise = this.fetch().then(callback)
    }

    return unsubCallback
  }

  unsub = (id: string) => {
    console.log("Unsubscribing", id)
    delete this.listeners[id]
    if (isEmpty(this.listeners)) { this.stop()}
  }

  start = () => {
    this.intervalId = setInterval(this.fetch, interval)
  }

  stop = () => clearInterval(this.intervalId)

  broadcast = (value) => {
    console.log("Broadcasting", this.path, value, size(this.listeners))
    this.lastValue = value
    forEach(this.listeners, f => f(value))
  }

  fetch = async () => this.waterbase.get(this.path, this.query)
}

export type SubscriptionTreeNode = {
  '.'?: Subscription,
  [string]: SubscriptionTreeNode
}
