// @flow
import type { UserProfile } from '../types/types'
import * as React from 'react'
import Avatar from '../compositions/Avatar'
import { Button } from '@upgrowth/react-fulcrum'
import { Link } from '@upgrowth/reactkit'
import './ProfilePreview.scss'
import * as classnames from 'classnames'
import { FaRegComments } from 'react-icons/fa'
import { stopPropagation } from '../services/util'
import { firebind, userId } from '../services/database'
import { truncate } from 'lodash'
import Chip from './Chip'

type Props = {
  profile: UserProfile,
  actions?: React.Node,
  userId: string,
  linkAvatar: boolean,
  isAdmin: any,
  isMod: any,
  isGlobalModerator: any,
  asAdmin?: boolean,
  showMod?: boolean,
  simple?: boolean
}
const ProfilePreview = ({
  profile = {},
  actions,
  userId,
  linkAvatar,
  isAdmin,
  isMod,
  isGlobalModerator,
  asAdmin,
  noFriendActions,
  showMod,
  simple
}: Props) => {
  const { name, firstName, lastName, avatar, campus, faculty } = profile
  isAdmin = isAdmin === true
  isMod = isMod === true
  isGlobalModerator = isGlobalModerator === true
  asAdmin = asAdmin === true

  if (!noFriendActions && (asAdmin || isAdmin)) {
    actions = (
      <Button
        as={Link}
        onClick={stopPropagation}
        name="friend"
        friendId={userId}
        className="shape-round admin-chat-button"
      >
        <FaRegComments />
      </Button>
    )
  }

  return (
    <div className={classnames('ProfilePreview', { simple })}>
      <div className="profile-chunk">
        {linkAvatar ? (
          <Link className="card-avatar" name="friend" friendId={userId}>
            <Avatar {...{ avatar, name }} size="small" />
          </Link>
        ) : (
          <Avatar className="card-avatar" {...{ avatar, name }} size="small" />
        )}
        <div className="profile-details">
          <div className="profile-name">
            <span>{truncate(name || [firstName, lastName].filter(x => x).join(' '), { length: 50 }) || 'A Person'}</span>
            {!simple && (
              <div className="profile-roles">
                {isAdmin && <Chip backgroundColor="#f95754">Admin</Chip>}
                {isGlobalModerator && (
                  <Chip backgroundColor="#006cab">
                    Global{showMod ? ' Mod' : ''}
                  </Chip>
                )}
                {showMod && isMod && (
                  <Chip backgroundColor="#5cb985" fontColor="#ffffff">
                    Mod
                  </Chip>
                )}
              </div>
            )}
          </div>
          {!simple && (faculty || campus) && (
            <div className="profile-location">
              {faculty}
              {faculty && campus ? ', ' : ''}
              {campus}
            </div>
          )}
        </div>
      </div>

      {!simple && <div className="actions">{actions}</div>}
    </div>
  )
}

export default firebind(ProfilePreview, {
  profile: ({ userId }) => userId && `users/${userId}/profile`,
  isAdmin: ({ userId }) => userId && `users/${userId}/standing/admin`,
  isGlobalModerator: ({ userId }) =>
    userId && `users/${userId}/standing/globalModerator`,
  asAdmin: () => `users/${userId()}/standing/admin`
})
