// @flow

import { sortBy } from 'lodash'

type SortableGroup = {
  name: string,
  sortKey: string
}

type SortOptions = {
  sortOrder?: string[],
  disableNameSorting?: boolean
}

/**
 * This function takes in a mapping of groups and sorts them
 * based on the `sortKey` property. The ordering of the sort
 * keys can be defined in `options.sortOrder` as an array of
 * strings.
 *
 * This function uses a stable sort so that it can preserve
 * search result ordering.
 *
 * @param groups The list of groups to sort. Should have a 'name' and 'sortKey' property.
 * @param options.sortOrder An array of keys in the order that they should be sorted in.
 * @param options.additionaFields Add additional fields to sort by. Should be a list of strings.
 */
const sortGroups = (groups: SortableGroup[], options: SortOptions = {}) => {
  const { sortOrder = [], additionalFields = [] } = options

  // Map each sort key to a numerical value, based on their index
  // e.g. ["master", "subgroups", "default"] => { master: 0, subgroups: 1, default: 2 }
  const sortKeyOrder = sortOrder.reduce(
    (acc, cur, index) => ({
      ...acc,
      [cur]: index
    }),
    {}
  )

  // Add the sort key field to each item
  const sortableGroups = groups.map(group => {
    // Get the sort key's numerical mapping
    let key = sortKeyOrder[group.sortKey]
    // If the sortKey isn't mapped to a number, use default
    if (typeof key !== 'number') key = sortKeyOrder['default']
    // If default isn't defined, use a really big number
    if (typeof key !== 'number') key = Number.MAX_VALUE

    // Return the group with the new sort key
    return {
      ...group,
      sortKey: key
    }
  })

  // Sort options for Lodash's sortBy method.
  // By default, we always sort by the sortKey, which is mapped to a numerical value.
  // We also include any additional fields to sort by.
  let sortFields = ['sortKey', ...additionalFields]

  // Perform a stable sort and return the result
  return sortBy(sortableGroups, sortFields)
}

export default sortGroups
