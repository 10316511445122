import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App.jsx'
// import registerServiceWorker from './registerServiceWorker';
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'
import initializeEnvironment from './services/connectionEnvironment'

;(async () => {
  viewportUnitsBuggyfill.init()
  await initializeEnvironment()
  ReactDOM.render(<App />, document.getElementById('root'))
  // registerServiceWorker();
})()
