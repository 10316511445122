// @flow

import React from 'react'
import { Field, Button, Input } from '@upgrowth/react-fulcrum'
import * as classnames from 'classnames'
import './SafetyField.scss'
import { FaCheck, FaWindowClose } from 'react-icons/fa'

export default class SafetyField extends React.Component {
  constructor(props) {
    super(props)
    this.state = { editing: false }
  }

  enterEditMode() {
    this.setState({ editing: true, value: this.props.value })
  }

  completeEdit() {
    this.props.onChange(this.state.value)
    this.cancelEdit()
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    value !== this.state.value && this.setState({ value })
  }

  cancelEdit() {
    this.setState({ value: false, editing: false })
  }

  handleKeyUp(e) {
    switch (e.keyCode) {
      case 27:
        this.cancelEdit()
        e.preventDefault()
        break
      case 13:
        this.completeEdit()
        e.preventDefault()
        break
      default:
        break
    }
  }

  render() {
    const { value, label } = this.props
    return (
      <Field name={label} label={label} className={classnames('SafetyField', { editing: this.state.editing })}>
        {this.state.editing
          ? (
            <span className="safety-group">
              <Input
                className="value"
                type={this.props.type || 'text'}
                value={this.state.value}
                onChange={value => this.setState({ value })}
                onKeyUp={(e) => this.handleKeyUp(e)} />
              <div className='safety-actions'>
                <Button className="editLink" onClick={() => this.cancelEdit()}><FaWindowClose /></Button>
                <Button className="editLink" onClick={() => this.completeEdit()}><FaCheck /></Button>
              </div>
            </span>
          ) : (
            <span className="safety-group">
              <span className="value">{value}</span>
              <Button className="editLink" onClick={() => this.enterEditMode()}>Edit</Button>
            </span>
          )
        }
      </Field>
    )
  }
}