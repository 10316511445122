import * as React from 'react'
import {GroupRole, GroupRoles, UserData} from '../types/types'
import {chain, isEmpty, map} from 'lodash'
import {UserPhoneCard} from '../friends/UsersMagazine'
import type {GroupId, UserId} from '../types/types'
import {Button} from '@upgrowth/react-fulcrum'
import {updateRole} from '../services/groups'
import modIcon from '../compositions/promoted.svg'
import bannedIcon from '../compositions/no-white.svg'
import mutedIcon from '../compositions/muted.svg'
import './MemberList.scss'
import { firebind } from '../services/database'
import * as classnames from 'classnames'

function returnToUser(groupId, userId) {
  return updateRole(groupId, userId, GroupRoles.user)
}

type MemberListSectionProps = {
  asAdmin?: boolean,
  groupRole: GroupRole,
  title: string,
  icon: React.Node,
  users: UserId[],
  groupId: GroupId
}

const MemberListSection = ({groupRole, title, icon, userIds, groupId, asAdmin}: MemberListSectionProps) => {
  if (isEmpty(userIds)) {
    return null
  }
  return (
    <section className={`MemberListSection role-${groupRole}`} key={groupRole}>
      <h3>{title}</h3>

      {map(userIds, userId => (
        <UserPhoneCard key={userId}
                       userId={userId}
                       asAdmin={asAdmin}
                       noFriendActions
                       actionProvider={(userId) =>
                         <Button className={`shape-round ${groupRole}`}
                                 onClick={(e: Event) => {
                                   e.stopPropagation()
                                   e.preventDefault()
                                   returnToUser(groupId, userId)
                                 }}>
                           <img src={icon} alt={`Remove ${title} Status`}/>
                         </Button>
                       }/>
      ))}
    </section>
  )
}

const MemberList = ({isAdmin, groupId, members}: { isAdmin: boolean, groupId: GroupId, members: UserData<GroupRole> }) => {
  const userIdsByRole = chain(members).keys().groupBy(userId => members[userId]).value()
  return (
    <div className={classnames('MemberList', {isAdmin})}>
      <div className={classnames('member-list-wrapper', {hideOnMobile: !isAdmin && isEmpty(userIdsByRole.banned) && isEmpty(userIdsByRole.muted)})}>
        <MemberListSection groupRole="banned" asAdmin={isAdmin} title="Banned" icon={bannedIcon} userIds={userIdsByRole.banned} groupId={groupId}/>
        <MemberListSection groupRole="muted" asAdmin={isAdmin} title="Muted" icon={mutedIcon} userIds={userIdsByRole.muted} groupId={groupId}/>
        <MemberListSection groupRole="mod" asAdmin={isAdmin} title="Moderators" icon={modIcon} userIds={userIdsByRole.mod} groupId={groupId}/>
      </div>
    </div>
  )
}

export default firebind(MemberList, {members: ({groupId}) => `groups/members/${groupId}`})