// @flow
import * as React from 'react'
import {sortBy, keys} from 'lodash'
type Props = {
  collection: {[string]: mixed},
  children: (item, id: string, onSortValueUpdated: (string | number) => void) => React.Node
}

type State = {
  sortValues: {[string]: string}
}

/**
 * Sorted renders a collection, sorted by values the parent doesn't yet know.
 * The initial sort of the collection will be lexicographically by key.
 *
 * It expects a function as its children, which will be given each item, its key and a function to
 * call to update the sort value.
 * 
 */
export default class Sorted extends React.Component<Props, State> {
  state = {sortValues: {}}

  update(id: string, sortValue: string | number) {
    const {sortValues} = this.state
    if (sortValues[id] !== sortValue) {
      this.setState(state => ({sortValues: {...state.sortValues, [id]: sortValue}}))
    }
  }

  render() {
    const {sortValues} = this.state
    const {children, collection} = this.props
    const sortedKeys = sortBy(keys(collection), key => sortValues[key] || key)

    return (<>
      {sortedKeys.map((id) =>
        children(collection[id], id, (value) => this.update(id, value)))
      }
    </>)
  }
}