import * as React from 'react'
import { Navbar, NavMenu, NavItem } from '@upgrowth/react-fulcrum'
import monashLogo from './monash-shield.svg'
import friendsIcon from './friends.svg'
import groupsIcon from './groups.svg'
import mapIcon from './map.svg'
import calIcon from './calendar.svg'
import adminIcon from './admin-icon.svg'
import defaultProfile from './default-profile.svg'
import { NavLink } from '@upgrowth/reactkit'
import './MainNav.scss'
import Avatar from './Avatar'
import withProfile from '../services/withProfile'
import SectionBlip from './SectionBlip'
import AdminOnly from '../services/AdminOnly'

export default withProfile(({ profile }) => (
  <Navbar className="MainNav">
    <NavMenu>
      <div className="monash-logo">
        <NavItem as={NavLink} name="dashboard">
          <img src={monashLogo} alt="Monash" />
        </NavItem>
      </div>

      <div className="sections">
        <NavItem as={NavLink} name="groups">
          <img src={groupsIcon} alt="Groups" />
          <div className="nav-label">GROUPS</div>
        </NavItem>
        <NavItem as={NavLink} name="friends">
          <img src={friendsIcon} alt="Friends" />
          <div className="nav-label">FRIENDS</div>
          <SectionBlip sourceType="user" />
        </NavItem>
        <NavItem as={NavLink} name="firstMap">
          <img src={mapIcon} alt="Map" />
          <div className="nav-label">MAP</div>
        </NavItem>
        <NavItem as={NavLink} name="events">
          <img src={calIcon} alt="Events" />
          <div className="nav-label">EVENTS</div>
        </NavItem>
        <AdminOnly>
          <NavItem as={NavLink} name="admin">
            <img src={adminIcon} alt="Admin" />
            <div className="nav-label">ADMIN</div>
          </NavItem>
        </AdminOnly>
      </div>
      <NavItem as={NavLink} className="avatar-link" name="profile">
        <Avatar avatar={(profile && profile.avatar) || defaultProfile} />
      </NavItem>
    </NavMenu>
  </Navbar>
))
