// @flow
import * as React from 'react'
import TimeAgo from 'react-timeago'
import uuidv4 from 'uuid/v4'
import { withRouter } from 'react-router-dom'
import { Button, Card } from '@upgrowth/react-fulcrum'

import Section from '../compositions/Section'
import Screen from '../compositions/Screen'
import { firebind, setDB, timestampNow } from '../services/database'
import AdminOnly from '../services/AdminOnly'

import BatchRoleUpdate from './BatchRoleUpdate'
import BatchGroupUpdate from './BatchGroupUpdate'
import ProcessQueue from './ProcessQueue'
import SortOrder from './SortOrder'

import './Admin.scss'

const CreateGroup = withRouter(
  class extends React.Component<*> {
    createGroup = () => {
      this.props.history.push(`groups/${uuidv4()}/edit`)
    }

    render() {
      return (
        <Card>
          <div className="inset">
            <h3>Create a Group</h3>
          </div>
          <Button onClick={this.createGroup}>Create</Button>
        </Card>
      )
    }
  }
)

const CreateEvent = withRouter(
  class extends React.Component<*> {
    createEvent = () => {
      this.props.history.push(`events/edit/new`)
    }

    render() {
      return (
        <Card>
          <div className="inset">
            <h3>Create an Event</h3>
          </div>
          <Button onClick={this.createEvent}>Create</Button>
        </Card>
      )
    }
  }
)

const setTermsUpdated = () => {
  if (
    window.confirm(`Are you sure you want to set the terms and conditions as updated? 
  
Users will be asked to agree to the new terms instantly, preventing them from using the site until they do.`)
  ) {
    return setDB('public/updatedTerms', timestampNow())
  }
}

const UpdateTerms = firebind(
  ({ termsUpdated }) => (
    <Card>
      <div className="inset">
        <h3>Update T&C Date</h3>
        <p>
          Last update:{' '}
          <strong>
            {typeof termsUpdated === 'number' ? (
              <TimeAgo date={termsUpdated} />
            ) : (
              'Never'
            )}
          </strong>
        </p>
      </div>
      <Button onClick={setTermsUpdated}>Update now</Button>
    </Card>
  ),
  {
    termsUpdated: `public/updatedTerms`
  }
)

export default () => (
  <Section className="Admin">
    <AdminOnly>
      <Screen>
        <div className="Actions">
          <CreateGroup />
          <CreateEvent />
          <UpdateTerms />
          <BatchRoleUpdate />
          <BatchGroupUpdate />
          <ProcessQueue />
          <SortOrder />
        </div>
      </Screen>
    </AdminOnly>
  </Section>
)
