// @flow

import * as React from 'react'
import TimeAgo from 'react-timeago'
import { keys } from 'lodash'
import { Column, Row } from '@upgrowth/react-fulcrum'
import Linkify from 'react-linkify'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'

import { firebind } from '../services/database'
import { MONASH_OFFICIAL_GROUP_ID } from '../types/types'

import { backgroundWithGradient } from '../groups/GroupProfile'

import monashShield from '../compositions/monash-shield.svg'

import './OfficialPosts.scss'

const linkProps = {
  target: '_blank',
  rel: 'noopener noreferrer nofollow'
}

const OfficialPosts = ({ groupDetails = {}, latestPosts }) => {
  if (!latestPosts) return null

  const latestPostId = (keys(latestPosts) || [])[0]
  const lastAnnouncement = latestPostId && latestPosts[latestPostId]

  const { updatedAt = Date.now(), body = 'No Announcements Yet' } =
    lastAnnouncement || {}
  const { photo } = groupDetails

  return (
    <React.Fragment>
      <div
        className="OfficialPost"
        style={backgroundWithGradient(window.rewriteImageUrl(photo))}
      >
        <Row>
          <img className="logo" src={monashShield} alt="Monash University" />
          <Column>
            <div className="title">Monash University</div>
            <div className="subtitle">Announcements</div>
          </Column>
        </Row>
        <p className="subtitle">
          <TimeAgo date={updatedAt} />
        </p>
        <div className="main-title">
          <Linkify properties={linkProps}>
            {<FroalaEditorView model={body} />}
          </Linkify>
        </div>
      </div>
    </React.Fragment>
  )
}

export default firebind(OfficialPosts, {
  groupDetails: () => `groups/details/${MONASH_OFFICIAL_GROUP_ID}`,
  latestPosts: () => ({
    ref: `groups/threads/${MONASH_OFFICIAL_GROUP_ID}`,
    queries: {
      orderByChild: 'updatedAt',
      limitToLast: 1
    }
  })
})
