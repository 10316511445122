// @flow

import { size } from 'lodash'
import * as React from 'react'
import type { GroupId, UserId } from '../types/types'
import { firebind, withUserId } from '../services/database'

const defaultRender = (n: number) => <span>{n}</span>

type ActualProps = { sourceType: 'user' | 'group', sourceId: UserId | GroupId }

type Props = { unreadMessages: {}, render?: (number) => React.Node }

const UnreadMessageCount: (ActualProps) => React.Node = firebind(({unreadMessages, render = defaultRender}: Props) =>
    render(size(unreadMessages) - 1)
  , {
    lastReadMessageId: ({userId, sourceId}) => `users/${userId}/secrets/user/${sourceId}/id`,
    unreadMessages: ({lastReadMessageId, sourceId, userId}) => {
      const ref = `users/${userId}/messages/${sourceId}`
      const startAt = typeof lastReadMessageId === 'string' ? {startAt: lastReadMessageId} : {}
      // console.log("Start at", startAt, lastReadMessageId, ref)
      return {
        ref,
        queries: {
          orderByKey: true,
          limitToLast: 99,
          ...startAt
        }
      }
    }
  })

export default withUserId(UnreadMessageCount)

export const UpdatedThreadCount = withUserId(firebind(
  ({updatedThreads, render, loading}) => !loading && render(size(updatedThreads))
  , {
    lastReadAt: ({sourceId, userId}) => `users/${userId}/secrets/group/${sourceId}/lastReadAt`,
    updatedThreads: ({lastReadAt, sourceId}) => sourceId &&
      (typeof lastReadAt === 'number' || typeof lastReadAt === 'string') && ({
      ref: `groups/threads/${sourceId}`,
      queries: {
        orderByChild: 'updatedAt',
        startAt: lastReadAt,
        limitToLast: 99
      }
    })
  }
))