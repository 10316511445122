// @flow
import * as React from 'react'
import * as firebase from 'firebase/app'
import { onAuthStateChanged } from '../services/database'

type LoggedInState = { auth?: 'in' | 'out' }

export default class LoggedIn extends React.Component<{ children: React.Node, loggedOut?: React.Node, loading?: React.Node }, LoggedInState> {
  state = {}

  componentDidMount() {
    this.unsub = onAuthStateChanged(this.updateUser)
  }

  componentWillUnmount() {
    this.unsub && this.unsub()
    delete this.unsub
  }

  updateUser = (user: firebase.User) => this.setState({ auth: user ? 'in' : 'out' })

  render() {
    const { auth } = this.state
    const { children, loggedOut = null, loading = null } = this.props

    if (auth === 'in') {
      return children
    }
    if (auth === 'out') {
      return loggedOut
    }

    return loading
  }

}