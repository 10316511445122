import React from 'react'
import uuidv4 from 'uuid/v4'

import './JSONTable.scss'

/**
 * This component renders a JSON object as a table for previewing purposes.
 * Values with a key prefixed with `__` are hidden.
 */
const JSONTable = ({ data }) => (
  <table className="DataTable">
    <thead>
      <tr>
        {Object.keys(data[0])
          // Filter out key that start with __
          .filter(k => !/^__/.test(k))
          // Convert each key to a <th> element
          .map(k => (
            <th key={uuidv4()}>{k}</th>
          ))}
      </tr>
    </thead>
    <tbody>
      {data.map(e => (
        <tr key={uuidv4()}>
          {Object.keys(e)
            // Filter out key that start with __
            .filter(k => !/^__/.test(k))
            // Convert each value to a <td> element
            .map(k => (
              // If the element is not null, JSON.stringify it and remove quotes at the start and end
              <td key={uuidv4()}>
                {e[k] && JSON.stringify(e[k]).replace(/^"|"$/g, '')}
              </td>
            ))}
        </tr>
      ))}
    </tbody>
  </table>
)

export default JSONTable
