import React, { useEffect, useState } from 'react'
import { Button, Modal } from '@upgrowth/react-fulcrum'

import { firebind, withUserId } from '../../services/database'
import { setName, setNameMode } from '../../services/users'
import { UserProfile } from '../../types/types'

import './PreferredNameModal.scss'

function PreferredNameModalComponent(props: { profile?: UserProfile }) {
  const { profile } = props

  const [open, setOpen] = useState(false)

  // Show the modal if the user does not have a preferred name mode
  useEffect(() => {
    if (profile && !profile.preferredNameMode) setOpen(true)
    else setOpen(false)
  }, [profile])

  function handleSyncPreferredName(sync: boolean) {
    if (sync) setName(profile?.firstName || profile?.lastName || '', 'system')
    else setNameMode('custom')
  }

  return (
    <Modal
      className="PreferredNameModal"
      isOpen={open}
      appElement={document.body}
    >
      <p>
        Would you like to update your preferred name to be the same as your
        preferred name in other Monash systems?
      </p>
      <div className="buttons">
        <Button onClick={() => handleSyncPreferredName(true)}>
          Yes, sync my preferred name
        </Button>
        <Button onClick={() => handleSyncPreferredName(false)}>
          No, keep my current name on aMigo
        </Button>
      </div>
    </Modal>
  )
}

/**
 * Shows a modal when the user's preferred name mode is not set.
 *
 * The modal will ask the user to select whether they want their
 * name to be synced with the Monash system provided preferred name,
 * or to use their current name on aMigo (and opt out of syncing).
 */
export const PreferredNameModal = withUserId(
  firebind(PreferredNameModalComponent, {
    profile: ({ userId }) => `users/${userId}/profile`
  })
)
