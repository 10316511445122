import { isEmpty, keys, pickBy, sortBy, clone } from 'lodash'

const truth = () => true

export const selectionFunction = (data, query) => {
  if (isEmpty(query)) { return truth }

  let targetFunction
  if (query.orderByChild) {
    targetFunction = (object, key) => object[query.orderByChild]
  }
  if (query.orderByKey) {
    targetFunction = (object, key) => key
  }
  if (query.orderByValue) {
    targetFunction = (value) => value
  }
  if (typeof targetFunction !== 'function') { return truth }

  let sortedKeys = []
  if (query.limitToFirst || query.limitToLast) {
    sortedKeys = sortBy(keys(data), (k) => targetFunction(data[k], k))
  }

  return (value, key) => {
    const target = targetFunction(value, key)
    const index = sortedKeys.indexOf(key)
    return (
      (!query.equalTo || target === query.equalTo) &&
      (!query.startAt || target >= query.startAt) &&
      (!query.endAt || target <= query.endAt) &&
      (!query.limitToFirst || index < query.limitToFirst) &&
      (!query.limitToLast || (sortedKeys.length - index) <= query.limitToLast)
    )
  }
}

function normaliseData(data) {
  if (data === null || data === undefined) { return {}}
  if (typeof data === 'object') { return clone(data) }
  return data
}

export default function executeQuery (data: {}, query) {
  let output = data
  if (typeof data === 'object' && !isEmpty(query)) {
    output = pickBy(data, selectionFunction(data, query))
    console.log("Filtered query", data, query, output)
  }

  output = normaliseData(output)
  return output
}