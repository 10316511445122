// @flow

import 'whatwg-fetch'
import qs from 'qs'
import React from 'react'


async function reverse(lat: number, lng: number, zoom: number, options: {addressDetails?: boolean, extraTags?: boolean, nameDetails?: boolean}) {
  let queryOptions = {}
  const optionsAsNumbers = ['addressDetails', 'extraTags', 'nameDetails']
    .forEach(key => queryOptions[key.toLowerCase()] = options[key] ? 1 : 0)
  const query = {
    format: 'json',
    'accept-language': 'en-au,en',
    lat, lon: lng, zoom,
    ...optionsAsNumbers
  }
  const queryString = qs.stringify(query)
  console.log("Query", queryString)
  const response = await fetch('//nominatim.openstreetmap.org/reverse?' + queryString)

  return response.json()
}

export async function cityCountry(lat: number, lng: number): Promise<string> {
  const resp = await reverse(lat, lng, 7, {addressDetails: true})
  console.log("Got response", resp)
  const {country, city} = resp.address
  return `${city || 'A City'}, ${country || 'A Country'}`
}

export async function placeNameAtZoom(lat: number, lng: number, zoom: number): Promise<string> {
  const query = {
    lat,
    lon: lng,
    zoom,
    format: 'json',
    namedetails: 0,
    extratags: 0,
    addressdetails: 0,
    'accept-language': 'en-au,en'
  }


  const queryString = qs.stringify(query)
  console.log("Query", query, queryString)
  const response = await fetch('//nominatim.openstreetmap.org/reverse?' + queryString)

  const json = await response.json()
  return json.display_name
}

type Props = {lat: number, lng: number, zoom: number, render?: (string) => any}
type State = {name: string}

const defaultName = ''

export class PlaceName extends React.Component<Props, State> {
  state: State = {name: defaultName}

  async fetchName() {
    const {lat, lng, zoom} = this.props
    const name = (await placeNameAtZoom(lat, lng, zoom)) || defaultName
    this.setState({name})
  }

  componentDidMount = this.fetchName
  UNSAFE_componentWillReceiveProps = this.fetchName

  render() {
    const {render} = this.props
    const {name} = this.state
    return render ? render(name) : name
  }
}