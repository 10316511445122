// @flow

import { Navbar, NavMenu, Select } from '@upgrowth/react-fulcrum'
import React from 'react'
import { map } from 'lodash'
import { FaAngleDown } from 'react-icons/fa'
import classnames from 'classnames'
import { firebind, withUserId } from '../services/database'
import './MapHeader.scss'

const AngleRenderer = ({ isOpen }) => (
  <FaAngleDown className={classnames('AngleRenderer', { open: isOpen })} />
)

export default withUserId(firebind(({ selectedGroupId, onChange, groups }) => (
  <Navbar variant="light" className="MapHeader">
    <NavMenu variant="light">
      <Select onChange={onChange}
        searchable={false}
        arrowRenderer={AngleRenderer}
        value={selectedGroupId}
        clearable={false}
        options={map(groups, ({ name }, value) => ({ label: name, value }))} />
    </NavMenu>
  </Navbar>

), {
  groups: ({ userId }) => `users/${userId}/groups`
}
))
