// @flow
import React from 'react'
import { Switch, Route } from '@upgrowth/reactkit/lib/router'

import Section from '../compositions/Section'

import EventsMenu from './EventsMenu'
import EventsEditor from './EventsEditor'
import SavedEvents from './SavedEvents'
import AllEvents from './AllEvents'
import MyEvents from './MyEvents'
import Event from './Event'
import UpcomingEvents from './UpcomingEvents'

export default () => {
  // render

  return (
    <Section
      sidebar={<EventsMenu />}
      classname="events-calendar"
      primeRoute="events"
    >
      <Switch>
        <Route name="editEvent" component={EventsEditor} />
        <Route name="allEvents" component={AllEvents} />
        <Route name="myEvents" component={MyEvents} />
        <Route name="upcomingEvents" component={UpcomingEvents} />
        <Route name="savedEvents" component={SavedEvents} />
        <Route name="event" component={Event} />
        <Route component={UpcomingEvents} />
      </Switch>
    </Section>
  )
}
