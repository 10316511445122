// @flow

import * as React from "react";
import Post from "./Post";
import { firebind } from '../services/database'

const PromotedPost = ({groupId, postId, post}) => {
  if (!post) {
    return null;
  }

  return <Post groupId={groupId} postId={postId} post={post} />
}

export default firebind(PromotedPost, {
  post: ({groupId, postId}) => `groups/threads/${groupId}/${postId}`
})
