// @flow

import React from "react";
import { Button } from "@upgrowth/react-fulcrum/lib/index";
import monashLogo from "../compositions/monash-shield.svg";
import "./Login.scss";
import { attemptAutoLogin, loginWithGoogle } from "../services/auth";
import { isEmpty } from "lodash";
import { firebind } from "../services/database";

type Props = { servicesHost: string, loading: boolean };
type State = { mounted: boolean };

class Login extends React.Component<Props, State> {
  // amigo-uat.apps.monash.edu - "https://monashuniqa.oktapreview.com/oauth2/v1/authorize?client_id=0oahh9djakyyFEiZE0h7&response_type=id_token&scope=openid%20profile%20groups%20email&redirect_uri=https%3A%2F%2Famigo-uat.apps.monash.edu%2Flogin&nonce=amigo&state=amigo"
  // monash-amigo-qat.firebaseapp.com - "https://monashuniqa.oktapreview.com/oauth2/v1/authorize?client_id=0oahh9djakyyFEiZE0h7&response_type=id_token&scope=openid%20profile%20groups%20email&redirect_uri=https%3A%2F%2Fmonash-amigo-qat.firebaseapp.com%2Flogin&nonce=amigo&state=amigo"
  // monash-amigo-qat.appspot.com "https://monashuniqa.oktapreview.com/oauth2/v1/authorize?client_id=0oahh9djakyyFEiZE0h7&response_type=id_token&scope=openid%20profile%20groups%20email&redirect_uri=https%3A%2F%2Fmonash-amigo-qat.appspot.com%2Flogin&nonce=amigo&state=amigo"
  async componentDidMount() {
    // 'production' here means not local env, it includes dev, uat and prod
    if (process.env.NODE_ENV !== "production") {
      await loginWithGoogle();
    } else {
      await attemptAutoLogin();
    }
    this.setState({ mounted: true });
  }

  state: State = { mounted: false };

  render() {
    const { mounted } = this.state;
    const { authorizeUrl, loading } = this.props;
    if (!mounted || loading) {
      return null;
    } // Prevent flash of loading screen

    const buttonProps = !isEmpty(authorizeUrl)
      ? { as: "a", href: authorizeUrl }
      : { onClick: loginWithGoogle };
    return (
      <div className="Login">
        <div className="content">
          <img src={monashLogo} width={80} alt="Monash University" />
          <h1>Welcome to aMigo, your portal to Monash University.</h1>
          <h2>Connect with other students like you.</h2>
          {!loading && (
            <Button variant="secondary" {...buttonProps}>
              Start Connecting
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default firebind(Login, {
  // servicesHost: () => `public/servicesHost`,
  authorizeUrl: () => `public/auth/authorizeUrl`
});
