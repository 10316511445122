import * as React from 'react'
import Screen from '../compositions/Screen'
import SectionNav from '../compositions/SectionNav'
import UnreadBadge from "../messages/UnreadBadge";
import './UsersMagazine.scss'
import UsersMagazine from './UsersMagazine'
import {withParams} from '@upgrowth/reactkit/lib/router/index'
import {FriendRoles} from '../types/types'
import {pickBy} from 'lodash'
import {Badge} from '@upgrowth/react-fulcrum'
import { firebind, withUserId } from '../services/database'

const FriendsMagazine = ({friends, hideNav, loading}) => (
    <Screen header={!hideNav && <SectionNav header="All Friends" backRoute="friends"/>}>
      {!loading &&
      <UsersMagazine users={pickBy(friends, (role) => role === FriendRoles.friend)}
                     actionProvider={friendId =>
                       <Badge className="unread-badge"><UnreadBadge sourceId={friendId} sourceType="user"/></Badge>
                     }/>
      }
    </Screen>
)

export default withParams(withUserId(firebind(FriendsMagazine, {
  friends: ({userId}) => `users/${userId}/friends`
})))
