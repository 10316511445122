// @flow
import * as React from 'react'
import {NavMenu, Navbar, Button} from '@upgrowth/react-fulcrum'
import {Link} from '@upgrowth/reactkit'
import backIcon from './back.svg'
import './SectionNav.scss'
import {withRouter} from 'react-router-dom'
import type {History} from 'react-router-dom'

type Props = {
  backRoute?: string,
  historyBack?: boolean,
  contextButton?: any,
  header?: any,
  history: History
}

const SectionNav = ({backRoute, historyBack, contextButton, header, history, ...routeParams}: Props) => (
  <Navbar variant="light" className="SectionNav">
    <NavMenu variant="light">
      {backRoute
        &&  <Button variant="light" className="shape-round outline" as={Link} name={backRoute} {...routeParams}>
              <img src={backIcon} alt="Back"/>
            </Button>}

      {historyBack
        &&  <Button variant="light" className="shape-round outline" onClick={() => history.goBack()}>
              <img src={backIcon} alt="Back"/>
            </Button>}

      {header && <h2>{header}</h2>}
      {contextButton || <div className="context-placeholder"/>}
    </NavMenu>
  </Navbar>
)

export default withRouter(SectionNav)

//
// : <Button variant="primary" className="shape-round" onClick={() => onQueryChange('')}>
//   <img src={searchIcon} alt="Search"/>
// </Button>
//