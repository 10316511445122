// @flow

import * as React from 'react'
import {withRouter} from 'react-router-dom'
import routes from '../routes'
import * as classnames from 'classnames'
import {map} from 'lodash'
import './Spread.scss'
import type {Location} from 'react-router-dom'

type SpreadProps = {
  header?: React.Node,
  className?: string,
  footer?: React.Node,
  screens: { [routeName: string]: (active: boolean, key: string) => React.Node },
  location: Location
}
export const Spread = withRouter(({header, screens, className, location, footer}: SpreadProps) =>
  <div className={classnames("Spread", className)}>
    <div className="spread-content">
      <div className="spread-header">{header}</div>
      <div className="spread-screens">
        {map(screens, (screen, routeName) => screen(location.pathname === routes[routeName], routeName))}
      </div>
    </div>
    <div className="spread-footer">{footer}</div>
  </div>
)
export const activeWrapperRender: (React.ComponentType<>) => (active: boolean, key: string) => React.Node = (Component: *) => {
  const RoutedComponent = withRouter(Component)
  return (active: boolean, key: string) => <div key={key} className={classnames("ActiveWrapper", {active})}><RoutedComponent/></div>
}