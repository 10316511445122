import React, { useState } from 'react'
import { Button, Modal } from '@upgrowth/react-fulcrum'
import Dropzone from 'react-dropzone'

import './CSVUploadModal.scss'

/**
 * This component renders a generic modal that has a title
 * and a Dropzone that accepts CSV files.
 *
 * Pass in children to this component to render instructions
 * for the CSV upload file above the dropzone area.
 */
const CSVUploadModal = ({
  open = false,
  onClose = () => {},
  canClose = false,
  title = 'Upload modal',
  file = null,
  setFile = () => {},
  loading = false,
  onUpload = () => {},
  hideDropzone = false,
  error = null,
  children
}) => {
  const [hover, setHover] = useState(false)

  const dropzoneMethods = {
    onDragEnter: () => setHover(true),
    onDragLeave: () => setHover(false),
    onDropRejected: () => setHover(false),
    onDropAccepted: files => {
      setHover(false)
      setFile(files[0])
    }
  }

  return (
    <Modal
      className="CSVUploadModal"
      isOpen={open}
      onRequestClose={onClose}
      appElement={document.body}
      closeElement={canClose ? null : 'Close'}
    >
      <h1>{title}</h1>
      {children}
      {!hideDropzone && (
        <>
          <Dropzone
            className={`Dropzone ${loading ? 'loading' : ''}`}
            multiple={false}
            accept="text/csv, application/vnd.ms-excel"
            disabled={loading}
            {...dropzoneMethods}
          >
            <div
              className={`DropzoneBox
                ${hover ? 'Hover' : ''}
                ${loading ? 'Loading' : ''}
              `}
            >
              {file ? (
                <span>
                  File: <b>{file.name}</b>
                </span>
              ) : (
                'Drop a CSV file here or click to choose a file'
              )}
            </div>
          </Dropzone>
          {error && (
            <div className="Error">
              <b>Error: </b>
              {error}
            </div>
          )}
          <Button
            className="Upload"
            disabled={!file || loading}
            onClick={onUpload}
          >
            {loading ? 'Uploading file...' : 'Upload'}
          </Button>
        </>
      )}
    </Modal>
  )
}

export default CSVUploadModal
