// @flow

import './Maintenance.scss'
import React from 'react'
import monashLogo from "../compositions/monash-shield.svg";
import { firebind } from '../services/database'

const Maintenance = ({ available, children }) =>
  available === false ? <div className="Maintenance">
    <div className="message">
      <div className="logo">
        <img src={monashLogo} width={80} alt='Monash University' />
      </div>
      <div className="text">
        <p>This system is currently undergoing maintenance and will be available after 9:00 AM Monday, 18th May, 2020 (AEST)</p>
        <p>If your matter is urgent, please contact the service desk on +61 3 9905 1777</p>
      </div>
    </div>
  </div>
    : <React.Fragment children={children} />

export default firebind(Maintenance, {
  available: 'public/available'
})