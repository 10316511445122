import {cityCountry} from '../services/geocoding'
import * as React from 'react'
import {shareLocation} from "../services/users";

type Props = {
  render: (lat: number, lng: number, name: string) => any,
  renderError: (error?: PositionError) => any
}

type State = {
  nonlocatable?: boolean,
  error?: PositionError,
  lat?: number,
  lng?: number,
  name?: string,
}


export default class LocationUpdater extends React.Component<Props, State> {
  state: State = {}
  positionOptions = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0
  }

  componentDidMount = () => {
    this.setState({nonlocatable: false})
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.handlePosition,
        this.handlePositionError,
        this.positionOptions)
    } else {
      this.handlePositionError()
    }
  }

  handlePosition = async (pos: Position) => {
    const lat = pos.coords.latitude
    const lng = pos.coords.longitude

    this.setState({lat, lng})
    const name = await cityCountry(lat, lng)
    this.setState({name})
    shareLocation(lat, lng, name)
  }

  handlePositionError = (error?: PositionError) => {
    console.log("Couldn't get position", error)
    this.setState({nonlocatable: true, error})
  }

  render() {
    const {render, renderError} = this.props
    const {nonlocatable, error, lat, lng, name} = this.state
    if (nonlocatable) {
      return renderError(error, this.componentDidMount)
    }
    
    return render({lat, lng, name})
  }
}
