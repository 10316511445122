// @flow

import React from 'react'
import UnreadFriendMessageCount from '../friends/UnreadFriendMessageCount'
import './SectionBlip.scss'

const Blip = ({count}) => count > 0 ? <div className="SectionBlip"/> : null

export default ({sourceType}: {sourceType: 'user' | 'group'}) => (
  sourceType === 'user'
    ? <UnreadFriendMessageCount render={(unread, requests) => {
      // console.log("BLIP ZONE", unread, requests)
      return (<Blip count={unread + requests}/>)
    }}/>
    : null // FUTURE STATE
)