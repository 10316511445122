// @flow
import React from 'react'
import { firebind, withUserId } from '@upgrowth/firekit'

import SidebarMenu from '../compositions/SidebarMenu'

const USER_MENU = [
  { key: 'upcoming', label: 'Upcoming', name: 'upcomingEvents' },
  { key: 'saved', label: 'Saved', name: 'savedEvents' }
]

const MODERATOR_MENU = [
  { key: 'my', label: 'My events', name: 'myEvents' },
  ...USER_MENU,
  { key: 'create', label: 'Create', name: 'editEvent', eventId: 'new' }
]

const ADMIN_MENU = [
  { key: 'all', label: 'All events', name: 'allEvents' },
  ...MODERATOR_MENU
]

function EventsMenu({ isAdmin, isGlobalModerator, userId }) {
  return (
    <SidebarMenu
      className="events-menu"
      links={{
        Events:
          isAdmin === true
            ? ADMIN_MENU
            : isGlobalModerator === true
            ? MODERATOR_MENU
            : USER_MENU
      }}
    />
  )
}

// export default EventsMenu

export default withUserId(
  firebind(EventsMenu, {
    isAdmin: ({ userId }) => `users/${userId}/standing/admin`,
    isGlobalModerator: ({ userId }) =>
      `users/${userId}/standing/globalModerator`
  })
)
