import React, { useState } from 'react'
import { Button, Card } from '@upgrowth/react-fulcrum'

import SortOrderModal from './SortOrderModal'

import './SortOrder.scss'

/**
 * This component opens up the SortOrderModal.
 */
const SortOrderCard = () => {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <Card>
      <div className="inset">
        <h3>Manage sort order</h3>
        <p>Change the ordering groups are displayed in.</p>
      </div>
      <Button onClick={() => setModalOpen(true)}>Open</Button>
      <SortOrderModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </Card>
  )
}

export default SortOrderCard
