// @flow

import './SmallCard.scss'
import {Card, Flex} from "@upgrowth/react-fulcrum";
import React from "react";

const SmallCard = ({className, backgroundImage, picture, title, subtitle, action}) => {
  return (
      <Card className={`SmallCard ${className}`} direction="row">
        <div className="card-picture" style={backgroundImage}>
          {picture}
        </div>
        <div className='card-info'>
          <div className="card-title">{title}</div>
          <div className="card-subtitle">{subtitle}</div>
        </div>
        <Flex />
        <div className="card-actions">
          {action}
        </div>
      </Card>
  )
}

export default SmallCard