import './Maps.scss'
import StudentCluster from './StudentCluster'
import FirstMap from './FirstMap'
import MapScreen from './MapScreen'
import Section from '../compositions/Section'
import {Route, Switch} from '@upgrowth/reactkit/lib/router/index'
import React from 'react'
import SetLocationToUse from './SetLocationToUse'
import { firebind, withUserId } from '../services/database'

export default withUserId(firebind(({locationSharing, location, loading}) => {
    if (loading) return null
    return (
        <Section className="Maps">
            {locationSharing && typeof location === 'string'
                ? (
                    <Switch>
                        <Route name="firstMap" exact component={FirstMap}/>
                        <Route name="mapCluster" component={StudentCluster}/>
                        <Route name="groupMap" component={MapScreen}/>
                    </Switch>
                ) : <SetLocationToUse/>}
        </Section>
    )
}, {
    locationSharing: ({userId}) => `users/${userId}/secrets/locationSharing`,
    location: ({userId}) => `users/${userId}/profile/location`
}))