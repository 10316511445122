// @flow
import type { History, Location } from 'react-router-dom'
import React from 'react'
import { Button } from '@upgrowth/react-fulcrum/lib/index'
import { Link } from '@upgrowth/reactkit/lib/router'
import qs from 'qs'
import { loginWithOAuthToken } from '../services/auth'

export default class UseToken extends React.Component<{ location: Location, history: History }, { error?: Error }> {
  state = {}

  async componentDidMount() {
    const { location: { hash } } = this.props
    let { id_token } = qs.parse(hash.replace(/^#/, ''))

    try {
      await loginWithOAuthToken(id_token)
    } catch (error) {
      console.log("Cannot login with OAuth token", error)
      this.setState({ error })
    }
  }

  render() {
    const { error } = this.state
    if (error) {
      return (
        <div>
          <h1>
            Could not login: {error.message}
          </h1>
          <Button as={Link} name="groups">Try again</Button>
        </div>
      )
    }
    return null
  }
}

