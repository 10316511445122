//@flow

export type UserId = string
export type GroupId = string
export type MessageId = string

export const MODERATOR_ROLE = 'mod'
export const GLOBAL_MODERATOR_ROLE = 'globalmod'

export const MODERATORS_GROUP_ID = 'moderators'
export const MONASH_OFFICIAL_GROUP_ID = 'monash-university'

export const GroupRoles = {
  mod: MODERATOR_ROLE,
  user: 'user',
  banned: 'banned',
  muted: 'muted'
}
export type GroupRole = $Keys<typeof GroupRoles>
export const FriendRoles = {
  friend: 'friend',
  requested: 'requested',
  rejected: 'rejected'
}
export type FriendRole = $Keys<typeof FriendRoles>

export const GroupTypes = { interest: 'Interests', monash: 'Monash' }
export type GroupType = $Keys<typeof GroupTypes>

export const CHAT_CHARACTER_LIMIT = 500
export const BIO_CHARACTER_LIMIT = 150

export type UserProfile = {
  // global read, user read-write
  name: string,
  firstName: string,
  lastName: string,
  faculty?: string,
  campus?: string,
  facultyId?: string,
  campusId?: string,
  avatar: string,
  preferredNameMode?: 'system' | 'custom'
}

export type UserStanding = {
  // global read
  admin: boolean,
  globalModerator?: boolean,
  groupModerator?: boolean
}

export type UserSecrets = {
  // user read
  email: string,
  read: {
    user: {
      [UserId]: MessageId
    },

    group: {
      [GroupId]: number
    }
  },
  search: {
    [email: string]: { _status: 'ready' | 'complete', userId?: UserId }
  }
}
export type UserGroups = { [GroupId]: { type: GroupType, role: GroupRole } }

export type Attachments = {
  image?: string,
  mentions?: UserId[]
}

export type Message = {
  from: UserId,
  to: UserId | GroupId,
  body: string,
  at: number,
  attachments?: Attachments,
  voteCount: number
}

export type User = {
  profile: UserProfile,

  secrets: UserSecrets,

  standing: UserStanding,
  groups: UserGroups,
  friends: { [UserId]: FriendRole },
  messages: { [UserId]: { [MessageId]: Message } }
}

export type Thread = Message & {
  updatedAt: number,
  replyCount: number
}

export type Report = {
  reportedBy: UserId,
  messageId: MessageId,
  sourceType: 'user' | 'group' | 'thread',
  sourceId: UserId | GroupId,
  threadId?: string,
  at: number
}

export type MapPoint = {
  position: [number, number]
}

export type GroupMessages = { [MessageId]: Message }

export type GroupDetails = {
  name: string,
  type: GroupType,
  description: string,
  photo: string,
  tags: string[],
  sortKey: string,
  hidden?: boolean
}

export type GroupCount = { count: number, type: GroupType, name: string }
export type GroupData<T> = { [GroupId]: T }
export type MessageData<T> = { [MessageId]: T }
export type UserData<T> = { [UserId]: T }

// This is purely for documentation sake, and may not be consistent with the database
// stored at Firebase. For a more consistent structure of the database, see
// database.rules.bolt
export type Database = {
  groups: {
    count: { [GroupType]: GroupData<GroupCount> },
    details: GroupData<GroupDetails>,
    maps: GroupData<UserData<MapPoint>>,
    members: GroupData<UserData<GroupRole>>,
    promotedPosts: GroupData<MessageData<boolean>>,
    replies: GroupData<Message>,
    threads: GroupData<Thread>,
    votes: GroupData<MessageData<UserData<Vote>>>
  },
  promotedPosts: { [MessageId]: GroupId },
  public: { updatedTerms: number },
  users: UserData<User>
}
