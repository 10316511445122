import { escapeRegExp, reduce } from 'lodash'

const routes = {
  groups: '/groups',
  allGroups: '/groups/all',
  newGroup: '/groups/new',
  group: '/groups/:groupId',
  replies: '/groups/:groupId/threads/:threadId',
  editGroup: '/groups/:groupId/edit',
  friendsMenu: '/friendsMenu',
  friends: '/friends',
  friendRequests: '/friends/requests',
  unreadFriends: '/friends/unread',
  addFriend: '/friends/add',
  allFriends: '/friends/all',
  friend: '/friends/:friendId',
  profile: '/profile',
  onboarding: '/onboarding',
  onboardProfile: '/onboarding/profile',
  onboardPicture: '/onboarding/picture',
  onboardLocation: '/onboarding/location',
  onboardGroupsLocationMonash: '/onboarding/groups-location-monash',
  onboardGroupsInterest: '/onboarding/groups-interest',
  mapCluster: '/map/:groupId/cluster',
  groupMap: '/map/:groupId',
  firstMap: '/map',
  events: '/events',
  event: '/events/:eventId',
  upcomingEvents: '/events/upcoming',
  allEvents: '/events/all',
  myEvents: '/events/my',
  savedEvents: '/events/saved',
  editEvent: '/events/edit/:eventId',
  // saml: '/saml',
  // useToken: '/useToken',
  login: '/login',
  admin: '/admin',
  logout: '/logout',
  dashboard: '/dashboard'
}

export default routes
export const resolveRoute = (
  routeName: $Keys<typeof routes>,
  mapping: { [string]: string }
) =>
  reduce(
    mapping,
    (subbedPath, value, key) =>
      subbedPath.replace(new RegExp(escapeRegExp(`:${key}?`) + '?'), value),
    routes[routeName]
  )
