// @flow

import React from 'react'
import { NavMenu, NavItem, Sidebar, Flex, Badge } from '@upgrowth/react-fulcrum'
import { NavLink } from '@upgrowth/reactkit/lib/index'
import { isNumber, map } from 'lodash'
import * as classnames from 'classnames'

import './SidebarMenu.scss'

type Props = {
  className: string,
  children?: React.Node,
  links: {
    [sectionName: string]: {
      key: string,
      label: string,
      name: string,
      badge: React.Element<any>
    }[]
  }
}

function styleBadge(badge: any) {
  if (isNumber(badge)) {
    if (badge === 0) return ''
    if (badge < 9) return `0${badge.toString()}`
    if (badge > 99) return 99
  }
  return badge
}

const SidebarMenu = ({ links, className, children }: Props) => {
  return (
    <Sidebar className={classnames('SidebarMenu', className)}>
      <NavMenu>
        {map(links, (sectionLinks, sectionName) => (
          <div className="NavGroup" key={sectionName}>
            <h3>{sectionName}</h3>
            {sectionLinks.map(({ badge, label, ...route }) => (
              <NavItem as={NavLink} key={route.key} {...route}>
                <div className="nav-label">{label}</div>
                <Flex />
                {badge !== undefined && (
                  <Badge variant="light" size="small">
                    {styleBadge(badge)}
                  </Badge>
                )}
              </NavItem>
            ))}
          </div>
        ))}
        {children}
      </NavMenu>
    </Sidebar>
  )
}

export default SidebarMenu
