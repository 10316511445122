import React from 'react'
import Section from '../compositions/Section'
import Group from './Group'
import { Route, Switch } from '@upgrowth/reactkit/lib/router/index'
import GroupsMenu from './GroupsMenu'
import GroupsMagazine from './GroupsMagazine'
import GroupDetailsEditor from './GroupDetailsEditor'

export default () => (
  <Section className="Groups" sidebar={<GroupsMenu />} primeRoute="groups">
    <Switch>
      <Route name="allGroups" component={GroupsMagazine} />
      <Route name="editGroup" component={GroupDetailsEditor} />
      <Route
        name="group"
        render={props => <Group {...props} key={props.match.params.groupId} />}
      />
      <Route component={GroupsMagazine} />
    </Switch>
  </Section>
)
