// @flow

import * as React from 'react'
import './Onboarding.scss'
import { Button, Checkbox, FormRow, Label } from '@upgrowth/react-fulcrum'
import { Link } from '@upgrowth/reactkit'
import { FaAngleDoubleRight } from 'react-icons/fa'
import withProfile from '../services/withProfile'
import OnboardingTabs from "./OnboardingTabs"
import type { UserProfile } from '../types/types'
import {
  confirmTermsAccepted,
  setLocationSharing,
  shareLocation,
  unshareLocation
} from "../services/users";
import { cityCountry } from "../services/geocoding";
import { currentUser } from '../services/database'
import TermsAndConditionsCheckbox from './TermsAndConditionsCheckbox'

const OnboardProfile = ({ profile: { firstName, lastName, faculty, campus } = {} }: { profile: UserProfile }) => {

  const toggleLocationSharing = async (enabled) => {
    await setLocationSharing(enabled)

    if (enabled && navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(
        handlePosition,
        handlePositionError,
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        })
    }
    else {
      await unshareLocation()
    }
  }

  const handlePosition = async (pos) => {
    const lat = pos.coords.latitude
    const lng = pos.coords.longitude
    const name = await cityCountry(lat, lng)
    shareLocation(lat, lng, name)
  }

  const handlePositionError = (error) => {
    console.log("Couldn't get position", error)
  }

  return (
    <div className="Profile">
      <OnboardingTabs currentTab="OnboardProfile" />
      <h2>Let's get you started with your profile </h2>
      <h5>Make sure we have your personal details correct.</h5>
      <p>Please contact Student Services if the details are not correct.</p>
      <div className="Form">
        <Label>Given Name</Label>
        <h5>{firstName}</h5>
        <Label>Family Name</Label>
        <h5>{lastName}</h5>
        <Label>Email</Label>
        <h5>{currentUser().email}</h5>
        <Label>Faculty</Label>
        <h5>{faculty}</h5>
        <Label>Campus</Label>
        <h5>{campus}</h5>

      </div>
      <div>
        <TermsAndConditionsCheckbox />
      </div>
      <div>
        <Checkbox id="locationSharing" onChange={toggleLocationSharing} label="Share my current location." />
        <div>Other users will not see your exact location, only an approximation to about a sq. kilometre. You can change location sharing whenever you want.</div>
      </div>
      <FormRow className="nextBtn">
        <Button as={Link} variant="secondary" name="onboardPicture" onClick={confirmTermsAccepted}>Next <FaAngleDoubleRight className="nextBtnIcon" /></Button>
      </FormRow>
    </div>
  )
}

export default withProfile(OnboardProfile)