// @flow
import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Link } from '@upgrowth/reactkit'

import { updateDB } from '../services/database'

import pin from './icons/pin.svg'
import bookmark from './icons/bookmark.svg'
import calendar from './icons/calendar.svg'
import clock from './icons/clock.svg'
import web from './icons/web.svg'

import styles from './events.module.scss'

function EventsList({
  events,
  userId,
  title,
  loading,
  emptyMsg,
  loadingMsg = 'Loading events...',
  upcomingFirst = false,
  updateLocalData = false
}) {
  const [eventsList, setEventsList] = useState([])

  useEffect(() => {
    if (events) {
      const list = [...events]
      list.sort((a, b) => {
        const aTime = new Date(a.time).getTime()
        const bTime = new Date(b.time).getTime()
        return upcomingFirst ? aTime - bTime : bTime - aTime
      })
      setEventsList(list)
    }
  }, [events, setEventsList, upcomingFirst])

  const saveEvent = (id, add) => {
    // Save event on server
    const updates = {
      [`events/${id}/saved/${userId}`]: add,
      [`users/${userId}/events/${id}`]: add
    }
    updateDB('/', updates)

    // This is required as
    if (updateLocalData && eventsList) {
      setEventsList(
        eventsList.map(event => {
          if (event.id === id) {
            if (!event.saved) event.saved = {}
            if (add) event.saved[userId] = add
            else delete event.saved[userId]
          }
          return event
        })
      )
    }
  }

  return (
    <div className={styles.eventsListContainer}>
      <h1>{title}</h1>
      <ul className={styles.eventsList}>
        {eventsList && !eventsList.length && !loading && <h3>{emptyMsg}</h3>}
        {loading && <h3 className={styles.loading}>{loadingMsg}</h3>}
        {eventsList &&
          eventsList.map(event => {
            if (!event) return null
            const {
              id,
              saved,
              photo,
              name,
              time,
              location,
              externalLink
            } = event
            const eventSaved = saved && saved[userId]
            const savedNum = saved && Object.entries(saved).length

            return (
              <li
                key={id}
                className={
                  new Date(time).getTime() < Date.now() ? styles.past : ''
                }
              >
                {photo && (
                  <div className={styles.eventImg}>
                    <img src={photo} alt={name}></img>
                  </div>
                )}
                <div className={styles.eventDetails}>
                  <h3>
                    <Link name="event" eventId={id}>
                      <span>{name}</span>
                    </Link>
                    <button
                      className={`${styles.saveBtn} ${
                        eventSaved ? styles.active : ''
                      }`}
                      onClick={e => saveEvent(id, eventSaved ? null : true)}
                    >
                      {eventSaved ? 'Unsave' : 'Save'}
                    </button>
                  </h3>

                  <div className={styles.content}>
                    {/* left */}
                    <div className={styles.contentLeft}>
                      <div className={styles.eventTime}>
                        <img src={clock} alt="Time"></img>
                        {format(new Date(time), 'h:mm a (O)')}
                      </div>
                      <div className={styles.eventTime}>
                        <img src={calendar} alt="Date"></img>
                        {format(new Date(time), 'iii dd/MM/yyyy')}
                      </div>

                      {Boolean(savedNum) && (
                        <div className={styles.eventNumbers}>
                          <img src={bookmark} alt="Saved"></img>
                          <b>{savedNum}</b>{' '}
                          {savedNum > 1 ? 'people have' : 'person has'} saved
                          this event.
                        </div>
                      )}
                    </div>

                    {/* right */}
                    <div className={styles.contentRight}>
                      <div className={styles.eventLocation}>
                        <img src={pin} alt="Location"></img>
                        {location}
                      </div>
                      {externalLink && (
                        <div className={styles.eventLink}>
                          <img src={web} alt="Web link"></img>
                          <a
                            href={
                              externalLink.startsWith('http')
                                ? externalLink
                                : `https://${externalLink}`
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {externalLink}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default EventsList
