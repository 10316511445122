// @flow

import {ContentColumn} from "@upgrowth/react-fulcrum";
import LatestPost from "./LatestPost";
import * as React from "react";
import { firebind, withUserId } from '../services/database'
import Sorted from '../compositions/Sorted'

const LatestActivity = ({myGroups}) => {
  return (
      <ContentColumn className='LatestActivity'>
        <h5 className='section-title'>Latest activity in your groups</h5>
        <div className="Posts">
          <Sorted collection={myGroups}>
            {(group, key, onChanged) => <LatestPost key={key} groupId={key} group={group} onChanged={onChanged} />}
          </Sorted>
        </div>
      </ContentColumn>
  )
}

export default withUserId(firebind(LatestActivity, {
  myGroups: ({userId}) => `users/${userId}/groups`
}))