import React, { useState, useEffect } from 'react'
import { Button, Card } from '@upgrowth/react-fulcrum'

import { uploadFile, setDB, newKey } from '../../services/database'

import JSONTable from '../../compositions/JSONTable'
import CSVUploadModal from '../CSVUploadModal'
import SAMPLE from './sample'

import './BatchGroupUpdate.scss'

/**
 * This component opens up a modal that uploads a CSV file
 * to Firebase storage for processing to assign users to
 * groups.
 */
const BatchGroupUpdateCard = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [key, setKey] = useState('')
  const [showExample, setShowExample] = useState(false)

  // Upload the file and trigger the processing
  const onUpload = async () => {
    if (!file) return setError('No file')
    setLoading(true)
    setError(null)
    try {
      const key = newKey()
      const location = `process/${key}-${Date.now()}-${file.name}`

      // Upload the file
      await uploadFile(file, location)

      // Create an object in the database that will trigger
      // a cloud function to start processing this file.
      await setDB(`files/${newKey()}`, {
        key,
        location,
        filename: file.name,
        type: 'GRANT_USERS_GROUP_ACCESS'
      })
      setFile(null)
      setKey(key)
    } catch (error) {
      console.error(error)
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  // Reset values when the modal opens
  useEffect(() => {
    if (modalOpen) {
      setLoading(false)
      setFile(null)
      setKey('')
      setError('')
      setShowExample(false)
    }
  }, [modalOpen, setLoading, setKey, setFile, setError])

  return (
    <Card className="BatchUserUpdate">
      <div className="inset">
        <h3>Batch group update</h3>
        <p>Upload a CSV file to update group membership.</p>
      </div>
      <Button onClick={() => setModalOpen(true)}>Upload</Button>
      <CSVUploadModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Batch group update"
        file={file}
        setFile={setFile}
        onUpload={onUpload}
        error={error}
        loading={loading}
        hideDropzone={Boolean(key)}
      >
        <p>
          Upload a CSV file with the following format to batch update user group
          membership.
        </p>
        <ul>
          <li>
            Use the column <code>email</code> to list the email of users to
            update.
          </li>
          <li>
            Use the column <code>groupId</code> to specify the id of an{' '}
            <b>existing group</b> to add the user to.
          </li>
          <li>
            Optional: use the column <code>role</code> to specify users which
            should become a moderator with the value 'mod'. Other values will be
            ignored.
          </li>
        </ul>
        <p>
          Additional columns may be added for reference purposes, but they will
          be ignored.
        </p>
        <p>
          If a given email does not map to an existing user, they will be added
          to the group (and assigned the moderator role if required) when they
          log in for the first time.
        </p>
        <p>
          <button
            className="link"
            onClick={() => setShowExample(show => !show)}
          >
            {showExample ? 'Hide example' : 'Show example'}
          </button>
        </p>
        {showExample && <JSONTable data={SAMPLE} />}
        {key && (
          <div className="Success">
            <h3>File uploaded successfully!</h3>
            <p>
              Your file will be processed in the background. Check the "Process
              queue" panel for more information.
            </p>
            <p>
              Job id: <code>{key}</code>
            </p>
          </div>
        )}
      </CSVUploadModal>
    </Card>
  )
}

export default BatchGroupUpdateCard
