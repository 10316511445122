// @flow
import React, { useState, useEffect } from 'react'
import { withUserId } from '@upgrowth/firekit'
import { withParams } from '@upgrowth/reactkit'
import { firebind } from '@upgrowth/firekit'
import { format } from 'date-fns'
import { Link } from '@upgrowth/reactkit/lib/router'

import { updateDB } from '../services/database'

import pin from './icons/pin.svg'
import bookmark from './icons/bookmark.svg'
import calendar from './icons/calendar.svg'
import clock from './icons/clock.svg'
import web from './icons/web.svg'

import styles from './events.module.scss'

function Event({ details, userId, eventId, isAdmin }) {
  const [saved, setSaved] = useState('')
  const [savedNum, setsavedNum] = useState('')

  useEffect(() => {
    if (details && userId) {
      setSaved(details.saved && details.saved[userId])
      setsavedNum(details.saved && Object.entries(details.saved).length)
    }
  }, [details, userId])

  const saveEvent = (id, add) => {
    const updates = {
      [`events/${id}/saved/${userId}`]: add,
      [`users/${userId}/events/${id}`]: add
    }

    updateDB('/', updates)
  }

  // render

  if (!details) return null

  if (Object.keys(details).length === 0)
    return <div className={styles.eventNotFound}>Event does not exist.</div>

  const { photo, name, owner, time, location, externalLink, body } = details

  return (
    <div className={styles.event}>
      {photo && (
        <img className={styles.eventDetailsImage} src={photo} alt={name}></img>
      )}

      <div className={styles.detailedEvent}>
        <div className={styles.eventTitle}>
          <h1>{name}</h1>
          <div className={styles.eventActions}>
            <button
              className={`${styles.saveBtn} ${saved ? styles.active : ''}`}
              onClick={e => saveEvent(details.id, saved ? null : true)}
            >
              {saved ? 'Unsave' : 'Save'}
            </button>
            {(isAdmin || owner === userId) && (
              // Show the edit button if user is an admin or if they are the owner
              <Link
                name="editEvent"
                eventId={eventId}
                className="Button variant-secondary shape-pill"
              >
                Edit event
              </Link>
            )}
          </div>
        </div>

        <div className={`${styles.eventDetails} ${styles.detailedViewMeta}`}>
          <div className={styles.content}>
            {/* left */}
            <div className={styles.contentLeft}>
              <div className={styles.eventTime}>
                <img src={clock} alt="Time"></img>
                {format(new Date(time), 'h:mm a (O)')}
              </div>
              <div className={styles.eventTime}>
                <img src={calendar} alt="Date"></img>
                {format(new Date(time), 'iii dd/MM/yyyy')}
              </div>

              {savedNum && (
                <div className={styles.eventNumbers}>
                  <img src={bookmark} alt="Saved"></img>
                  <b>{savedNum}</b>{' '}
                  {savedNum > 1 ? 'people have' : 'person has'} saved this
                  event.
                </div>
              )}
            </div>

            {/* right */}
            <div className={styles.contentRight}>
              <div className={styles.eventLocation}>
                <img src={pin} alt="Location"></img>
                {location}
              </div>
              {externalLink && (
                <div className={styles.eventLink}>
                  <img src={web} alt="Web link"></img>
                  <a
                    href={
                      externalLink.startsWith('http')
                        ? externalLink
                        : `https://${externalLink}`
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {externalLink}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* body */}
        <div className={styles.detailedViewBody}>{body}</div>
      </div>
    </div>
  )
}

export default withUserId(
  withParams(
    firebind(Event, {
      details: ({ eventId }) => `events/${eventId}`,
      isAdmin: ({ userId }) => `users/${userId}/standing/admin`
    })
  )
)
