// @flow

import { firekit } from '@upgrowth/firekit'
import { connect } from './track'
import Ping from 'ping.js'
import { getDB } from './database'
import { servicesHost } from './util'
import Waterbase from './waterbase/Waterbase'

// const originalHosts = {
//   'www.googleapis.com': 'googleApisHost',
//   'firebasestorage.googleapis.com': 'storageHost',
//   'securetoken.googleapis.com': 'secureTokenHost'
// }

const FORCE_NO_GOOGLE = localStorage.getItem('CHINA')
const GOOGLE_ANYWAY = false

const pingTimeoutMs = 3 * 1000
const ping = new Ping({ timeout: pingTimeoutMs })

export default async function setupEnvironment() {
  if (FORCE_NO_GOOGLE) {
    return setupNonGoogleEnvironment('force on')
  }
  try {
    console.info('Pinging google')
    const pingData = await new Promise((resolve, reject) =>
      ping.ping('https://www.google.com', (err, data) =>
        err ? reject(err) : resolve(data)
      )
    )
    console.info('Results', pingData)
    await setupGoogleEnvironment()
  } catch (err) {
    console.info('Failed')
    await setupNonGoogleEnvironment(err)
  }

  const { gaTrackingId, ga4MeasurementId } = (await getDB('public')) || {}
  await connect(gaTrackingId, ga4MeasurementId)
}

// const storageHost = 'https://firebasestorage.googleapis.com'
const isStorageRe = /^https:\/\/(firebase)?storage\.googleapis\.com/

const straightThrough = url => url
const intercept = baseUrl => url =>
  typeof url === 'string' && url.match(isStorageRe)
    ? url.replace('https://', baseUrl + '/client/storage/')
    : url

window.rewriteUrl = straightThrough

async function setupNonGoogleEnvironment(err) {
  if (GOOGLE_ANYWAY) {
    await setupGoogleEnvironment()
  }
  window.isGoogleAvailable = 'no'
  console.info('Google cannot be reached. Reverting to non-realtime mode.')
  console.log('Attempt log', err)
  const host = await servicesHost(true)
  const baseUrl = `${host}/client/db`
  window.rewriteImageUrl = intercept(host)
  console.log('Creating Waterbase')
  return new Waterbase(baseUrl)
}

async function setupGoogleEnvironment() {
  window.isGoogleAvailable = 'yes'
  console.info('Google reached successfully. Engaging realtime mode.')
  const confJson = process.env.REACT_APP_FIREBASE_CONFIG
  if (!confJson) {
    console.error('FATAL: Firebase config was not defined during deployment.')
    window.alert('Configuration error. Please contact the site administrator.')
    return
  }

  window.rewriteImageUrl = straightThrough
  const conf = JSON.parse(confJson)

  // conf.databaseURL = conf.databaseURL + '?ns=' + conf.projectId
  return firekit(conf)
}
