export default [
  {
    email: 'Orla.Finch@monash.edu',
    groupId: '66b7bf2b',
    groupName: 'hidden group',
    role: ''
  },
  {
    email: 'Karen.Castillo@monash.edu',
    groupId: '66b7bf2b',
    groupName: 'hidden group',
    role: ''
  },
  {
    email: 'Karen.Castillo@monash.edu',
    groupId: 'b26bf76b',
    groupName: 'second hidden group',
    role: 'mod'
  },
  {
    email: 'Jamie.Franco@student.monash.edu',
    groupId: '66b7bf2b',
    groupName: 'hidden group',
    role: ''
  }
]
