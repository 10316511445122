import React from 'react'
import { firebind } from '../services/database'

const UserName = ({ name, firstName, lastName }) => {
  if (!name && !firstName && !lastName) return null;
  return (
    <span className="UserName">
      {name || [firstName, lastName].filter(x => x).join(" ")}
    </span>
  )
}

export default firebind(UserName, {
  name: ({userId}) => `users/${userId}/profile/name`,
  firstName: ({userId}) => `users/${userId}/profile/firstName`,
  lastName: ({userId}) => `users/${userId}/profile/lastName`
})