import React from "react";
import CONSTANTS from "./constants";

function youtubeLinkDiscovery(targetLink) {
  if (!targetLink) {
    return null;
  }
  let link = targetLink;
  if (!(targetLink.includes("https") || targetLink.includes("http"))) {
    link = "https://" + link;
  }
  const url = new URL(link);
  if (
    url.hostname === CONSTANTS.HOSTNAMES.SHORT ||
    url.hostname === CONSTANTS.HOSTNAMES.SHORT_Y2U
  ) {
    return {
      type: CONSTANTS.TYPES.VIDEO,
      youtubeId: url.pathname.replace("/", "")
    };
  } else {
    // video link is a playlist
    if (url.pathname === CONSTANTS.PATHNAMES.PLAYLIST) {
      return {
        type: CONSTANTS.TYPES.PLAYLIST,
        youtubeId: url.searchParams.get(CONSTANTS.SEARCHPARAMS.PLAYLIST)
      };
    }

    if (
      url.pathname === CONSTANTS.PATHNAMES.VIDEO &&
      url.searchParams.get(CONSTANTS.SEARCHPARAMS.VIDEO)
    ) {
      return {
        type: CONSTANTS.TYPES.VIDEO,
        youtubeId: url.searchParams.get(CONSTANTS.SEARCHPARAMS.VIDEO)
      };
    }
  }
}

function YoutubeEmbed({ postBody }) {
  if (!postBody) {
    return null;
  }
  const found = postBody.match(CONSTANTS.YOUTUBE_REGEX);

  const { PLAYLIST } = CONSTANTS.TYPES;

  let discovery = null;
  if (found) {
    discovery = youtubeLinkDiscovery(found[0]);
  }

  // deconstruct discovery to get the payload data

  return (
    <>
      {discovery && (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <iframe
            title="Embedded video"
            width="100%"
            height="300"
            src={`https://www.youtube.com/embed/${
              discovery.type === PLAYLIST
                ? `videoseries?list=${discovery.youtubeId}`
                : discovery.youtubeId
            }`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          />
        </div>
      )}
    </>
  );
}

export default YoutubeEmbed;
