// @flow

import React from 'react'
import { Field, Button, Input } from '@upgrowth/react-fulcrum'
import * as classnames from 'classnames'
import './ProfileBio.scss'
import { FaCheck, FaWindowClose } from 'react-icons/fa'
import { BIO_CHARACTER_LIMIT } from '../types/types'

export default class ProfileBio extends React.Component {

  constructor(props) {
    super(props)
    this.state = { editing: false, charsLeft: BIO_CHARACTER_LIMIT }
  }

  enterEditMode() {
    this.setState({ editing: true, value: this.props.value })
  }

  completeEdit() {
    this.props.onChange(this.state.value)
    this.cancelEdit()
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    value !== this.state.value && this.setState({ value })
  }

  cancelEdit() {
    this.setState({ value: false, editing: false })
  }

  render() {
    const { value, label } = this.props
    return (
      <Field name={label} label={label} className={classnames('ProfileBio', { editing: this.state.editing })}>
        {this.state.editing
          ? (
            <span className="profile-bio-editor">
              <Input
                type={this.props.type || 'textarea'}
                value={this.state.value}
                onChange={newValue => this.setState({ value: newValue, charsLeft: BIO_CHARACTER_LIMIT - newValue.length })}
              />
              <p>Characters Left: {this.state.charsLeft}</p>
              <div className='profile-bio-actions'>
                <Button className="editLink" onClick={() => this.cancelEdit()}><FaWindowClose /></Button>
                <Button className="editLink" onClick={() => this.completeEdit()} disabled={this.state.charsLeft < 0}><FaCheck /></Button>
              </div>
            </span>
          ) : (
            <span className="profile-bio-editor">
              <span className="profile-bio-textarea">{value}</span>
              <Button className="editLink" onClick={() => this.enterEditMode()}>Edit</Button>
            </span>
          )
        }
      </Field>
    )
  }
}