import * as React from 'react'

import { firebind, withUserId } from '../services/database'
import SidebarMenu from '../compositions/SidebarMenu'
import UserName from '../compositions/UserName'

import UnreadFriendMessageCount from './UnreadFriendMessageCount'

import './FriendMenu.scss'

class FriendsMenu extends React.Component {
  render() {
    return (
      <UnreadFriendMessageCount
        render={(totalUnreadCount, requestCount, unreadCounts) => {
          const friendLinks = Object.keys(unreadCounts).map(friendId => ({
            key: friendId,
            name: 'friend',
            badge: unreadCounts[friendId],
            friendId,
            label: <UserName userId={friendId} />
          }))

          return (
            <SidebarMenu
              className="FriendsMenu"
              links={{
                'New Friends': [
                  {
                    key: 'requests',
                    label: 'Requests',
                    name: 'friendRequests',
                    badge: requestCount
                  },
                  { key: 'add', label: 'Add by Email', name: 'addFriend' }
                ],
                Friends: [
                  {
                    key: 'unread',
                    label: 'Unread',
                    name: 'unreadFriends',
                    badge: totalUnreadCount
                  },
                  {
                    key: 'friends',
                    label: 'All Friends',
                    name: 'allFriends'
                  },
                  ...friendLinks
                ]
              }}
            />
          )
        }}
      />
    )
  }
}

export default withUserId(
  firebind(FriendsMenu, {
    friends: ({ userId }) => `users/${userId}/friends`,
    messages: ({ userId }) => `users/${userId}/messages`
  })
)
