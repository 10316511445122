import {logout} from '../services/auth'
import React from 'react'

export default class LogOut extends React.Component {
  async componentDidMount() {
    await logout()
  }

  render() {
    return null
  }
}