// @flow

import * as React from 'react'
import Section from "../compositions/Section";
import Screen from "../compositions/Screen";
import './Dashboard.scss'
import PromotedPosts from "./PromotedPosts";
import OfficialPosts from "./OfficialPosts";
import LatestActivity from "./LatestActivity";

export default () => {
  const latestActivity = <LatestActivity />

  return (
      <Section className="Dashboard" sidebar={latestActivity} primeRoute="groups">
        <Screen>
            <OfficialPosts />
            <div className='posts'>
              <PromotedPosts />
              {latestActivity}
            </div>
        </Screen>
      </Section>
  )
}