// @flow
import React from 'react'
import { Button } from '@upgrowth/react-fulcrum'
import * as classnames from 'classnames'
import './ModerationTools.scss'
import darkEllipsis from './dark-ellipsis.svg'
import lightEllipsis from './light-ellipsis.svg'
import { GroupRoles } from '../types/types'
import { updateRole } from '../services/groups'
import type { GroupId, GroupRole, MessageId, UserId } from '../types/types'
import { DeleteButton, ReportButton } from './ModerationButtons'
import { stopPropagation } from '../services/util'
import { firebind } from '../services/database'
import { promotePost, unpromotePost } from '../services/messaging'

type Props = {
  to?: UserId,
  userId: UserId,
  groupId?: GroupId,
  mod: boolean,
  admin: boolean,
  role: GroupRole,
  threadId?: string,
  messageId: MessageId,
  mine: boolean
}

class ModerationTools extends React.Component<Props, { open: boolean }> {
  state = { open: false }
  toggle = () => this.setState({ open: !this.state.open })

  ban = () =>
    updateRole(this.props.groupId, this.props.userId, GroupRoles.banned)
  mute = () =>
    updateRole(this.props.groupId, this.props.userId, GroupRoles.muted)
  promoteToMod = () =>
    updateRole(this.props.groupId, this.props.userId, GroupRoles.mod)
  forgive = () =>
    updateRole(this.props.groupId, this.props.userId, GroupRoles.user)

  promoteThisPost = () => promotePost(this.props.groupId, this.props.messageId)
  unpromoteThisPost = () =>
    unpromotePost(this.props.groupId, this.props.messageId)

  getSourceType = () => {
    const { threadId, groupId } = this.props
    return threadId ? 'thread' : groupId ? 'group' : 'user'
  }

  /**
   * Return tools available to a mod for a message based on the author's role.
   */
  getModTools() {
    const { role, mod, mine } = this.props
    if (mod !== true) return null

    // Collect tools based on role
    let tools = []

    // Add tools if the post does not belong to the user
    if (!mine) {
      // If the role is not a string, it means that the user is no longer in the group.
      // (Firebase will return an empty object {} if the role property is not found)
      if (typeof role === 'string') {
        switch (role) {
          case GroupRoles.muted:
            tools = tools.concat([
              <Button
                key="unmute"
                onClick={this.forgive}
                className="shape-pill mute active"
              >
                Unmute User
              </Button>
            ])
            break
          case GroupRoles.user:
            tools = tools.concat([
              <Button
                key="mute"
                onClick={this.mute}
                className="shape-pill mute"
              >
                Mute User
              </Button>
            ])
            break
          // Mods can't take actions on other mods
          case GroupRoles.mod:
            break
          default:
            break
        }
      }
    }

    return tools
  }

  /**
   * Return tools available to an admin for a message based on the author's role.
   */
  getAdminTools() {
    const { role, admin, isPinned } = this.props
    if (admin !== true) return null
    // Collect tools based on role
    let tools = []

    // Add buttons to promote or demote posts if currently in a group view
    if (this.getSourceType() === 'group') {
      tools.push(
        isPinned ? (
          <Button
            key="promotePost"
            onClick={this.unpromoteThisPost}
            className="shape-pill promote unpromote"
          >
            Unpin
          </Button>
        ) : (
          <Button
            key="promotePost"
            onClick={this.promoteThisPost}
            className="shape-pill promote"
          >
            Pin to Dashboard
          </Button>
        )
      )
    }

    switch (role) {
      case GroupRoles.user:
        tools = tools.concat([
          <Button
            key="promote"
            onClick={this.promoteToMod}
            className="shape-pill promote"
          >
            Make User Mod
          </Button>,
          <Button key="ban" onClick={this.ban} className="shape-pill ban">
            Ban User
          </Button>
        ])
        break
      case GroupRoles.mod:
        tools = tools.concat([
          <Button
            key="mod"
            onClick={this.forgive}
            className="shape-pill demote"
          >
            Demote Mod
          </Button>
        ])
        break
      case GroupRoles.banned:
        tools = tools.concat([
          <Button
            key="unban"
            onClick={this.forgive}
            className="shape-pill ban active"
          >
            Unban User
          </Button>
        ])
        break
      default:
        break
    }

    return tools
  }

  /**
   * Return tools available to all users
   */
  getUserTools() {
    const { mine, threadId, messageId, to, groupId, userId, admin } = this.props
    if (mine || admin) {
      // If the post belongs to the user, or if the user is a mod, return a delete button
      return (
        <DeleteButton
          {...{
            sourceType: this.getSourceType(),
            sourceId: groupId || userId,
            threadId,
            messageId,
            to
          }}
        />
      )
    }
    // Otherwise return a report button
    return (
      <ReportButton
        {...{
          sourceType: this.getSourceType(),
          sourceId: groupId || userId,
          threadId,
          messageId
        }}
      />
    )
  }

  render() {
    const { open } = this.state
    return (
      <div
        className={classnames('ModerationTools', { open })}
        onClick={stopPropagation}
      >
        <div className="open-tools">
          <div className="tools">
            {this.getAdminTools()}
            {this.getModTools()}
            {this.getUserTools()}
          </div>
        </div>
        <div className="toggle" onClick={this.toggle}>
          <img src={open ? lightEllipsis : darkEllipsis} alt="Toggle" />
        </div>
      </div>
    )
  }
}

export default firebind(ModerationTools, {
  role: ({ userId, groupId }) =>
    groupId && `groups/members/${groupId}/${userId}`
})
