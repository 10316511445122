// @flow

import * as React from 'react'
import { firebind } from '../services/database'
import { values, isEmpty, maxBy } from 'lodash'
import Message from '../messages/Message'

/*
 * Since Firebase does not support composite indexing, we need to fetch several replies with the highest
 * likes, then tie-break the replies based on latest activity (i.e. when it received a like/unlike change).
 *
 * Solution is based on "filter most on the server, do the rest on the client"
 * https://stackoverflow.com/a/26701282
 */
const BestReply = ({ groupId, threadId, mostLikedReplies }) => {
  if (!mostLikedReplies) {
    return null
  }

  if (isEmpty(mostLikedReplies)) {
    return <div>No reply exists for this thread.</div>
  }

  // Handle tie-breakers, by finding the reply that has the latest activity
  const repliesList = values(mostLikedReplies)
  const highestLikedReply = maxBy(repliesList, 'likeCount')
  const highestLikeCount =
    typeof highestLikedReply !== 'undefined' ? highestLikedReply.likeCount : 0
  const filteredRepliesList = repliesList.filter(
    reply => reply.likeCount === highestLikeCount
  )
  const bestReply = filteredRepliesList.sort((replyA, replyB) => {
    const atA = replyA.updatedAt || replyA.at
    const atB = replyB.updatedAt || replyB.at

    return atB - atA
  })[0]

  if (bestReply.likeCount === 0) {
    return (
      <div>
        There are replies to this post, but none of them have any likes.
      </div>
    )
  }

  return <Message simple bestPost {...bestReply} />
}

export default firebind(BestReply, {
  mostLikedReplies: ({ groupId, threadId }) => ({
    ref: `groups/replies/${groupId}/${threadId}`,
    queries: {
      orderByChild: 'likeCount',
      limitToLast: 100
    }
  })
})
