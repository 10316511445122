// @flow

import { firebind, withUserId } from './database'

export default withUserId(
  firebind(
    ({ isAdmin, isGlobalModerator, children }) =>
      ((isAdmin === true || isGlobalModerator === true) && children) || null,
    {
      isAdmin: ({ userId }) => `users/${userId}/standing/admin`,
      isGlobalModerator: ({ userId }) =>
        `users/${userId}/standing/globalModerator`
    }
  )
)
