// @flow

import ImageCompressor from 'image-compressor.js/dist/image-compressor.esm.js'
import roundPrecision from 'round-precision'
import uuidv4 from 'uuid/v4'

import track from './track'
import {
  removeDB,
  setDB,
  timestampNow,
  updateDB,
  uploadFile,
  userId as getUserId,
  getDB
} from './database'

/* USEFUL GPS PRECISION FACTS:
(https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude/8674#8674)
The first decimal place is worth up to 11.1 km: it can distinguish the position of one large city from a neighboring large city.
The second decimal place is worth up to 1.1 km: it can separate one village from the next.
The third decimal place is worth up to 110 m: it can identify a large agricultural field or institutional campus.
The fourth decimal place is worth up to 11 m: it can identify a parcel of land. It is comparable to the typical accuracy of an uncorrected GPS unit with no interference.
The fifth decimal place is worth up to 1.1 m: it distinguish trees from each other. Accuracy to this level with commercial GPS units can only be achieved with differential correction.
The sixth decimal place is worth up to 0.11 m: you can use this for laying out structures in detail, for designing landscapes, building roads. It should be more than good enough for tracking movements of glaciers and rivers. This can be achieved by taking painstaking measures with GPS, such as differentially corrected GPS.
The seventh decimal place is worth up to 11 mm: this is good for much surveying and is near the limit of what GPS-based techniques can achieve.
 */

const GPS_PRECISION = 2

export function setOnboarded() {
  const onboardedPromise = setDB(
    `users/${getUserId()}/onboarded`,
    timestampNow()
  )
  track.event('onboarded', { category: 'profile' })
  return onboardedPromise
}

const imageCompressorOptions = {
  maxWidth: 1024,
  maxHeight: 1024
}

window.unboard = () => removeDB(`users/${getUserId()}/onboarded`)

const compressor = new ImageCompressor()

export const uploadProfilePic = async (file: File): Promise<string | void> => {
  try {
    console.log('Starting compression')
    const blob = await compressor.compress(file, imageCompressorOptions)
    console.log('Compression complete')
    return await uploadFile(
      blob,
      `public/${uuidv4()}/${getUserId()}/${file.name}`
    )
  } catch (err) {
    window.alert(`Could not upload image\n${err}`)
    console.error('Upload Error', err)
  }
}

const updateUser = (path, value, userToUpdate = getUserId()) => {
  setDB(`users/${userToUpdate}/${path}`, value)
}

const updateBio = (path, value, userToUpdate = getUserId()) => {
  setDB(`users/${userToUpdate}/${path}`, value)
}

export const setAvatar = (avatar: string | null) => {
  updateUser('profile/avatar', avatar)
}

export const setName = (name: string | null, mode?: 'system' | 'custom') => {
  if (typeof name !== 'string' || !name.trim()) return
  updateUser('profile/name', name.trim())
  if (mode) setNameMode(mode)
}

export const setNameMode = (mode: 'system' | 'custom') => {
  updateUser('profile/preferredNameMode', mode)
}

export const setBio = (bio: string | null) => {
  if (typeof bio !== 'string') return
  updateBio('profile/bio', bio)
}

export const setUnsubscribed = (unsubscribed: boolean) => {
  return track.after(
    updateUser('secrets/unsubscribed', !!unsubscribed),
    !!unsubscribed ? 'subscribe' : 'unsubscribe',
    { category: 'notifications' }
  )
}

// Set the global moderator status of the specified user
export const setGlobalModerator = (userId: string, isModerator: boolean) =>
  updateUser('standing/globalModerator', isModerator, userId)

// Get the user's global roles
export const getUserRole = (userId: string) => getDB(`users/${userId}/standing`)

export const setLocationSharing = (shared: boolean) => {
  if (shared) {
    return track.after(updateUser('secrets/locationSharing', shared), 'share', {
      category: 'location'
    })
  } else {
    return unshareLocation()
  }
}

export const confirmTermsAccepted = event => {
  const elem = document.getElementById('termsConditions')
  if (!elem.checked) {
    alert('You must accept the Terms & Conditions.')
    event.preventDefault()
  } else {
    return true
  }
}

export const confirmUpdatedTermsAccepted = event => {
  if (confirmTermsAccepted(event)) {
    return setOnboarded()
  }
}

export function shareLocation(lat: number, lng: number, name: string) {
  lat = roundPrecision(lat, GPS_PRECISION)
  lng = roundPrecision(lng, GPS_PRECISION)

  name = name.replace(/^A City, /, '')
  name = name.replace(/A Country$/, '')

  return track.after(
    updateDB(`users/${getUserId()}`, {
      'profile/coordinates': { position: [lat, lng] },
      'profile/location': name
    }),
    'update',
    { category: 'location' }
  )
}

export async function unshareLocation() {
  return track.after(
    updateDB(`users/${getUserId()}`, {
      'secrets/locationSharing': false,
      'profile/coordinates': null,
      'profile/location': null
    }),
    'unshare',
    { category: 'location' }
  )
}
