// @flow

import React from "react";
import {OrderedMap, Map, fromJS} from "immutable";
import Waterbase from './Waterbase'
import { pick, forEach } from 'lodash'


type PropMap = any;

const waterbind = (BoundComponent: any, propMap: PropMap) => {
  return class Waterbind extends React.Component {
    subs: Array<any>;
    result: Map<string, any>;
    mounted: boolean;
    state = {}
    id = Math.floor(Math.random() * 100)
    subs = {}
    mounted = false
    result = OrderedMap()

    componentDidMount() {
      this.mounted = true;
      this.updateSubscriptions(true)
    }

    componentDidUpdate() {
      this.updateSubscriptions(false)
    }

    componentWillUnmount() {
      this.mounted = false;
      forEach(this.subs, (_, subName) => this.unsubscribeOne(subName))
      OrderedMap(this.subs)
        .forEach((sub) => this.unsubscribeOne(sub))
    }

    render() {
      const anyUnloadedData = this.result.keySeq().some(k => this.state[k] === undefined)
      const loading = this.result.count() === 0 || anyUnloadedData
      // if (loading) { return <b>...</b>}
      return <BoundComponent {...this.props} {...this.state} loading={loading}/>
    }

    updateSubscriptions(initialSubscription: any) {
      const data = {...this.state, ...this.props};

      const newResult = OrderedMap(propMap).map((configurator, name) => {
        let config = typeof configurator === "function" ? configurator(data) : configurator;
        // Single string values are interpreted to be the ref
        config = typeof config === "object" ? config : {ref: config};
        return fromJS({name, value: config});
      });

      const unsubscribe = this.result.toOrderedSet().subtract(newResult.toOrderedSet());
      const subscribe = newResult.toOrderedSet().subtract(this.result.toOrderedSet());

      unsubscribe.forEach((map) => {
        const name = map.get("name")
        this.unsubscribeOne(name, initialSubscription);
      });
      subscribe.forEach((map) => {
        this.subscribeOne(map);
      });

      this.result = newResult;
    }


    unsubscribeOne(name: string, removeState?: boolean) {
      const sub = this.subs[name];
      if (sub) {
        name === 'threads' && console.log(`bind ${this.id} - Unsubscribing from `, name)
        sub && sub()
      } else {
        name === 'threads' && console.log(`bind ${this.id} - No subscription found for `, name)
      }
      delete this.subs[name];
      if (removeState) {
        this.set(name, null)
      }
    }

    setter = (key) => {
      const f = (value) => this.set(key, value)
      f.key = key
      return f
    }

    set = (key: string, value: any) => {
      if (this.mounted) {
        key === 'latestThreads' && console.log(`bind ${this.id} - Setting ${key}`, value)
        this.setState({[key]: value})
      } else {
        key === 'latestThreads' && console.log(`bind ${this.id} - Not setting ${key} because not mounted`, value)
      }
    }

    subscribeOne(nameValueMap: Map<string, *>) {
      const name = nameValueMap.get("name");
      const value = nameValueMap.get("value").toJS();
      name === 'threads' && console.log(`bind ${this.id} - Subscribing`, nameValueMap.toJS())
      if (this.props[name]) {
        this.set(name, this.props[name])
      }

      if (value.ref !== undefined) {
        if (this.subs[name]) {
          this.unsubscribeOne(name)
        }
        if (typeof value.ref !== "string") {
          this.set(name, value.ref)
        } else {
          const query = pick(value.queries || {}, ['startAt', 'endAt', 'limitToFirst', 'limitToLast', 'orderByChild', 'orderByKey', 'orderByValue'])
          this.subs[name] = Waterbase.instance.subscribe(value.ref, this.setter(name), query)
        }
      }
    }
  }
}
export default waterbind
