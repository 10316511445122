// @flow

import firebase from 'firebase/app'
import 'firebase/functions'

/**
 * Create a reference to a callable function.
 */
export const getFunction = (name: string) =>
  firebase.functions().httpsCallable(name)
