import * as React from 'react'
import Screen from '../compositions/Screen'
import SectionNav from '../compositions/SectionNav'
import './UsersMagazine.scss'
import AddFriendButton from '../compositions/AddFriendButton'
import { FriendRoles } from '../types/types'
import UsersMagazine from './UsersMagazine'
import { firebind, withUserId } from '../services/database'

const FriendRequests = ({userId, requests}) => {
  const actions = (friendId) => {
    return (friendId === userId) ? null : <AddFriendButton friendId={friendId}/>
  }

  return (
    <Screen className="FriendRequests" header={<SectionNav header="Friend Requests" backRoute="friends"/>}>
      <UsersMagazine users={requests} actionProvider={friendId => actions(friendId)}/>
    </Screen>
  )
}

export default withUserId(firebind(FriendRequests, {
  requests: ({userId}) => ({
    ref: `users/${userId}/friends`,
    queries: {
      orderByValue: true,
      equalTo: FriendRoles.requested
    }
  }),
}))
