// @flow

import React from 'react'
import Tab from '../compositions/Tab'
import Tabs from '../compositions/Tabs'
import type { UserProfile } from '../types/types'
import withProfile from '../services/withProfile'
import GroupPreview from '../compositions/GroupPreview'
import { Button, FormRow } from '@upgrowth/react-fulcrum'
import { FaAngleDoubleRight } from 'react-icons/fa'
import { Link } from '@upgrowth/reactkit'
import { idify } from '../services/util'
import { isEmpty } from 'lodash'

export const MONASH_NEW_STARTERS = 'monash-new-starters'

export default withProfile(({ profile: { location, course, faculty, facultyId, campus, campusId } = {}, loading }: { profile: UserProfile, loading: boolean }) => {


  if (loading) {
    return <div>Loading...</div>
  }

  // let country = ''

  // if (location) {
  //   const match = location.match(/.+, (.*)$/)
  //   if (match) {
  //     country = match[1]
  //   }
  // }

  return (
    <div className="OnboardingGroupsLocationMonash">
      <Tabs>
        <Tab active>1</Tab>
        <Tab>2</Tab>
      </Tabs>

      <h2>Join chat groups to meet other students and people like you.</h2>
      <h5>We've added you to these official Monash groups.</h5>

      {/*{location &&*/}
      {/*<div className="location-groups">*/}
      {/*<h3>Location</h3>*/}
      {/*{!location.match(/^A City/) &&*/}
      {/*<GroupPreview groupId={idify(location)}*/}
      {/*create*/}
      {/*defaults={{name: location, type: 'location'}} />*/}
      {/*}*/}
      {/*{!country.match(/^A Country/) &&*/}
      {/*<GroupPreview groupId={idify(country)}*/}
      {/*create*/}
      {/*defaults={{name: country, type: 'location'}} />*/}
      {/*}*/}
      {/*</div>*/}
      {/*}*/}
      <GroupPreview groupId={MONASH_NEW_STARTERS}
        create
        defaults={{ name: 'Monash New Starters', type: 'monash' }} />
      {!isEmpty(faculty) &&
        <GroupPreview groupId={facultyId || idify(faculty)}
          defaults={{ name: faculty, type: 'monash' }} />
      }
      {!isEmpty(campus) &&
        <GroupPreview groupId={campusId || idify(campus)}
          defaults={{ name: campus, type: 'monash' }} />
      }
      <FormRow className="nextBtn">
        <Button variant="secondary" as={Link} name="onboardGroupsInterest">Confirm details <FaAngleDoubleRight className="nextBtnIcon" /></Button>
      </FormRow>

    </div>
  )
})