import React, { useState, useEffect } from 'react'
import { NavItem } from '@upgrowth/react-fulcrum'
import { NavLink, withParams } from '@upgrowth/reactkit'

import UnreadBadge from '../messages/UnreadBadge'
import SidebarMenu from '../compositions/SidebarMenu'
import { firebind, withUserId } from '../services/database'
import sortGroups from './sortGroups'

import { GroupTypes } from '../types/types'

const GroupsMenu = ({ groups, sortOrder, loading }) => {
  const [links, setLinks] = useState({})

  // Categories and sort groups when they are updated
  useEffect(() => {
    if (!loading) {
      const groupsByType = {}

      // Categorise groups by the interest type
      Object.keys(groups).forEach(groupId => {
        const group = groups[groupId]

        // Get the group name's mapping if it exists
        const type = GroupTypes[group.type] || group.type

        // If the group doens't have a list yet, create one
        if (!groupsByType[type]) groupsByType[type] = []

        // Push the group into the list
        groupsByType[type].push({
          ...group,
          key: groupId,
          label: group.name,
          name: 'group',
          groupId,
          badge: <UnreadBadge sourceId={groupId} sourceType="group" />
        })
      })

      // Sort groups within each category
      Object.keys(groupsByType).forEach(type => {
        const sortedGroups = sortGroups(groupsByType[type], {
          sortOrder,
          additionalFields: ['label']
        })
        groupsByType[type] = sortedGroups
      })

      // Save the groups to state
      setLinks(groupsByType)
    }
  }, [groups, sortOrder, loading, setLinks])

  return (
    <SidebarMenu className="GroupsMenu" links={links}>
      <NavItem className="allGroups" as={NavLink} name="allGroups">
        View All Groups
      </NavItem>
    </SidebarMenu>
  )
}

export default withParams(
  withUserId(
    firebind(GroupsMenu, {
      groups: ({ userId }) => userId && `users/${userId}/groups`,
      sortOrder: `groups/sortOrder`
    })
  )
)
