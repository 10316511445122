// @flow

import React from 'react'
import type {Element, ChildrenArray} from 'react'
import type {Location} from 'react-router-dom'

import * as classnames from 'classnames'
import MainNav from './MainNav'
import './Section.scss'
import {withRouter} from 'react-router-dom'
import routes from '../routes'

type Props = {sidebar: Element<any>, className: string, children: ChildrenArray<any>, primeRoute: string, location: Location}
const Section =({sidebar, className, children, primeRoute, location: {pathname}}: Props) => (
  <div className={classnames("Section", className, {prime: routes[primeRoute] === pathname})}>
    <MainNav/>
    <div className="section-sidebar">{sidebar}</div>
    <div className="section-content">{children}</div>
  </div>
)

export default withRouter(Section)