// @flow

import * as React from "react";
import { map, forEach, isEmpty, remove, pull } from "lodash";
import { getDB } from "../services/database";
import { Column, Row, Button } from "@upgrowth/react-fulcrum";
import { firebind, withUserId } from "@upgrowth/firekit";
import SmallCard from "../compositions/SmallCard";
import { FaCheck } from "react-icons/fa";
import Avatar from "../compositions/Avatar";

class MentionsEditor extends React.Component {
  state = {
    profiles: {}
  }

  props: {
    value: ?string[],
    onChange: (string[]) => void,
    friends: {}
  }

  componentDidUpdate() {
    const { friends } = this.props
    const { profiles } = this.state

    if (!isEmpty(friends) && isEmpty(profiles)) {
      forEach(friends, async (status, userId) => {
        const profile = await getDB(`users/${userId}/profile`)
        const { profiles } = this.state
        profiles[userId] = profile
        this.setState({ profiles })
      })
    }
  }

  tagFriend = (userId) => {
    const mentions = this.props.value || []
    mentions.push(userId)
    this.props.onChange(mentions)
  }

  untag = (userId) => {
    const mentions = this.props.value || []
    remove(mentions, userId)
    this.props.onChange(pull(mentions, userId))
  }

  render() {
    const { value = [], friends } = this.props

    return (
      <Column className='MentionsEditor'>
        <Row className="tagged">
          {
            map(friends, (status, userId) => {
              const isTagged = value.includes(userId)
              return isTagged ? <TaggedFriend key={userId} userId={userId} onUntag={() => this.untag(userId)} /> : <UntaggedFriend key={userId} userId={userId} onTagFriend={() => this.tagFriend(userId)} />
            })
          }
        </Row>
      </Column>
    )
  }
}

const FriendCard = firebind(({ children, userId, profile }) => {
  const { name = '...', location = 'Unknown' } = profile || {}
  const pic = <Avatar {...profile} />
  return <SmallCard picture={pic} key={userId} title={name} subtitle={location} action={children} />
}, {
  profile: ({ userId }) => `users/${userId}/profile`
})

const TaggedFriend = ({ userId, onUntag }) => {
  return <FriendCard userId={userId}>
    <Button className="shape-round tagged-indicator" onClick={onUntag}><FaCheck /></Button>
  </FriendCard>
}

const UntaggedFriend = ({ userId, onTagFriend }) => {
  return <FriendCard userId={userId}>
    <Button variant='neutral' className="shape-round outline tag-button" onClick={onTagFriend}>+</Button>
  </FriendCard>
}

export default withUserId(firebind(MentionsEditor, {
  friends: ({ userId }) => `users/${userId}/friends`
}))
