// @flow

import React from 'react'
import { firebind } from '../services/database'

type Version = { version?: string }

class ForceReload extends React.Component<Version, Version> {
  props: {
    version?: string
  }
  state: {
    version?: string
  } = {
    version: undefined
  }

  static getDerivedStateFromProps (props, state) {
    const {version} = props
    if (!state.version) {
      return {version}
    }
  }

  forceReload () {
    const {location} = window
    location.reload(true)
  }

  render () {
    const {version, children} = this.props
    if (version && this.state.version && version !== this.state.version) {
      this.forceReload()
    }

    return <React.Fragment children={children} />
  }
}

export default firebind(ForceReload, {
  version: 'public/version'
})