// @flow

import React from 'react'
import Screen from '../compositions/Screen'
import { Map, Marker, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import Leaflet from 'leaflet'
import markerImg from './marker.svg'
import './MapScreen.scss'
import type { Viewport } from 'react-leaflet/es/types'
import MapHeader from './MapHeader'
import qs from 'qs'
import { withParams } from '@upgrowth/reactkit/lib/router/index'
import { map } from 'lodash'
import type { UserId } from '../types/types'
import { firebind, withUserId } from '../services/database'

const markerIcon = Leaflet.icon({
  iconUrl: markerImg,
  iconSize: [23, 30],
  iconAnchor: [12, 30]
})

const options = {
  showCoverageOnHover: false,
  zoomToBoundsOnClick: false,
  spiderfyOnMaxZoom: false
}

type Cluster = {
  getAllChildMarkers(): { options: { userId: string } }[],
  getChildCount(): number,
  zoomToBounds(): void,
  getLatLng(): { lng: number, lat: number }
}

const maxZoom = 10
const maxCluster = 100


class MapScreen extends React.Component<*, *> {
  constructor(props: *) {
    super(props)
    if (!this.props.location.state || !this.props.location.state.viewport) {
      this.handleViewportChanged(MapScreen.defaultViewport)
    }
  }

  static defaultViewport = { center: [-37.0, 145.0], zoom: 6 }

  handleClusterClick = (cluster: Cluster) => {
    const userCount = cluster.getChildCount()

    const { viewport } = this.props.location.state
    const { groupId } = this.props
    const zoom = viewport.zoom

    if (userCount < maxCluster || zoom >= maxZoom) {
      const userIds = cluster.getAllChildMarkers().map(({ options: { userId } }) => userId)
      const { lng, lat } = cluster.getLatLng()

      this.props.history.push(`/map/${groupId}/cluster?${qs.stringify({ lat, lng, zoom })}`, { userIds })
    } else {
      cluster.zoomToBounds()
    }
  }

  handleMarkerClick = (userId: UserId) => {
    this.props.history.push(`/friends/${userId}`)
  }

  handleViewportChanged = (viewport: Viewport) => {
    const { location } = this.props
    this.props.history.replace({ ...location, state: { ...location.state, viewport } })
  }

  render() {
    const { location, history, groupId, loading, myCoordinates, markers = {} } = this.props
    const viewport: Viewport = (location.state && location.state.viewport) ||
      ((myCoordinates && myCoordinates.position) ? { center: myCoordinates.position, zoom: 8 } : MapScreen.defaultViewport)

    // console.log("Viewport is ", viewport, myCoordinates, MapScreen.defaultViewport)
    // console.log("Viewport is now", viewport)
    if (loading) { return null }
    return (
      <Screen className="MapScreen" header={
        <MapHeader selectedGroupId={groupId}
          onChange={(groupId) => history.push(groupId)} />
      }>
        <Map className="markercluster-map"
          viewport={viewport}
          style={{ height: '100%' }}
          onViewportChanged={this.handleViewportChanged}
          maxZoom={maxZoom}>
          <TileLayer
            url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
            attribution='Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL.'
          />

          <MarkerClusterGroup options={options} onClusterClick={this.handleClusterClick}>
            {map(markers, (marker, id) => <Marker {...marker}
              userId={id}
              id={id}
              icon={markerIcon}
              key={id}
              onClick={() => this.handleMarkerClick(id)} />)}
          </MarkerClusterGroup>
        </Map>
      </Screen>

    )
  }
}

export default withParams(withUserId(firebind(MapScreen, {
  markers: ({ groupId }) => `groups/maps/${groupId}`,
  myCoordinates: ({ userId }) => `users/${userId}/profile/coordinates`
})))