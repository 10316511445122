import React from 'react'
import {MONASH_NEW_STARTERS} from '../onboarding/OnboardGroupsLocationMonash'
import {Redirect} from '@upgrowth/reactkit/lib/router/index'
import {first, keys, indexOf} from "lodash"
import { firebind, withUserId } from '../services/database'

export default withUserId(firebind(({groups, loading}) => {
    if (loading) {
        return null
    }
    const groupIds = keys(groups)
    const bestGroup = indexOf(groupIds, MONASH_NEW_STARTERS) >= 0 ? MONASH_NEW_STARTERS : first(groupIds)
    return bestGroup ? <Redirect name="groupMap" groupId={bestGroup}/> : null
}, {
    groups: ({userId}) => `users/${userId}/groups`,
}))
