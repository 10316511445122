// @flow
import React from 'react'
import { firebind } from '@upgrowth/firekit'
import { withUserId } from '@upgrowth/firekit'

import EventsList from './EventsList'

function AllEvents({ events, userId }) {
  return (
    <EventsList
      title="All events"
      emptyMsg="No events available"
      loading={!Boolean(events)}
      userId={userId}
      events={events && Object.keys(events).map(id => events[id])}
    />
  )
}

export default withUserId(
  firebind(AllEvents, {
    events: 'events'
  })
)
