// @flow

import React from 'react'
import * as classnames from 'classnames'
import './SkipToNowButton.scss'
import { Button } from '@upgrowth/react-fulcrum'
import { FaAngleDoubleUp, FaAngleDoubleDown } from 'react-icons/fa';

type Props = { atBottom: boolean, onGoToTop: () => any, onSkipToNow: () => any }

const SkipToNowButton = ({ atBottom, onGoToTop, onSkipToNow }: Props) => {
  return (
    <Button variant="light" className={classnames("SkipToNowButton", "shape-round", { atBottom })}
      onClick={() => atBottom ? onGoToTop() : onSkipToNow()}>
      {atBottom ? <FaAngleDoubleUp className="skip-down-indicator" /> : <FaAngleDoubleDown className="skip-down-indicator" />}
    </Button>
  )
}

export default SkipToNowButton