//@flow

import * as React from 'react'
import {Card} from '@upgrowth/react-fulcrum'
import {map} from 'lodash'
import {Link} from '@upgrowth/reactkit'
import ProfilePreview from "../compositions/ProfilePreview";
import './UsersMagazine.scss'
import Magazine from '../compositions/Magazine'
import type {UserId} from '../types/types'
import {UserMagazineCard} from './UserMagazineCard'

export type ActionProvider = (userId: UserId) => React.Node


export const UserPhoneCard = ({userId, actionProvider, noFriendActions, asAdmin}: {userId: UserId, actionProvider: ActionProvider, asAdmin?: boolean}) =>
  <Card as={Link}
        name="friend"
        className="user-card"
        friendId={userId}>
    <ProfilePreview userId={userId}
                    asAdmin={asAdmin}
                    noFriendActions={noFriendActions}
                    actions={actionProvider && actionProvider(userId)}/>
  </Card>


type RenderFunction = ({userId: UserId, actionProvider?: ActionProvider}) => React.Node
type Props = {
  users: { [string]: any },
  actionProvider: ActionProvider,
  phoneRender?: RenderFunction,
  className?: any,
  desktopRender?: RenderFunction
}

const UsersMagazine = ({users, actionProvider, phoneRender = UserPhoneCard, desktopRender = UserMagazineCard, className}: Props) => {
  const Phone = phoneRender
  const Desktop = desktopRender
  
  return (

    <Magazine className={["UsersMagazine", className]}
              phoneView={
                map(users, (_, userId) => (<Phone {...{userId, actionProvider}} key={userId}/>))
              }>
      {map(users, (_, userId) => (
        <Desktop userId={userId} actionProvider={actionProvider} key={userId}/>
      ))}

    </Magazine>
  )
}

export default UsersMagazine