// @flow

import { firekit, firebind as firekitFirebind, withUserId as firekitWithUserId } from '@upgrowth/firekit/lib/index'
import * as React from 'react'
import waterbind from './waterbase/waterbind'
import Waterbase from './waterbase/Waterbase'
import firebase from 'firebase/app'
import 'firebase/storage';
import { authedClient } from './auth'
import { forEach } from 'lodash'
import * as firebaseKey from 'firebase-key'

// Ethan named this [L]

export function googleJunction<Args, Return>(yesGoogle: (Args) => Return, noGoogle: (Args) => Return): (Args) => Return {
  return (...args: Args) => {
    if (window.isGoogleAvailable) {
      return (window.isGoogleAvailable === 'yes') ? yesGoogle(...args) : noGoogle(...args)
    } else {
      throw new Error("Google service attempted before environment initialisation")
    }
  }
}

function googleJunctionHoc<HocArgs, PropType>(
  yesGoogleHoc: (...HocArgs) => React.ComponentType<PropType>,
  noGoogleHoc: (...HocArgs) => React.ComponentType<PropType>): React.ComponentType<PropType> {
  return (...hocArgs: HocArgs) => {
    const Yes = yesGoogleHoc(...hocArgs)
    const No = noGoogleHoc(...hocArgs)

    return (props) => {
      if (window.isGoogleAvailable) {
        const As = window.isGoogleAvailable === 'yes' ? Yes : No
        // console.log("Rendering component", As, props)
        return <As {...props} />

      } else {
        console.error("Google Junction HOC prematurely rendering", props)
        return null
      }
    }
  }
}

const ref = (path: string) => firekit().database.ref(path)

export const setDB = googleJunction(
  (path: string, data: any) => ref(path).set(data),
  (path: string, data: any) => Waterbase.instance.set(path, data)
)

export const getDB = googleJunction(
  (path: string) => ref(path).once('value').then(ss => ss.val()),
  (path: string) => Waterbase.instance.get(path)
)

export const pushDB = googleJunction(
  (path: string, data: any) => ref(path).push(data),
  (path: string, data: any) => Waterbase.instance.push(path, data)
)

export const updateDB = googleJunction(
  (path: string, updates: {}) => ref(path).update(updates),
  (path: string, updates: {}) => Waterbase.instance.update(path, updates)
)

export const removeDB = googleJunction(
  (path: string) => ref(path).remove(),
  (path: string) => Waterbase.instance.remove(path)
)

export const newKey = googleJunction(
  () => ref('/').push().key,
  () => firebaseKey.key()
)

export const currentUser = googleJunction(
  () => firekit().auth.currentUser,
  () => window.amigoCurrentUser
)

export const reload = googleJunction(
  async () => { },
  (path: string) => Waterbase.instance.refresh(path)
)


const userObservers = {}

export const onAuthStateChanged = googleJunction(
  (callback) => firekit().auth.onAuthStateChanged(callback),
  (callback) => {
    const key = newKey()
    userObservers[key] = callback
    callback(currentUser())
    return () => userObservers[key] && delete userObservers[key]
  }
)

export const updateAuthData = (authData) => {
  console.log("Handling auth Data", authData)
  window.amigoCurrentUser = authData
  forEach(userObservers, f => f(authData))
}


export const handleAuthData = googleJunction(
  async (authData) =>
    firekit().auth.signInWithCustomToken(authData.token)
  , updateAuthData
)

export const userId = () => currentUser().uid

export const timestampNow = () => firebase.database.ServerValue.TIMESTAMP

export const idToken = googleJunction(
  () => firekit().auth.currentUser.getIdToken(),
  async () => window.amigoCurrentUser.token
)

export const firebind = googleJunctionHoc(firekitFirebind, waterbind)

export const signOut = googleJunction(
  () => firekit().auth.signOut(),
  () => updateAuthData(null)
)

export const uploadFile = googleJunction(
  async (file: Blob, path: string) => {
    const storage = firebase.storage(firekit().app)
    const imgRef = storage.ref().child(path)
    console.log("Uploading to", imgRef.fullPath)
    await imgRef.put(file)
    return imgRef.getDownloadURL()
  },
  async (file: Blob, path: string) => {
    console.log("Uploading to services")
    const formData = new FormData()
    formData.append('file', file)
    const res = await (await authedClient()).post(`client/storage/${path}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return res.data.url
  }
)

export const withUserId = googleJunctionHoc(firekitWithUserId, (Component) => {
  return class WithUserId extends React.Component {
    state = { userId: null }
    unsub: ?() => void

    componentDidMount() {
      this.unsub = onAuthStateChanged(this.updateUser)
    }

    updateUser = (user) => {
      this.setState({ userId: user && user.uid })
    }

    componentWillUnmount() {
      this.unsub && this.unsub()
      delete this.unsub
    }

    render() {
      const { userId } = this.state
      return userId
        ? (<Component {...this.props} userId={this.state.userId} />)
        : null
    }
  }
})