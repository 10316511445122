import React from 'react'
import Screen from '../compositions/Screen'
import LocationView from '../compositions/LocationView'
import LocationUpdater from '../compositions/LocationUpdater'
import { firebind, withUserId } from '../services/database'
import {Button} from '@upgrowth/react-fulcrum'
import {setLocationSharing} from "../services/users";

const SetLocationToUse = ({profile, userId, loading, locationSharing = false}) => {
  if (loading) { return null }
  return (
      <Screen className="SetLocationToUse page-content">
        <h3>You must share your location to use the map.</h3>
        {locationSharing
            ?
            <LocationUpdater
                renderError={(error, retry) => (
                    <div className="location-error">
                      <h3>Either your browser doesn't support location, or you've chosen not to share your location with us.</h3>
                      <Button onClick={retry}>Retry</Button>
                    </div>
                )}
                render={(locProps) => <LocationView {...locProps} {...{profile, userId}}/>}
            />

            : <Button onClick={() => setLocationSharing(true)}>Share my current location</Button>

        }

      </Screen>
  )
}

export default withUserId(firebind(SetLocationToUse, {
  profile: ({userId}) => `users/${userId}/profile`,
  locationSharing: ({userId}) => `users/${userId}/secrets/locationSharing`
}))