import * as React from 'react'
import {Avatar} from '@upgrowth/react-fulcrum'
import '../compositions/ProfilePicture.scss'

const ProfilePicture = ({photoURL, children, ...props}) => (
    <div className="ProfilePicture">
      <div className="frame-edge left">
        <div className="top"/>
        <div className="bottom"/>
      </div>
      <Avatar img={window.rewriteImageUrl(photoURL)} size="xlarge" />
      <div className="frame-edge right">
        <div className="top"/>
        <div className="bottom"/>
      </div>
      <div className="caption">{children}</div>
    </div>
)

export default ProfilePicture