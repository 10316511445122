//@flow
import * as React from 'react'
import { Button } from '@upgrowth/react-fulcrum'
import classnames from 'classnames'
import { toggleLikeMessage } from '../services/messaging'
import { firebind, withUserId } from '../services/database'

type Props = {
  groupId: string,
  threadId: string,
  replyId: string,
  userId: string,
  upvotedThread?: boolean | object,
  downvotedThread?: boolean | object,
  upvotedReply?: boolean | object,
  downvotedReply?: boolean | object
}

class LikeButton extends React.Component<Props> {
  toggleLike = e => {
    e.stopPropagation()

    const { groupId, threadId, replyId, userId } = this.props

    toggleLikeMessage(groupId, threadId, replyId, userId)
  }

  render() {
    const { replyId, likedThread, likedReply } = this.props

    const liked =
      (likedThread === true && replyId == null) ||
      (likedReply === true && replyId != null)

    return (
      <Button
        className={classnames('shape-pill', 'like', { active: liked })}
        name="like"
        onClick={this.toggleLike}
      >
        <span role="img" aria-label="Thumbs up">
          👍
        </span>{' '}
        {liked ? 'Liked' : 'Like'}
      </Button>
    )
  }
}

export default withUserId(
  firebind(LikeButton, {
    likedThread: ({ groupId, threadId, userId }) =>
      userId &&
      threadId != null &&
      `groups/likedThreads/${groupId}/${threadId}/${userId}`,
    likedReply: ({ groupId, threadId, replyId, userId }) =>
      userId &&
      threadId != null &&
      replyId != null &&
      `groups/likedReplies/${groupId}/${threadId}/${replyId}/${userId}`
  })
)
