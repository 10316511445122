//@flow
import React from 'react'
import { Route, Switch, withParams } from '@upgrowth/reactkit'

import { firebind, withUserId } from '../services/database'

import type { GroupDetails, GroupId, GroupRole, UserId } from '../types/types'
import { GroupRoles } from '../types/types'

import Threads from './Threads'
import Replies from './Replies'
import './Group.scss'

type GroupProps = {
  groupId: GroupId,
  userId: UserId,
  loading: boolean,
  details?: GroupDetails,
  role: GroupRole,
  isAdmin?: boolean,
  isGlobalMod?: boolean
}

const GroupPage = (props: GroupProps) => {
  const { role, isAdmin, isGlobalMod } = props

  const canModerate =
    isAdmin === true || isGlobalMod === true || role === GroupRoles.mod

  const sharedProps = {
    canModerate: canModerate,
    parentLoading: props.loading
  }

  return (
    <Switch>
      <Route name="replies">
        <Replies {...props} {...sharedProps} />
      </Route>
      <Route name="group" exact>
        <Threads {...props} {...sharedProps} />
      </Route>
    </Switch>
  )
}

export default withUserId(
  withParams(
    firebind(GroupPage, {
      details: ({ groupId }) => groupId && `groups/details/${groupId}`,
      role: ({ groupId, userId }) =>
        groupId && userId && `groups/members/${groupId}/${userId}`,
      isAdmin: ({ userId }) => userId && `users/${userId}/standing/admin`,
      isGlobalMod: ({ userId }) =>
        userId && `users/${userId}/standing/globalModerator`
    })
  )
)
