// @flow
import React, { useState, useEffect } from 'react'
import { firebind } from '@upgrowth/firekit'
import { withUserId } from '@upgrowth/firekit'

import EventsList from './EventsList'

function SavedEvents({ events, saved, userId }) {
  const [savedEvents, setSavedEvents] = useState()

  useEffect(() => {
    if (events && saved) {
      setSavedEvents(
        Object.keys(saved)
          .map(id => events[id])
          .filter(event => event && new Date(event.time).getTime() > Date.now())
      )
    }
  }, [events, saved])

  // render

  return (
    <EventsList
      title="Saved events"
      emptyMsg="No saved upcoming events"
      events={savedEvents}
      loading={!Boolean(savedEvents)}
      userId={userId}
    />
  )
}

export default withUserId(
  firebind(SavedEvents, {
    events: 'events',
    saved: ({ userId }) => `users/${userId}/events`
  })
)
