import {Badge, Card} from '@upgrowth/react-fulcrum'
import React from 'react'
import {Link} from '@upgrowth/reactkit'
import './MagazineCard.scss'

const MagazineCard = ({picture, title, data, badge, route, ...routeParams}) => {
  const LinkOrDiv = route ? Link : 'div'
  return (
    <Card className="MagazineCard">
      <LinkOrDiv name={route} {...routeParams} className="magazine-card-picture">
        {picture && <div className="magazine-card-picture-image" style={{backgroundImage: picture && `url(${window.rewriteImageUrl(picture)})`}}/>}
      </LinkOrDiv>
      <LinkOrDiv name={route} {...routeParams} className="magazine-card-text">
        <div className="magazine-card-title">{title}</div>
        <div className="magazine-card-data">{data}</div>
      </LinkOrDiv>
      {(typeof badge === 'string' || typeof badge === 'number')
        ? <Badge className="magazine-badge">{badge}</Badge>
        : badge && <div className="magazine-badge">{badge}</div>
      }
    </Card>
  )
}

export default MagazineCard