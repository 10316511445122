import type { UserId } from '../types/types'
import { FriendRoles } from '../types/types'
import track from './track'
import { sendAuthenticatedRequest } from './auth'
import { setDB, updateDB, userId } from './database'

export const sendFriendRequest = (
  friendId: UserId,
  force: boolean = false
) => {
  const uid = userId()
  const updates = {
    [`${uid}/friends/${friendId}`]: FriendRoles.friend,
    [`${friendId}/friends/${uid}`]: force
      ? FriendRoles.friend
      : FriendRoles.requested
  }
  console.log('Sending updates', updates)

  const p = updateDB('users', updates)
  track.event('request', { category: 'friendships' })
  return p
}

export const acceptFriendRequest = (friendId: UserId) => {
  const uid = userId()
  const p = setDB(`users/${uid}/friends/${friendId}`, FriendRoles.friend)
  track.event('accept', { category: 'friendships' })
  return p
}

export const rejectFriendRequest = (friendId: UserId) => {
  const uid = userId()
  const p = setDB(`users/${uid}/friends/${friendId}`, FriendRoles.rejected)
  track.event('reject',
    { category: 'friendships' })
  return p
}

export const getUserByEmail = async (email: string, trackWithAnalytics = true): string | null => {
  try {
    const p = sendAuthenticatedRequest('search', { email })
    if (trackWithAnalytics) track.event('search', { category: 'friendships' })
    return p
  } catch (err) {
    return null
  }
}

export const unfriend = async (friendId: UserId) => {
  const uid = userId()
  const p = updateDB('users', {
    [`${uid}/friends/${friendId}`]: null,
    [`${friendId}/friends/${uid}`]: null
  })

  track.event('unfriend',
    { category: 'friendships' })
  return p
}
