// @flow

import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Redirect, Route, Routes, Switch } from '@upgrowth/reactkit'
import { DndProvider } from 'react-dnd'
import backend from 'react-dnd-html5-backend'

import routes from './routes'
import ProfileScreen from './compositions/ProfileScreen'
import Onboarding from './onboarding/Onboarding'
import Groups from './groups/Groups'
import Friends from './friends/Friends'
import Maps from './map/Maps'
import Admin from './admin/Admin'
import { TrackPageView } from './services/track'
import LoggedIn from './auth/LoggedIn'
import Login from './auth/Login'
import LogOut from './auth/LogOut'
import UseToken from './auth/UseToken'
import Dashboard from './dashboard/Dashboard'
import ForceReload from './infrastructure/ForceReload'
import Maintenance from './infrastructure/Maintenance'
import Events from './events/Events'
import PreferredNameModal from './onboarding/PreferredNameModal'

function App() {
  return (
    <ForceReload>
      <Maintenance>
        <DndProvider backend={backend}>
          <BrowserRouter>
            <TrackPageView>
              <Routes routes={routes}>
                <LoggedIn
                  loading={null}
                  loggedOut={
                    <Switch>
                      {/* <Route name="saml" component={AuthWithSaml}/> */}
                      <Route name="login" component={UseToken} />
                      <Route component={Login} />
                    </Switch>
                  }
                >
                  <PreferredNameModal />
                  <Onboarding>
                    <Switch>
                      <Route name="admin" component={Admin} />
                      <Route name="dashboard" component={Dashboard} />
                      <Route name="groups" component={Groups} />
                      <Route name="friends" component={Friends} />
                      <Route name="profile" component={ProfileScreen} />
                      <Route name="onboarding" component={Onboarding} />
                      <Route name="firstMap" component={Maps} />
                      <Route name="events" component={Events} />
                      <Route name="logout" component={LogOut} />
                      <Redirect from="/" name="dashboard" />
                    </Switch>
                  </Onboarding>
                </LoggedIn>
              </Routes>
            </TrackPageView>
          </BrowserRouter>
        </DndProvider>
      </Maintenance>
    </ForceReload>
  )
}

export default App
