// @flow

import { getDB } from './database'

export function idify(id: string): string {
  return id.toLowerCase().replace(/, /g, '--').replace(/[^a-z0-9-]+/ig, '-')
}

let _servicesHost: string | null = null

const envServicesHost = process.env.REACT_APP_SERVICES_HOST
export const servicesHost = async (offlineOnly = false): Promise<string> => {
  let host = _servicesHost

  if (typeof envServicesHost === 'string' && envServicesHost.length > 0) {
    host = envServicesHost
  } else {
    if (!_servicesHost && !offlineOnly) {
      _servicesHost = await getDB('public/servicesHost')
      host = _servicesHost
    }
  }

  if (typeof host !== 'string') {
    host = '/api'
  }

  host = (host.startsWith('/') || host.startsWith('http')) ? host : `https://${host}`

  return host
}


export function stopPropagation(event: Event) { event.stopPropagation() }