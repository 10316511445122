// @flow

import * as React from 'react'
import { Row } from '@upgrowth/react-fulcrum'
import { map } from 'lodash'

import { setAvatar, setName, setBio } from '../services/users'
import AdminOnly from '../services/AdminOnly'
import ProfileBio from '../elements/ProfileBio'
import SafetyField from '../elements/SafetyField'
import {
  PromoteGlobalModButton,
  DemoteGlobalModButton
} from '../messages/ModerationButtons'

import ProfilePicture from './ProfilePicture'
import GroupPreview from './GroupPreview'
import EditableProfilePicture from './EditableProfilePicture'
import Chip from './Chip'

import './Profile.scss'

type Props = {
  profile?: UserProfile,
  groups?: UserGroups,
  editable?: boolean,
  userId: UserId,
  standing?: { isAdmin: boolean, isGlobalMod: boolean }
}

export default class Profile extends React.Component<Props> {
  state = { updateLocation: false }

  render() {
    const { profile, groups, editable, userId, standing } = this.props
    const { firstName = '', lastName = '', name, faculty, campus, bio, avatar } =
      profile || {}
    return (
      <div className="profile">
        <div>
          <h3>Profile</h3>
          <div className="profile-info">
            <div className="profile-picture">
              {editable ? (
                <EditableProfilePicture value={avatar} onChange={setAvatar} />
              ) : (
                <ProfilePicture photoURL={avatar} />
              )}
            </div>
            <div className="profile-details">
              <div className="profile-name">
                <label className="profile-label">Preferred Name</label>
                <div className="name-row">
                  {editable ? (
                    <div>
                      <SafetyField
                        placeholder="Preferred name"
                        value={name}
                        onChange={value => {
                          // If the user enters an empty name, reset to use system preferred name
                          // If the user does not have a first name, fall back to the last name
                          if (value === '') setName(firstName || lastName, 'system')
                          // Otherwise, set the preferred name and make the mode "custom"
                          else setName(value, 'custom')
                        }}
                      />
                      <div>
                        Your preferred name and profile picture will be visible
                        to everyone. This is how you will be recognised, so use
                        your name or your nick name.
                      </div>
                    </div>
                  ) : (
                    <div className="text">{name}</div>
                  )}
                </div>
              </div>
              <Row>
                <div className="given-name">
                  <label className="profile-label">Given Name</label>
                  <div className="text">{firstName}</div>
                </div>
                <div>
                  <label className="profile-label">Family Name</label>
                  <div className="text">{lastName}</div>
                </div>
              </Row>
              <div>
                <label className="profile-label">Faculty</label>
                <div className="text">{faculty}</div>
              </div>
              <div>
                <label className="profile-label">Campus</label>
                <div className="text">{campus}</div>
              </div>
              <div>
                <label className="profile-label">Bio</label>
                {editable ? (
                  <ProfileBio value={bio} onChange={setBio} />
                ) : (
                  <div className="profile-bio-textarea">{bio}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        {editable && this.props.children}
        <div className="profile-groups">
          <h3>Groups</h3>
          <div className="interest-cards">
            {map(groups, (defaults, groupId) => (
              <GroupPreview
                linked
                defaults={defaults}
                groupId={groupId}
                key={groupId}
              />
            ))}
          </div>
          <AdminOnly>
            {standing ? (
              <>
                <h3>Standing</h3>
                <div className="standing-roles">
                  {standing.isAdmin && (
                    <Chip backgroundColor="#f95754">Admin</Chip>
                  )}
                  {standing.isGlobalMod && (
                    <Chip backgroundColor="#006cab">Global Mod</Chip>
                  )}
                </div>
                <h3>Actions</h3>
                <div>
                  {standing.isGlobalMod ? (
                    <DemoteGlobalModButton userId={userId} />
                  ) : (
                    <PromoteGlobalModButton userId={userId} />
                  )}
                </div>
              </>
            ) : null}
          </AdminOnly>
        </div>
        {!editable && this.props.children}
      </div>
    )
  }
}
