// @flow
import * as React from 'react'
import { Button, Navbar } from '@upgrowth/react-fulcrum'
import './Chatbox.scss'
import AttachmentsModal from './AttachmentsModal'
import type { Attachments } from '../types/types'
import * as classnames from 'classnames'
import { isEmpty } from 'lodash'
import Editor from '../compositions/Editor'
import { CHAT_CHARACTER_LIMIT } from '../types/types'

type Props = { onSend: string => any }
type State = {
  body: string,
  showingAttachments: boolean,
  attachments: Attachments
}

export default class Chatbox extends React.Component<Props, State> {
  state = { body: '', showingAttachments: false, attachments: {} }

  handleChange = value => {
    this.setState({
      body: value
    })
  }

  handleSend = async () => {
    if (this.state.body.length > 0 || this.state.attachments.image) {
      await this.props.onSend(this.state.body, this.state.attachments)
      this.setState({ body: '', attachments: {} })
    }
  }

  handleKeydown = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter' && (ev.ctrlKey || ev.metaKey)) {
      ev.preventDefault()
      ev.stopPropagation()
      return this.handleSend()
    }
  }

  openAttachments = () => {
    this.setState({ showingAttachments: true })
  }

  closeAttachments = () => {
    this.setState({ showingAttachments: false })
  }

  handleChangeAttachments = (attachments: Attachments) =>
    this.setState({ attachments })

  render() {
    const { showingAttachments, attachments, body } = this.state
    return (
      <>
        <Navbar
          variant="light"
          className={classnames('Chatbox', {
            withAttachments:
              attachments &&
              (attachments.image || !isEmpty(attachments.mentions))
          })}
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <AttachmentsModal
            isOpen={showingAttachments}
            onRequestClose={this.closeAttachments}
            value={attachments}
            onChange={this.handleChangeAttachments}
          />
          <div
            className={classnames('image-attachment-reminder', {
              visible: attachments.image
            })}
            onClick={this.openAttachments}
          >
            Image Attached
          </div>
          <div className="attachments" />
          <Editor
            body={body}
            handleChange={this.handleChange}
            maxCharacterCount={CHAT_CHARACTER_LIMIT}
          />
          <div
            style={{
              display: 'flex',
              flex: 1,
              width: '100%',
              marginTop: 5
            }}
          >
            <div style={{ flex: 1 }}>
              <Button onClick={this.openAttachments} className="shape-pill">
                + Add an attachment
              </Button>
            </div>
            <Button
              className="shape-pill"
              onClick={this.handleSend}
              variant="secondary"
            >
              Send
            </Button>
          </div>
        </Navbar>
      </>
    )
  }
}
