import * as React from 'react'
import type { UserProfile } from '../types/types'
import * as classnames from 'classnames'
import { Switch } from '@upgrowth/react-fulcrum'
import { Map, TileLayer } from 'react-leaflet'
import { shareLocation, unshareLocation } from '../services/users'

type ViewProps = { profile: UserProfile, userId: string, lat: number, lng: number, name: string, minimal?: boolean }

export default class LocationView extends React.Component<ViewProps> {

  componentDidMount() {
    this.update(this.props)
  }

  update = ({profile: {location} = {}, userId, lat, lng, name}: ViewProps) => (
    location && name && location !== name ? shareLocation(lat, lng, name, userId) : false
  )

  UNSAFE_componentWillReceiveProps = this.update

  defaultLat = -37.9080956
  defaultLng = 145.1073593

  render() {
    const {userId, profile: {location} = {}, lat = this.defaultLat, lng = this.defaultLng, name, minimal} = this.props
    return (
      <div className="LocationView">
        <Map center={[lat, lng]} zoom={6} zoomControl={false} dragging={false} doubleClickZoom={false} boxZoom={false}>
          <TileLayer
            url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
            attribution='Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL.'
          />

        </Map>

        {name &&
        <div>
          {!minimal &&
          <div>
            <p>Your location: {name}</p>

            <h5>Let others see your location so they can find and add you as a friend?</h5>
          </div>
          }

          <div className={classnames("switch-group", {yes: location, no: !location})}>
            <label className="no">Don't Share</label>
            <Switch onClick={() => location ? unshareLocation(userId) : shareLocation(lat, lng, name, userId)} value={!!location}/>
            <label className="yes">Share</label>
          </div>

        </div>
        }
      </div>
    )

  }
}