import React, { useState } from 'react'
import { Button, Card } from '@upgrowth/react-fulcrum'

import ProcessQueueModal from './ProcessQueueModal'

import './ProcessQueue.scss'

/**
 * This component opens up the ProcessQueueModal.
 */
const ProcessQueueCard = () => {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <Card>
      <div className="inset">
        <h3>Process queue</h3>
        <p>View tasks in the processing queue.</p>
      </div>
      <Button onClick={() => setModalOpen(true)}>Open</Button>
      <ProcessQueueModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </Card>
  )
}

export default ProcessQueueCard
