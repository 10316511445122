//@flow
import React from 'react'
import { Link } from '@upgrowth/reactkit/lib/router/index'
import { Button } from '@upgrowth/react-fulcrum'
import { withParams } from '@upgrowth/reactkit'
import { isEmpty, keys, last, map, chain } from 'lodash'

import SectionNav from '../compositions/SectionNav'
import type {
  GroupDetails,
  GroupId,
  GroupRole,
  Thread,
  UserId
} from '../types/types'
import { GroupRoles } from '../types/types'
import Conversation from '../messages/Conversation'
import Message from '../messages/Message'
import Screen from '../compositions/Screen'
import { deleteGroup, leaveGroup, markGroupRead } from '../services/groups'
import ModerationTools from '../messages/ModerationTools'
import { firebind, withUserId } from '../services/database'

import GroupChatbox from './GroupChatbox'
import GroupProfile from './GroupProfile'
import MemberList from './MemberList'
import './Threads.scss'

type Props = {
  details?: GroupDetails,
  count?: number,
  threads?: { [threadId: string]: Thread },
  groupId: GroupId,
  userId: UserId,
  role: GroupRole,
  isAdmin?: boolean | {||},
  canModerate: boolean
}

class Threads extends React.PureComponent<Props, *> {
  conversation: Conversation

  componentDidUpdate(oldProps) {
    const {
      loading,
      groupId,
      history,
      role,
      details,
      isAdmin,
      parentLoading
    } = this.props

    // If the group doesn't exist, or the user is not a member or moderator
    // and the group is hidden, redirect the user back to the groups page
    if (
      !parentLoading &&
      (isEmpty(details) ||
        (isAdmin !== true && isEmpty(role) && details.hidden))
    ) {
      history.push('/groups')
    }

    let { lastRead, threads } = this.props
    if (typeof lastRead === 'object') {
      lastRead = 0
    }
    if (oldProps.threads === threads && loading === oldProps.loading) {
      return
    }
    if (loading || !threads || isEmpty(threads)) {
      return
    }
    const postsWithId = chain(threads)
      .map((value, key) => ({ ...value, key }))
      .filter('updatedAt')
      .sortBy('updatedAt')
      .value()
    const latest = last(postsWithId)
    const { updatedAt } = latest
    if (lastRead < updatedAt) {
      return markGroupRead(groupId, latest.key)
    }
  }

  sortedThreadIds = () => {
    const { threads } = this.props
    return keys(threads).sort(
      (a, b) => threads[b].updatedAt - threads[a].updatedAt
    )
  }

  delete = async () => {
    const { groupId, history } = this.props
    await deleteGroup(groupId)
    history.push('/groups')
  }

  render() {
    const {
      details = {},
      threads,
      groupId,
      userId,
      count,
      role,
      isAdmin,
      canModerate,
      history,
      parentLoading
    } = this.props

    // Don't show anything if:
    //  - group/user details are loading
    //  - the group has no details
    //  - the user isn't and admin and is not a member of a hidden group
    if (
      parentLoading ||
      isEmpty(details) ||
      (!isAdmin && isEmpty(role) && details.hidden)
    ) {
      return null
    }

    return (
      <Screen
        className="Group Threads"
        header={<SectionNav backRoute="groups" header={details.name} />}
        footer={null}
      >
        <div className="thread-list">
          <div className="conversation-header">
            <div className="conversation-header-contents">
              <GroupProfile details={details} count={count}>
                <>
                  {typeof role === 'string' && role !== GroupRoles.banned && (
                    <Button
                      className="variant-light outline shape-pill"
                      onClick={() => {
                        leaveGroup(groupId)
                        history.push('/groups')
                      }}
                    >
                      Leave Group
                    </Button>
                  )}
                  {isAdmin === true && (
                    <Button
                      as={Link}
                      className="variant-light outline shape-pill"
                      name="editGroup"
                      groupId={groupId}
                    >
                      Edit Group
                    </Button>
                  )}
                  {isAdmin === true && (
                    <Button
                      className="variant-light outline shape-pill"
                      onClick={this.delete}
                    >
                      Delete Group
                    </Button>
                  )}
                </>
              </GroupProfile>
              {isAdmin === true && canModerate && (
                <MemberList groupId={groupId} isAdmin={isAdmin === true} />
              )}
            </div>
          </div>

          <GroupChatbox groupId={groupId} role={role} />

          {threads &&
            map(this.sortedThreadIds(), id => {
              const thread = threads[id]
              return (
                <Message
                  alwaysHeader
                  {...thread}
                  key={id}
                  threadId={id}
                  groupId={groupId}
                  thread
                >
                  <ModerationTools
                    mine={thread.from === userId}
                    userId={thread.from}
                    groupId={groupId}
                    messageId={id}
                    isPinned={thread.promoted}
                    mod={canModerate}
                    admin={isAdmin === true}
                    role={role}
                  />
                </Message>
              )
            })}
        </div>
      </Screen>
    )
  }
}

export default withUserId(
  withParams(
    firebind(Threads, {
      details: ({ groupId }) => `groups/details/${groupId}`,
      count: ({ groupId, details }) =>
        details &&
        details.type &&
        `groups/count/${details.type}/${groupId}/count`,
      threads: ({ groupId }) => ({
        ref: `groups/threads/${groupId}`,
        queries: {
          orderByChild: 'updatedAt',
          limitToLast: 50
        }
      }),
      lastRead: ({ groupId, userId }) =>
        `users/${userId}/secrets/group/${groupId}/lastReadAt`
    })
  )
)
