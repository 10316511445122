import React from 'react'
import { Button, Modal } from '@upgrowth/react-fulcrum'
import Dropzone from 'react-dropzone'
import Papa from 'papaparse'
import uuidv4 from 'uuid/v4'

import SAMPLE from './sample.js'
import JSONTable from '../../compositions/JSONTable'
import { getUserByEmail } from '../../services/friends.js'
import { getGroupRole, updateRole } from '../../services/groups.js'
import { getUserRole, setGlobalModerator } from '../../services/users.js'
import { MODERATOR_ROLE, GLOBAL_MODERATOR_ROLE } from '../../types/types'

const TOKENS = {
  EMAIL: 'email',
  ROLE: 'role',
  GROUP: 'group',
  ROLE_TYPES: [MODERATOR_ROLE, GLOBAL_MODERATOR_ROLE]
}

export default class BatchRoleUpdateModal extends React.Component {
  DEFAULT_STATE = {
    // Pre-upload config
    showExample: false,
    hover: false,
    file: null,
    processing: false,
    data: [],
    error: null,
    errors: [],
    // Upload config
    uploading: false,
    uploadFinished: false,
    promises: null
  }

  state = { ...this.DEFAULT_STATE }

  closeModal = () => {
    // Only allow the modal to be closed if we are not processing a request.
    if (!this.state.uploading || this.state.uploadFinished) {
      this.setState(this.DEFAULT_STATE)
      this.props.onClose()
    }
  }

  showExample = (showExample: boolean) => () => {
    this.setState({ showExample })
  }

  // These methods are props for the Dropzone component
  dropzoneMethods = {
    onDragEnter: () => {
      this.setState({ hover: true })
    },
    onDragLeave: () => {
      this.setState({ hover: false })
    },
    onDropRejected: () => {
      this.setState({ hover: false })
    },
    onDropAccepted: files => {
      this.setState({
        hover: false,
        processing: true,
        file: files[0],
        error: null
      })
      // Parse the CSV file
      // See https://www.papaparse.com/docs
      Papa.parse(files[0], {
        header: true,
        worker: false,
        skipEmptyLines: true,
        complete: (results, file) => {
          const errorCollector = []

          // Validate each row of the CSV data
          results.data.forEach((row, index) => {
            let localErrors = []

            if (!row.email || !row.email.includes('@'))
              localErrors.push(`"${row.email}" is not a valid email`)

            if (!row.role || !TOKENS.ROLE_TYPES.includes(row.role))
              localErrors.push(`"${row.role}" is not a valid role`)

            if (row.role !== MODERATOR_ROLE && row.group)
              localErrors.push(
                `cannot assign the role "${row.role}" to a group`
              )

            if (row.role === MODERATOR_ROLE && !row.group)
              localErrors.push(`mods must be assigned to a group`)

            // If there were errors, push them to the global errors array
            if (localErrors.length > 0) {
              errorCollector.push({
                row: index,
                message: localErrors.join(', ')
              })
            }
          })

          // Create update object to be passed to this.setState()
          const update = {
            processing: false,
            error: null,
            data: [],
            errors: []
          }

          if (errorCollector.length > 0 || results.errors.length > 0) {
            // If there are errors, set the update.errors object
            update.errors = [...results.errors, ...errorCollector]
          } else {
            // Otherwise, pass the data through
            update.data = results.data
          }

          // Update the state!
          this.setState(update)
        },
        error: (error, file) => {
          console.warn(error)
          this.setState({
            processing: false,
            data: [],
            errors: [],
            error
          })
        }
      })
    }
  }

  // Clear the data from uploaded file
  clearData = () => {
    this.setState({
      file: null,
      processing: false,
      data: [],
      error: null,
      errors: []
    })
  }

  submit = () => {
    this.setState({
      uploading: true,
      promises: null,
      uploadFinished: false
    })
    // For each row, create a promise to run the update for the user
    const promises = this.state.data.reduce((accumulator, row, index) => {
      return {
        ...accumulator,
        [index]: {
          ...row,
          result: 'Pending...',
          __complete: false,
          // When this promise resolves, it is responsible for letting the state know.
          __promise: this.runUpdate(row, index)
        }
      }
    }, {})
    this.setState({ promises })
  }

  runUpdate = async ({ email, role, group }, index) => {
    let userId
    let result
    try {
      // Get user id
      let response = await getUserByEmail(email, false)
      if (response.status === 200) {
        // Extract user id from response
        userId = response.data
      }
    } catch (error) {
      result = 'Failed - user not found'
    }
    if (userId) {
      result = 'No update applied'
      try {
        if (role === MODERATOR_ROLE && group) {
          // Get their group role
          const groupRole = await getGroupRole(group, userId)
          if (groupRole !== MODERATOR_ROLE) {
            // If they are not, apply the update
            await updateRole(group, userId, MODERATOR_ROLE)
            result = 'Update complete.'
          }
        } else if (role === GLOBAL_MODERATOR_ROLE) {
          // Check if they are a global mod
          const roles = await getUserRole(userId)
          if (!roles || !roles.globalModerator) {
            // If they are not, apply the update
            await setGlobalModerator(userId, true)
            result = 'Update complete.'
          }
        } else {
          result = 'Failed - user or role not available'
        }
      } catch (error) {
        // Give some user feedback if something goes wrong
        console.error(error)
        result = 'An error occurred.'
      }
    }

    // === Once the promise has resolved === //

    // Update the state to display that the update has finished.
    const update = {
      promises: {
        ...this.state.promises,
        [index]: {
          email,
          role,
          group,
          result,
          __complete: true
        }
      }
    }

    // Apply update to state
    this.setState(update, () => {
      // Iterate through promises to see if we've finished all updates.
      let complete = true
      Object.keys(this.state.promises)
        .map(k => this.state.promises[k])
        .forEach(p => {
          if (!p.__complete) complete = false
        })
      if (complete) {
        // Update state to indicate to the user that the process has finished
        this.setState({ uploadFinished: true })
      }
    })
  }

  render() {
    // Configure the modal that appears.
    let buttonText = 'Update users'
    let buttonDisabled = false
    if (this.state.data.length === 0 || this.state.errors.length > 0) {
      buttonDisabled = true
    }
    if (this.state.processing) {
      buttonDisabled = true
      buttonText = 'Processing...'
    }

    // This is the view that is shown when an upload is not occurring.
    const preUpload = (
      <>
        <p>
          Upload a CSV file with the following format to batch update user
          roles.
        </p>
        <ul>
          <li>
            Use the column <code>{TOKENS.EMAIL}</code> to list the users to
            update.
          </li>
          <li>
            Use the column <code>{TOKENS.ROLE}</code> to specify which role (
            {TOKENS.ROLE_TYPES.join(', ')}) to assign to a user.
          </li>
          <li>
            If assigning a user to be a mod, use the column{' '}
            <code>{TOKENS.GROUP}</code> to specify which group to make the user
            a the moderator of. To assign the user to multiple groups, add a new
            row with the same user details but a new group name.
          </li>
        </ul>
        {!this.state.data.length && (
          <>
            <p>
              <button
                className="link"
                onClick={this.showExample(!this.state.showExample)}
              >
                {this.state.showExample ? 'Hide example' : 'Show example'}
              </button>
            </p>
            {this.state.showExample && <JSONTable data={SAMPLE} />}
          </>
        )}
        {this.state.errors.length > 0 && (
          <>
            <h3 className="Error">
              Your CSV file has {this.state.errors.length} error
              {this.state.errors.length === 1 ? '' : 's'}
            </h3>
            <ul>
              {this.state.errors.map(e => (
                <li key={uuidv4()}>
                  {`Row ${e.row} - `}
                  {e.message}
                </li>
              ))}
            </ul>
          </>
        )}
        {this.state.error && (
          <>
            <h3 className="Error">Your CSV file could not be parsed</h3>
            <p>{JSON.stringify(this.state.error, null, 2)}</p>
          </>
        )}
        {this.state.data.length ? (
          <>
            <h3>The following changes will be made:</h3>
            <JSONTable data={this.state.data} />
            <p>
              <button className="link" onClick={this.clearData}>
                Clear
              </button>
            </p>
          </>
        ) : (
          <Dropzone
            className="Dropzone"
            multiple={false}
            accept="text/csv, application/vnd.ms-excel"
            {...this.dropzoneMethods}
          >
            <div
              className={this.state.hover ? 'DropzoneBox Hover' : 'DropzoneBox'}
            >
              {this.state.file
                ? `File: ${this.state.file.name}`
                : 'Drop a CSV file here or click to choose a file'}
            </div>
          </Dropzone>
        )}
        <Button
          className="Upload"
          disabled={buttonDisabled}
          onClick={this.submit}
        >
          {buttonText}
        </Button>
      </>
    )

    // This is the view that shows when the data is uploading
    const uploading = (
      <>
        <b>
          {this.state.uploadFinished
            ? 'Updates complete.'
            : 'Updates in progress...'}
        </b>
        <p className={this.state.uploadFinished ? null : 'InProgress'}>
          {this.state.uploadFinished
            ? 'You may now close this window.'
            : 'Please do not close this window, or only partial changes may be applied.'}
        </p>
        {this.state.promises && (
          <JSONTable
            data={Object.keys(this.state.promises).map(
              key => this.state.promises[key]
            )}
          />
        )}
      </>
    )

    return (
      <Modal
        className="BatchRoleUpdateModal"
        isOpen={this.props.open}
        onRequestClose={this.closeModal}
        appElement={document.body}
        closeElement={
          this.state.uploading && !this.state.uploadFinished ? null : 'Close'
        }
      >
        <h1>Batch role update</h1>
        {this.state.uploading ? uploading : preUpload}
      </Modal>
    )
  }
}
