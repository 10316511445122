import React from 'react'
import Section from '../compositions/Section'
import {Route, Switch} from '@upgrowth/reactkit/lib/router/index'
import FriendsMenu from './FriendsMenu'
import Friend from './Friend'
import FriendsMagazine from './FriendsMagazine'
import FriendRequests from './FriendRequests'
import AddFriend from './AddFriend'
import UnreadFriends from './UnreadFriends'

export default () => (
  <Section className="Groups" sidebar={<FriendsMenu/>} primeRoute="friends">
    <Switch>
      <Route name="friendRequests" component={FriendRequests}/>
      <Route name="addFriend" component={AddFriend}/>
      <Route name="friendsMenu" component={FriendsMenu}/>
      <Route name="allFriends" component={FriendsMagazine}/>
      <Route name="unreadFriends" component={UnreadFriends}/>
      <Route name="friend" component={Friend}/>
      <Route>
        <FriendsMagazine hideNav/>
      </Route>
    </Switch>
  </Section>
)