// @flow

import React, { useCallback } from 'react'
import { FaGripVertical } from 'react-icons/fa'
import { useDrag, useDrop } from 'react-dnd'

import './OrderableListItem.scss'

const ITEM_TYPE = 'item'

/**
 * This component renders a list item that supports
 * drag and drop for re-ordering.
 *
 * Uses the `react-dnd` package internally.
 * See https://react-dnd.github.io/react-dnd/docs/overview for documentation.
 */
const OrderableListItem = ({
  // The data to show in the item
  data,
  // The complete list of items
  dataList = [],
  // A function used to set the data in the list in some order
  setDataList = () => {},
  // Function to get the id of an item
  getId = (item: { id: string }) => item.id,
  // The react component that will be used to render the item
  display: Display,
  // Props passed to the display component
  displayProps = {},
  // An id used to specify which drop targets will accept this item.
  // Useful if there are multiple drag-and-drop components on display.
  itemType = ITEM_TYPE
}) => {
  /**
   * Find the index of the data with the given id.
   *
   * @param id The id of the item.
   */
  const findIndex = useCallback(
    (id: string) => dataList.findIndex(c => getId(c) === id),
    [dataList, getId]
  )

  /**
   * Move an item with the given id to the given index.
   *
   * @param id The id of the item to move.
   * @param moveToIndex The index to move the item to.
   */
  const moveToIndex = useCallback(
    (id: string, moveToIndex: number) => {
      const index = findIndex(id)
      const newList = [...dataList]
      // Take out the item from the list
      const [item] = newList.splice(index, 1)
      // Insert it into the desired position
      newList.splice(moveToIndex, 0, item)
      setDataList(newList)
    },
    [findIndex, dataList, setDataList]
  )

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: itemType,
      id: getId(data)
    },
    canDrag: () => true,
    end: (result, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveToIndex(droppedId, originalIndex)
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, drop] = useDrop({
    accept: itemType,
    hover: (item: Item) => {
      if (item.id !== getId(data)) {
        moveToIndex(item.id, findIndex(getId(data)))
      }
    },
    collect: monitor => {
      const validTarget =
        monitor.getItem() && monitor.getItem().id !== getId(data)
      return {
        canDrop: validTarget,
        isOver: validTarget && monitor.isOver()
      }
    }
  })

  return drop(
    preview(
      <div className="OrderableListItem">
        {Display && (
          <Display data={data} isDragging={isDragging} {...displayProps}>
            {drag(
              <div className="grip">
                <FaGripVertical />
              </div>
            )}
          </Display>
        )}
      </div>
    )
  )
}

export default OrderableListItem
