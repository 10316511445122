// @flow
import React, { useState, useCallback, useEffect } from 'react'
import { withUserId } from '@upgrowth/firekit'

import { getFunction } from '../services/functions'

import EventsList from './EventsList'

function MyEvents({ userId }) {
  const [eventsList, setEventsList] = useState()

  const fetchEvents = useCallback(async () => {
    try {
      const { data: events } = await getFunction('getMyEvents')()
      setEventsList(events)
    } catch (error) {
      console.error(error)
    }
  }, [setEventsList])

  useEffect(() => {
    if (!eventsList) fetchEvents()
  }, [eventsList, fetchEvents])

  return (
    <EventsList
      title="My events"
      emptyMsg="You have not created any events yet"
      events={eventsList}
      loading={!Boolean(eventsList)}
      userId={userId}
      updateLocalData
    />
  )
}

export default withUserId(MyEvents)
