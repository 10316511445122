//@flow
import React from 'react'
import SectionNav from '../compositions/SectionNav'
import type {
  GroupId,
  GroupMessages,
  GroupRole,
  Thread,
  UserId
} from '../types/types'
import Conversation from '../messages/Conversation'
import { keys, last, map, size, isEmpty } from 'lodash'
import Message from '../messages/Message'
import { markRead } from '../services/messaging'
import Screen from '../compositions/Screen'
import GroupChatbox from './GroupChatbox'
import { withParams } from '@upgrowth/reactkit'
import SkipToNowButton from '../messages/SkipToNowButton'
import ModerationTools from '../messages/ModerationTools'
import './Replies.scss'
import { firebind, withUserId } from '../services/database'

type Props = {
  replies?: GroupMessages,
  thread?: Thread,
  groupId: GroupId,
  threadId: string,
  userId: UserId,
  role: GroupRole,
  canModerate: boolean,
  isAdmin?: boolean | {||}
}

class Replies extends React.Component<Props, *> {
  state = { atBottom: true }
  conversation: Conversation

  goToTop = () => {
    this.conversation.scrollToTop()
  }

  skipToNow = () => {
    this.conversation.scrollToBottom()
  }

  handleScroll = atBottom =>
    atBottom !== this.state.atBottom && this.setState({ atBottom })
  handleUpdate = () => {
    const { threadId, messages } = this.props
    return markRead(threadId, 'thread', last(keys(messages)))
  }

  render() {
    const { atBottom } = this.state
    const {
      thread,
      replies,
      threadId,
      groupId,
      userId,
      role,
      isAdmin,
      canModerate
    } = this.props

    // Finds reply with the most likes. If there are multiple replies with
    // the same number of most likes, it then finds the reply with the latest
    // activity
    const bestReplyId =
      replies &&
      !isEmpty(replies) &&
      keys(replies).sort((replyAId, replyBId) => {
        const likeCountDelta =
          replies[replyBId].likeCount - replies[replyAId].likeCount

        if (likeCountDelta !== 0) {
          return likeCountDelta
        }

        const replyAat = replies[replyAId].updatedAt || replies[replyAId].at
        const replyBat = replies[replyBId].updatedAt || replies[replyBId].at

        return replyBat - replyAat
      })[0]
    const bestReply = bestReplyId && replies[bestReplyId]

    // If the user should not be allowed to post, we can set footer to `null`.
    // Currently, there aren't any situations where a user shouldn't be allowed
    // post in a group they belong in, so there is no check necessary.
    const footer = (
      <GroupChatbox
        groupId={groupId}
        role={role}
        threadId={threadId}
        count={size(replies)}
      />
    )

    return (
      <Screen
        className="Group Replies"
        header={
          <SectionNav
            backRoute="group"
            groupId={groupId}
            header={'Message Thread'}
            contextButton={
              <SkipToNowButton
                atBottom={atBottom}
                onGoToTop={this.goToTop}
                onSkipToNow={this.skipToNow}
              />
            }
          />
        }
        footer={footer}
      >
        <Conversation
          ref={conversation => (this.conversation = conversation)}
          contextId={threadId}
          onUpdate={this.handleUpdate}
          onScroll={this.handleScroll}
        >
          <div className="conversation-header">
            <Message {...thread} groupId={groupId} threadId={threadId} />
          </div>
          {bestReply && bestReply.likeCount > 0 && (
            <div>
              <h3 className="header">Most Liked Reply</h3>
              <Message
                alwaysHeader
                {...bestReply}
                threadId={threadId}
                replyId={bestReplyId}
                groupId={groupId}
                bestPost
              >
                <ModerationTools
                  mine={bestReply.from === userId}
                  userId={bestReply.from}
                  threadId={threadId}
                  groupId={groupId}
                  messageId={bestReplyId}
                  mod={canModerate}
                  admin={isAdmin === true}
                  role={role}
                />
              </Message>
              <hr />
            </div>
          )}
          <div className="replies">
            {replies &&
              map(replies, (reply, id) => (
                <Message
                  {...reply}
                  key={id}
                  groupId={groupId}
                  threadId={threadId}
                  replyId={id}
                >
                  <ModerationTools
                    mine={reply.from === userId}
                    userId={reply.from}
                    threadId={threadId}
                    groupId={groupId}
                    messageId={id}
                    mod={canModerate}
                    admin={isAdmin === true}
                    role={role}
                  />
                </Message>
              ))}
          </div>
        </Conversation>
      </Screen>
    )
  }
}

export default withUserId(
  withParams(
    firebind(Replies, {
      replies: ({ groupId, threadId }) => ({
        ref: `groups/replies/${groupId}/${threadId}`,
        queries: {
          orderByKey: true,
          limitToLast: 1000
        }
      }),
      threadPosterAvatar: ({ thread }) =>
        thread && thread.from && `users/${thread.from}/profile/avatar`,
      thread: ({ groupId, threadId }) => `groups/threads/${groupId}/${threadId}`
    })
  )
)
