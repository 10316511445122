// @flow

import icon from './add-friend.svg'
import whiteIcon from './friends-white.svg'

import { Button } from '@upgrowth/react-fulcrum'
import * as React from 'react'
import { acceptFriendRequest, rejectFriendRequest, sendFriendRequest } from '../services/friends'
import { FriendRoles } from '../types/types'
import { Link } from '@upgrowth/reactkit'
import { FaCheck } from 'react-icons/fa'
import rejectIcon from './no-white.svg'
import rejectedIcon from './no-red.svg'
import { firebind, withUserId } from '../services/database'

const terminally = (action: () => any, ...args) => (event: Event) => {
  event.stopPropagation()
  event.preventDefault()
  action(...args)
}

const FriendButton = ({ friendId, friendship, loading, text }) => {
  if (loading) {
    return null
  }

  const buttonClass = text ? 'shape-pill' : 'shape-round'

  switch (friendship) {
    case FriendRoles.friend:
      return (
        !text && <Button className={buttonClass}
          variant="secondary"
          as={Link}
          name="friend"
          friendId={friendId}>
          <img src={whiteIcon} alt="Friend" />

        </Button>
      )
    case FriendRoles.rejected:
      return (
        <Button className={buttonClass}
          variant="secondary"
          as={Link}
          name="friend"
          friendId={friendId}>
          {text ? 'Rejected' : <img alt='Rejected' src={rejectedIcon} />}
        </Button>
      )

    case FriendRoles.requested:
      return (
        <>
          <Button className={buttonClass + ' reject'}
            name="friend"
            onClick={terminally(rejectFriendRequest, friendId)}>
            {text ? 'Reject Friend Request' : <img style={{ width: '24px', height: '24px' }} alt='Reject' src={rejectIcon} />}
          </Button>
          <Button className={buttonClass}
            variant="secondary"
            onClick={terminally(acceptFriendRequest, friendId)}>
            {text ? 'Approve Friend Request' : <FaCheck />}
          </Button>
        </>
      )
    default:
      return (
        <Button className={`${buttonClass} outline`}
          variant="neutral"
          onClick={terminally(sendFriendRequest, friendId)}>
          {text ? 'Send Friend Request' : <img src={icon} alt="Add Friend" style={{ marginLeft: 5 }} />}

        </Button>
      )
  }
}

export default withUserId(firebind(FriendButton, { friendship: ({ userId, friendId }) => `users/${userId}/friends/${friendId}` }))