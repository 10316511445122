// @flow

import * as React from 'react'
import './FriendMenu.scss'
import { forEachRight, mapValues, pickBy, size, sum, sumBy, values } from 'lodash'
import type { FriendRole, Message, MessageId, UserId } from '../types/types'
import { FriendRoles } from '../types/types'
import { firebind, withUserId } from '../services/database'

type Props = {
  friends?: {[UserId]: FriendRole},
  lastReadMessageIds?: {[UserId]: MessageId},
  messages?: {[UserId]: {[MessageId]: Message}},
  render: (totalUnreadCount: number, requestCount: number, unreadCounts: {[UserId]: number}) => React.Node
}

const UnreadFriendMessageCount = ({friends, lastReadMessage, messages, render}: Props) => {
  const lastReadMessageIds = mapValues(lastReadMessage, 'id')
  const acceptedFriends = pickBy(friends, role => role === FriendRoles.friend)
  let requestCount = sumBy(values(friends), role => {
    const out = role === FriendRoles.requested ? 1 : 0
    // console.log("Printing out", out, role, FriendRoles.requested)
    return out
  })

  const unreadCounts = mapValues(acceptedFriends, (_, userId) => {
    if (!messages || !lastReadMessageIds) {
      return 0
    }
    const userMessages = messages[userId]
    if (!userMessages) {
      return 0
    }
    const target = lastReadMessageIds[userId]
    if (!target) {
      return size(userMessages)
    }
    let count = 0

    forEachRight(userMessages, (_, messageId) => {
      if (messageId > target) {
        count += 1
      } else {
        return false // trufact: this breaks out of a forEach
      }
    })

    return count
  })

  const totalUnreadCount = sum(values(unreadCounts))

  return render(totalUnreadCount, requestCount, unreadCounts)
}


export default withUserId(firebind(UnreadFriendMessageCount, {
  friends           : ({userId}) => `users/${userId}/friends`,
  lastReadMessage   : ({userId}) => `users/${userId}/secrets/user`,
  messages          : ({userId}) => `users/${userId}/messages`
}))


