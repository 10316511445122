// @flow

import { withParams, Redirect } from '@upgrowth/reactkit'
import Screen from '../compositions/Screen'
import * as React from 'react'
import { markRead, sendMessageToUser } from '../services/messaging'
import Chatbox from '../messages/Chatbox'
import SectionNav from '../compositions/SectionNav'
import Profile from '../compositions/Profile'
import { keys, last, map } from 'lodash'
import Conversation from '../messages/Conversation'
import Message from '../messages/Message'
import type { FriendRole, User, UserGroups, UserId, UserProfile } from '../types/types'
import { FriendRoles } from '../types/types'
import SkipToNowButton from '../messages/SkipToNowButton'
import { sendFriendRequest, unfriend } from '../services/friends'
import AddFriendButton from '../compositions/AddFriendButton'
import { Button } from '@upgrowth/react-fulcrum'
import ModerationTools from '../messages/ModerationTools'
import { firebind, withUserId } from '../services/database'
import './Friend.scss'

type Props = {
  profile: UserProfile,
  messages: $PropertyType<User, 'messages'>,
  friendship: FriendRole,
  myFriendship: FriendRole,
  groups: UserGroups,
  friendId: UserId,
  userId: UserId,
  isAdmin: any
}

class Friend extends React.Component<Props, *> {
  state = {atBottom: true}
  conversation: Conversation

  goToTop = () => {
    this.conversation.scrollToTop()
  }

  skipToNow = () => {
    this.conversation.scrollToBottom()
  }

  handleScroll = atBottom => (atBottom !== this.state.atBottom) && this.setState({atBottom})
  handleUpdate = () => markRead(this.props.friendId, 'user', last(keys(this.props.messages)))

  render() {
    const {atBottom} = this.state
    const {profile = {}, messages, friendship, myFriendship, groups, friendId, userId, isAdmin, asAdmin, isGlobalMod} = this.props
    const {name} = profile
    const areMutualFriends = (myFriendship === FriendRoles.friend) && (friendship === FriendRoles.friend)

    if (friendId === userId) { return <Redirect name='profile'/>}

    return (
      <Screen className="Friend"
              header={<SectionNav historyBack
                                  header={name}
                                  contextButton={profile &&
                                  <SkipToNowButton atBottom={atBottom}
                                                   onGoToTop={this.goToTop}
                                                   onSkipToNow={this.skipToNow}/>
                                  }/>}
              footer={areMutualFriends ? <Chatbox onSend={(body, attachments) => sendMessageToUser(friendId, body, attachments)}/> : null}>
        <Conversation ref={ref => this.conversation = ref}
                      contextId={friendId}
                      onUpdate={this.handleUpdate}
                      onScroll={this.handleScroll}>
          <div className='profile-background'>
            <Profile userId={friendId} profile={profile} groups={groups} standing={{
              isAdmin: isAdmin === true,
              isGlobalMod: isGlobalMod === true,
            }} />
          </div>
          <div className="conversation-controls">
            {areMutualFriends &&
              <div className="conversation-label" style={{color: '#006cab', fontSize: '20px', fontWeight: 'normal'}}>
                Direct Message
              </div>
            }

            <div className="actions">
              {((isAdmin === true || asAdmin === true) && (friendship !== FriendRoles.friend))
                ? <Button className={`shape-pill outline`}
                                        variant="neutral"
                                        onClick={() => sendFriendRequest(friendId, true)}>
                 Start Conversation {isAdmin === true ? (asAdmin === true ? 'between Admins' : ' with Admin') : ''}
               </Button>
               :  <AddFriendButton friendId={friendId} text/>
              }

              {(myFriendship === FriendRoles.friend) && <Button className='shape-pill outline'
                                                              variant="neutral"
                                                              name="friend"
                                                              onClick={() => unfriend(friendId)}>
                {(isAdmin === true || asAdmin === true) ? 'End Conversation' : 'Unfriend'}
                </Button>}

              {(friendship === FriendRoles.requested) && <span>Friend Request Sent</span>}
            </div>
          </div>

          {messages && map(messages, (message, id) => (
            <Message neverHeader noVote {...message} key={id} messageId={id}>
              <ModerationTools to={message.to} mine={message.from === userId} userId={message.from} messageId={id}/>
            </Message>
          ))}
        </Conversation>
      </Screen>
    )
  }
}

export default withParams(withUserId(firebind(Friend, {
  groups: ({friendId}) => ({
    ref: `users/${friendId}/groups`,
    queries: {
      orderByChild: 'type',
      equalTo: 'interest'
    }
  }),
  profile: ({friendId}) => `users/${friendId}/profile`,
  isAdmin: ({friendId}) => `users/${friendId}/standing/admin`,
  isGlobalMod: ({friendId}) => `users/${friendId}/standing/globalModerator`,
  messages: ({userId, friendId}) => `users/${userId}/messages/${friendId}`,
  myFriendship: ({userId, friendId}) => `users/${userId}/friends/${friendId}`,
  friendship: ({userId, friendId}) => `users/${friendId}/friends/${userId}`,
  asAdmin: ({userId}) => `users/${userId}/standing/admin`
})))