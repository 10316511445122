// @flow

import { firekit } from '@upgrowth/firekit'
import firebase from 'firebase/app'
import track from './track'
import axios from 'axios'
import { servicesHost } from './util'
import { isEmpty } from 'lodash'
import { currentUser, getDB, googleJunction, handleAuthData, idToken, signOut, updateAuthData, updateDB, userId } from './database'

const LOGIN_TOKEN_KEY = 'monash-amigo-session-token'

const getStudentInfo = async (email: string) => {
  return {
    faculty: 'Law',
    campus: 'Clayton Campus'
  }
}

export const loginWithGoogle = async () => {
  const auth = firekit().auth
  const googleAuth = new firebase.auth.GoogleAuthProvider()
  try {
    await auth.signInWithPopup(googleAuth)
    const user = firekit().auth.currentUser

    updateAuthData(user)
    const [firstName = '', lastName = ''] = user.displayName.split(' ')

    const studentInfo = await getStudentInfo(auth.currentUser.email);

    const update = {
      'secrets/email': user.email,
      'profile/firstName': firstName,
      'profile/lastName': lastName,
      'profile/faculty': studentInfo.faculty,
      'profile/campus': studentInfo.campus
    }

    console.log('Setting initial account', update)
    await updateDB(`users/${userId()}`, update)
    track.identify(user.uid)
  } catch (err) {
    window.alert(`Couldn't log in: ${err.message}`)
    console.error('Login error', err)
    return firekit().auth.signOut()
  }
}

export async function attemptAutoLogin() {
  let oauthToken = window.localStorage.getItem(LOGIN_TOKEN_KEY)
  if (oauthToken && !isEmpty(oauthToken) && !currentUser() && oauthToken !== 'undefined') {
    try {
      return await loginWithOAuthToken(oauthToken)
    } catch (error) {
      console.error("Autologin failed, clearing token")
      clearSavedToken()
      window.location.replace("/")
    }
  }
}

export async function loginWithOAuthToken(oauthToken: string) {
  const host = await servicesHost()
  const { data, status } = await axios.get(`${host}/token?oauthToken=${encodeURIComponent(oauthToken)}`)
  if (!data || !data.token) {
    console.error("No token returned by server", data, status)
    throw new Error("No token returned by server")
  }
  window.localStorage.setItem(LOGIN_TOKEN_KEY, oauthToken)
  return handleAuthData(data)
}

export function clearSavedToken() {
  window.localStorage.removeItem(LOGIN_TOKEN_KEY)
}


export const authedClient = async () => {

  const baseURL = await servicesHost()
  const token = await idToken()
  const headers: any = {
    // 'Content-Type': 'application/json'
  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  // console.log("Headers", headers)
  return axios.create({
    baseURL,
    headers
  })
}


export function postProcessResponseHeaders(result) {
  if (result && result.headers && result.headers['X-Next-Custom-Token']) {
    // console.log("Updating custom token")
    updateAuthData({ ...window.amigoCurrentUser, token: result.headers['X-Next-Custom-Token'] })
  }

  if (result.status === 498) {
    window.alert("Your session has expired.")
    clearSavedToken()
    window.location.assign('/')
  }

  return result
}

async function sendAuthenticatedRequestWithGoogle(path: string, requestBody: any = {}) {
  const result = await (await authedClient()).post(path, requestBody)

  if (result.status === 403) {
    window.alert("Your session has expired.")
    clearSavedToken()
    window.location.assign('/')
  }

  return postProcessResponseHeaders(result)
}


function sendAuthenticatedRequestWithoutGoogle(path: string, requestBody: any = {}) {
  return sendAuthenticatedRequestWithGoogle(`client/request/${path}`, requestBody)
}

export const sendAuthenticatedRequest = googleJunction(sendAuthenticatedRequestWithGoogle, sendAuthenticatedRequestWithoutGoogle)

export const logout = async () => {
  track.anonymise()
  console.info("Logging out...")
  clearSavedToken()
  await signOut()
  window.location.assign(await getDB('public/logoutUrl'))
}