import React from "react";
import PropTypes from "prop-types";
import "./Chip.scss";

function Chip({ children, backgroundColor, fontColor }) {
  return (
    <div
      className="unselectable"
      style={{
        backgroundColor,
        color: fontColor,
        borderRadius: 10,
        textAlign: "center",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 2,
        paddingBottom: 2,
        marginLeft: 2,
        marginRight: 2
      }}
    >
      <span>{children}</span>
    </div>
  );
}

Chip.defaultProps = {
  backgroundColor: "#c3e3f6",
  fontColor: "#ffffff",
  children: "Chip"
};

Chip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fontColor: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default Chip;
