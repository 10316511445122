import { MODERATOR_ROLE, GLOBAL_MODERATOR_ROLE } from '../../types/types'

export default [
  {
    email: 'Orla.Finch@monash.edu',
    role: GLOBAL_MODERATOR_ROLE,
    group: ''
  },
  {
    email: 'Karen.Castillo@monash.edu',
    role: MODERATOR_ROLE,
    group: 'monash-new-starters'
  },
  {
    email: 'Karen.Castillo@monash.edu',
    role: MODERATOR_ROLE,
    group: 'monash-university'
  },
  {
    email: 'Jamie.Franco@student.monash.edu',
    role: GLOBAL_MODERATOR_ROLE,
    group: ''
  }
]
