// @flow

import React, { useState, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { Badge } from '@upgrowth/react-fulcrum'
import Fuse from 'fuse.js'

import { firebind, withUserId } from '../services/database'
import { getFunction } from '../services/functions'

import UnreadBadge from '../messages/UnreadBadge'
import MagazineCard from '../compositions/MagazineCard'
import Screen from '../compositions/Screen'
import Magazine from '../compositions/Magazine'
import SearchField from '../compositions/SearchField'

import sortGroups from './sortGroups'

import type {
  GroupCount,
  GroupData,
  GroupDetails,
  GroupId
} from '../types/types'

function pluralise(singular: string, plural: string, number: number) {
  number = typeof number === 'number' || typeof number === 'string' ? number : 0
  return `${number} ${number === 1 ? singular : plural}`
}

type CardProps = {
  name: string,
  groupDetails: GroupDetails,
  count: number,
  groupId: GroupId
}

const GroupMagazineCard = firebind(
  ({ name, groupDetails, count, groupId }: CardProps) => {
    return (
      <MagazineCard
        route="group"
        groupId={groupId}
        title={name}
        picture={groupDetails.photo}
        data={pluralise('member', 'members', count)}
        badge={
          <Badge className="unread-badge">
            <UnreadBadge sourceType="group" sourceId={groupId} />
          </Badge>
        }
      />
    )
  }
)

type Props = {
  loading: boolean,
  groups?: GroupData<GroupDetails>,
  counts?: GroupData<GroupCount>,
  emptyQueryRender?: () => React.Node,
  leader?: React.ReactNode,
  render?: React.ComponentType<{
    groupDetails: GroupDetails,
    groupId: GroupId,
    unreadCount: number
  }>
}

const fuse = new Fuse([], {
  keys: ['name', 'description', 'tags']
})

const GroupsMagazine = (props: Props) => {
  const {
    loading,
    counts,
    emptyQueryRender,
    leader = <h1 className="heading">What are you searching for?</h1>,
    render: Render = ({ groupDetails, groupId, unreadCount }) => (
      <GroupMagazineCard
        name={groupDetails.name}
        groupDetails={groupDetails}
        groupId={groupId}
        count={unreadCount}
      />
    ),
    sortOrder
  } = props

  const [fetching, setFetching] = useState(false)
  const [groupsList, setGroupsList] = useState()
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])

  // Function to fetch filtered groups from database using a Firebase function
  const fetchGroups = useCallback(async () => {
    try {
      const { data: groups } = await getFunction('getGroups')()
      setGroupsList(groups)
    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }, [setGroupsList])

  // Fetch the groups to show if it hasn't been fetched yet.
  useEffect(() => {
    if (!fetching && !groupsList) {
      setFetching(true)
      fetchGroups()
    }
  }, [fetching, setFetching, fetchGroups, groupsList])

  // Update the data used for searching by fuse.js when the group list is updated
  useEffect(() => {
    if (groupsList) {
      fuse.setCollection(groupsList)
    }
  }, [groupsList])

  // Update search results when the query changes
  useEffect(() => {
    let searchResults = []

    if (!loading && groupsList) {
      // If we have a query, use fuse.js to search groups
      if (query) searchResults = fuse.search(query)
      // Otherwise sort all groups
      else
        searchResults = sortGroups(groupsList, {
          sortOrder,
          additionalFields: ['name']
        })

      // Set the results
      setResults(searchResults)
    }
  }, [query, groupsList, loading, sortOrder])

  return (
    <Screen className="GroupsMagazine">
      {leader}
      <SearchField query={query} onChange={setQuery} />
      {!loading &&
        groupsList &&
        (query || !emptyQueryRender ? (
          <Magazine>
            {results.map(details => (
              <Render
                key={details.groupId}
                groupDetails={details}
                groupId={details.groupId}
                unreadCount={get(counts, [
                  details.type,
                  details.groupId,
                  'count'
                ])}
              />
            ))}
          </Magazine>
        ) : (
          emptyQueryRender()
        ))}
    </Screen>
  )
}

export default withUserId(
  firebind(GroupsMagazine, {
    counts: () => `groups/count`,
    sortOrder: `groups/sortOrder`
  })
)
