// @flow

import {Input} from '@upgrowth/react-fulcrum'
import React from 'react'
import searchIcon from './search.svg'
import './SearchField.scss'

type Props = {query: string, onChange: (string) => any}
export default ({query, onChange}: Props) => (
  <div className="SearchField">
    <div className="search-icon">
      <img src={searchIcon} alt="Search Icon"/>
    </div>

    <Input name="query" type="search" value={query} placeholder="Search" onChange={onChange}/>
  </div>
)