// @flow

import type {
  Attachments,
  GroupId,
  GroupRole,
  MessageId,
  UserId
} from '../types/types'
import track from './track'
import { sendAuthenticatedRequest } from './auth'
import { isEmpty } from 'lodash'
import {
  getDB,
  newKey,
  pushDB,
  removeDB,
  setDB,
  timestampNow,
  updateDB,
  userId
} from './database'
import { uploadProfilePic } from './users'

const at = timestampNow()
const preprocessMessage = message => message.replace(/\n(\s*\n)*\n/g, '\n\n')

export const sendMessageToUser = (
  friendId: UserId,
  body: string,
  attachments: Attachments
) => {
  const uid = userId()
  const key = newKey()
  const message = {
    body: preprocessMessage(body),
    at,
    from: uid,
    to: friendId,
    attachments
  }

  const updates = {
    [`${uid}/messages/${friendId}/${key}`]: message,
    [`${friendId}/messages/${uid}/${key}`]: message
  }
  const p = updateDB('users', updates)

  // console.log("Sending message to user", updates)

  track.event('friendMessage', { category: 'messaging' })

  return p
}

export const notifyReplies = (
  attachments: Attachments,
  groupId: GroupId,
  threadId: string
) =>
  attachments &&
  !isEmpty(attachments.mentions) &&
  Promise.all(
    attachments.mentions.map(userId =>
      sendMessageToUser(userId, '@', { mentioned: { groupId, threadId } })
    )
  )

export const postThread = (
  groupId: GroupId,
  body: string,
  role: GroupRole,
  attachments: Attachments
) => {
  const thread = {
    body: preprocessMessage(body),
    at,
    from: userId(),
    to: groupId,
    replyCount: 0,
    voteCount: 0,
    updatedAt: at,
    role,
    attachments
  }
  // console.log('Posting thread', `groups/threads/${groupId}`, thread)

  const promise = pushDB(`groups/threads/${groupId}`, thread)

  // we're using .then here rather than async/await so we can return the thenable reference synchronously [LFS]
  promise.then(() => notifyReplies(attachments, groupId, promise.key))

  track.event('postThread', { category: 'messaging', label: groupId })
  return promise
}

export const replyToThread = async (
  groupId: GroupId,
  threadId: string,
  body: string,
  role: GroupRole,
  count: number,
  attachments: Attachments
) => {
  const replyId = newKey()
  // console.log('Replying to thread', {replyId, threadId, body, role, count})
  const p = updateDB('groups', {
    [`replies/${groupId}/${threadId}/${replyId}`]: {
      body: preprocessMessage(body),
      attachments,
      at,
      updatedAt: at,
      from: userId(),
      to: groupId,
      role,
      voteCount: 0
    },
    [`threads/${groupId}/${threadId}/updatedAt`]: at,
    [`threads/${groupId}/${threadId}/replyCount`]: count + 1
  })

  await p
  notifyReplies(attachments, groupId, threadId)

  track.event('reply', { category: 'messaging', label: groupId, threadId })
  return p
}

export const toggleLikeMessage = async (
  groupId: GroupId,
  threadId: MessageId,
  replyId?: MessageId,
  userId: UserId
) => {
  // console.log('upvote', groupId, threadId, replyId)

  if (replyId != null) {
    const liked =
      (await getDB(
        `groups/likedReplies/${groupId}/${threadId}/${replyId}/${userId}`
      )) === true
    const likeCount =
      (await getDB(
        `groups/replies/${groupId}/${threadId}/${replyId}/likeCount`
      )) || 0

    return updateDB('groups', {
      [`likedReplies/${groupId}/${threadId}/${replyId}/${userId}`]: liked
        ? null
        : true,
      [`replies/${groupId}/${threadId}/${replyId}/likeCount`]:
        likeCount + (liked ? -1 : 1),
      [`replies/${groupId}/${threadId}/${replyId}/updatedAt`]: at
    })
  }

  const liked =
    (await getDB(`groups/likedThreads/${groupId}/${threadId}/${userId}`)) ===
    true
  const likeCount =
    (await getDB(`groups/threads/${groupId}/${threadId}/likeCount`)) || 0

  return updateDB('groups', {
    [`likedThreads/${groupId}/${threadId}/${userId}`]: liked ? null : true,
    [`threads/${groupId}/${threadId}/likeCount`]: likeCount + (liked ? -1 : 1)
  })
}

export const markRead = async (
  sourceId: GroupId | UserId,
  sourceType: 'group' | 'user' | 'thread',
  messageId: MessageId
) => {
  const uid = userId()
  if (!messageId) {
    return
  }
  return updateDB(`users/${uid}/secrets/${sourceType}/${sourceId}`, {
    lastReadAt: timestampNow(),
    id: messageId
  })
}

export const report = async (
  messageId: MessageId,
  sourceType: 'user' | 'group',
  sourceId: GroupId | UserId,
  threadId?: string
) => {
  if (!window.confirm('Are you sure you want to report this user?')) {
    return
  }
  const reportedBy = userId()

  await sendAuthenticatedRequest('reports', {
    report: { messageId, reportedBy, sourceType, sourceId, threadId, at }
  })
  window.alert('This message has been reported successfully.')
}

export const deleteThread = async (threadId: string, groupId: GroupId) => {
  if (!window.confirm('Are you sure you want to delete this thread?')) {
    return
  }

  const updates = {
    [`likedThreads/${groupId}/${threadId}`]: null,
    [`threads/${groupId}/${threadId}`]: null
  }

  if (!isEmpty(await getDB(`groups/replies/${groupId}/${threadId}`))) {
    updates[`likedReplies/${groupId}/${threadId}`] = null
    updates[`replies/${groupId}/${threadId}`] = null
  }

  // console.log('Deleting', `threads/${groupId}/${threadId}`, `replies/${groupId}/${threadId}`)
  return updateDB('groups', updates)
}

export const deleteReply = async (
  replyId: MessageId,
  threadId: string,
  groupId: GroupId
) => {
  if (!window.confirm('Are you sure you want to delete this reply?')) {
    return
  }

  await removeDB(`groups/likedReplies/${groupId}/${threadId}/${replyId}`)
  await removeDB(`groups/replies/${groupId}/${threadId}/${replyId}`)

  let replyCount = await getDB(
    `groups/threads/${groupId}/${threadId}/replyCount`
  )
  replyCount = typeof replyCount === 'number' ? replyCount - 1 : 0

  return setDB(`groups/threads/${groupId}/${threadId}/replyCount`, replyCount)
}

export const deleteUserMessage = async (
  messageId: MessageId,
  from: UserId,
  to: UserId
) => {
  if (isEmpty(messageId) || isEmpty(from) || isEmpty(to)) {
    throw new Error(
      `Invalid delete request: messageId ${messageId}, from ${from}, to ${to}`
    )
  }

  if (!window.confirm('Are you sure you want to delete this message?')) {
    return
  }

  return updateDB('users', {
    [`${from}/messages/${to}/${messageId}`]: null,
    [`${to}/messages/${from}/${messageId}`]: null
  })
}

export const promotePost = (groupId, messageId) => {
  // console.log('promote', groupId, messageId)

  updateDB('/', {
    [`promotedPosts/${messageId}`]: groupId,
    [`groups/promotedPosts/${groupId}/${messageId}`]: true,
    [`groups/threads/${groupId}/${messageId}/promoted`]: true
  })
}

export const unpromotePost = (groupId, messageId) => {
  // console.log('promote', groupId, messageId)

  updateDB('/', {
    [`promotedPosts/${messageId}`]: null,
    [`groups/promotedPosts/${groupId}/${messageId}`]: null,
    [`groups/threads/${groupId}/${messageId}/promoted`]: null
  })
}

export const uploadImageAttachment = (file: File) => uploadProfilePic(file)
