import type { GroupDetails, GroupId, GroupRole, GroupType, UserId } from '../types/types'
import { forEach } from 'lodash'
import track from './track'
import { getDB, removeDB, setDB, timestampNow, updateDB, userId, reload } from './database'

export function updateRole (
  groupId: GroupId,
  userId: UserId,
  role: GroupRole | null
) {
  if (!groupId || !userId) {
    throw new Error('No Group/User ID Given')
  }

  let updates = {
    [`groups/members/${groupId}/${userId}`]: role
  }

  if (role) {
    updates[`users/${userId}/groups/${groupId}/role`] = role
  } else {
    updates[`users/${userId}/groups/${groupId}`] = null
  }
  return updateDB('/', updates)
}

export function getGroupRole(
  groupId: GroupId,
  userId: UserId
) {
  if (!groupId || !userId) {
    throw new Error('No Group/User ID Given')
  }
  return getDB(`users/${userId}/groups/${groupId}/role`)
}

export const joinGroup = async (
  groupId: string,
  suppliedName?: string = 'Unknown Group',
  suppliedType?: GroupType = 'interest'
) => {

  if (!groupId) {
    throw new Error('No Group ID Given')
  }


  await setDB(`users/${userId()}/groups/${groupId}`, {name: suppliedName, type: suppliedType, role: 'user'})
  setTimeout(() => reload(`groups/members/${groupId}/${userId()}`), 500)
  track.event('join', {category: 'groupMemberships', label: groupId})
}

export const leaveGroup = async (groupId: string) => {
  if (!groupId) {
    throw new Error('No Group ID Given')
  }

  setTimeout(() => reload(`groups/members/${groupId}/${userId()}`), 500)

  return track.after(
    removeDB(`users/${userId()}/groups/${groupId}`),
    'leave',
    {category: 'groupMemberships', label: groupId}
  )
}

export const updateGroupDetails = (groupId: GroupId, details: GroupDetails) => {
  if (!groupId) {
    throw new Error('No Group ID Given')
  }
  // return sendAuthenticatedRequest(`groups/${groupId}/details/update`, {
  //   details
  // })

  return updateDB(`groups/details/${groupId}`, details)
}

export const deleteGroup = async (groupId: string) => {
  if (!groupId) {
    throw new Error('No Group ID Given')
  }
  if (
    window.prompt(
      'Are you sure you want to delete this group? Type \'DELETE\' in all caps if you are'
    ) !== 'DELETE'
  ) {
    return
  }

  const members = await getDB(`groups/members/${groupId}`)
  const memberUpdates = {}

  forEach(members, (role, uid) => {
    memberUpdates[`users/${uid}/groups/${groupId}`] = null
  })

  const postsUpdates = {}
  const promotedPosts = await getDB(`groups/promotedPosts/${groupId}`)

  forEach(promotedPosts, (groupId, messageId) => {
    postsUpdates[`promotedPosts/${messageId}`] = null
  })

  const {type} = await getDB(`groups/details/${groupId}`)

  const groupUpdates = {
    [`groups/count/${type}/${groupId}`]: null,
    [`groups/details/${groupId}`]: null,
    [`groups/maps/${groupId}`]: null,
    [`groups/members/${groupId}`]: null,
    [`groups/promotedPosts/${groupId}`]: null,
    [`groups/replies/${groupId}`]: null,
    [`groups/threads/${groupId}`]: null
  }

  return updateDB('/', {...groupUpdates, ...memberUpdates, ...postsUpdates})
}

export const markGroupRead = async (groupId: string, threadId: string) => {
  const update = { lastReadAt: timestampNow(), id: threadId }
  // console.log('Marking read', `users/${userId()}/secrets/group/${groupId}`, update)
  return updateDB(`users/${userId()}/secrets/group/${groupId}`, update)
}