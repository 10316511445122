import * as React from 'react'
import classnames from 'classnames'
import './Screen.scss'

export default ({className, header, children, footer, style}) => (
  <div className={classnames("Screen", className)} style={style}>
    {header && <div className="screen-header">{header}</div>}
    <div className="screen-content">{children}</div>
    <div className="screen-footer">
      {footer}
    </div>
  </div>
)