import React from 'react'
import { Button, Card } from '@upgrowth/react-fulcrum'

import BatchRoleUpdateModal from './BatchRoleUpdateModal'
import './BatchRoleUpdate.scss'

export default class BatchRoleUpdateCard extends React.Component {
  state = {
    modalOpen: false
  }

  toggleModal = (open: boolean) => () => {
    this.setState({ modalOpen: open })
  }

  render() {
    return (
      <Card className="BatchRoleUpdate">
        <div className="inset">
          <h3>Batch role update</h3>
          <p>Upload a CSV file to update user roles.</p>
        </div>
        <Button onClick={this.toggleModal(true)}>Upload</Button>
        <BatchRoleUpdateModal
          open={this.state.modalOpen}
          onClose={this.toggleModal(false)}
        />
      </Card>
    )
  }
}
