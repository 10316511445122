// @flow

import { toPairs, sortBy, isEmpty, mapValues, isEqual, keys, forEach, set, get, take, last, without, unset } from 'lodash'
import * as qs from 'qs'
import axios from 'axios'
import * as firebaseKey from 'firebase-key'
import Subscription from './Subscription'
import type { SubscriptionTreeNode } from './Subscription'
import executeQuery from './executeQuery'
import { onAuthStateChanged, timestampNow } from '../database'
import { postProcessResponseHeaders } from '../auth'

const interval = 30 * 1000 // 30 seconds

const ancestors = (path: any[]) => take(path, path.length - 1)

const emptyPush = Symbol()

type UpdateTreeNode = {
  '.'?: { [queryString: string]: number },
  [string]: UpdateTreeNode
}

function stringifyQuery (query: {} = {}) {
  return JSON.stringify(sortBy(toPairs(query), ([k, v]) => k))
}

const emptyQuery = stringifyQuery()

const pathToArray = (path: string | string[]) => typeof path === 'string' ? path.split('/') : path

function normalisePrewriteData(data) {
  if (typeof data !== 'object') { return data }
  if (isEqual(data, timestampNow())) { return Date.now() }
  return mapValues(data, normalisePrewriteData)
}

export default class Waterbase {
  // data: {[key: string]: any} = {}
  // updated: {[key: string]: number} = {}

  tree: {} = {}
  updatedTree: UpdateTreeNode = {}
  subscriptionTree: SubscriptionTreeNode = {}
  // subscriptions: { [key: string]: Subscription }
  baseUrl: string
  client: axios.instance

  static instance: Waterbase

  constructor (baseURL: string) {
    console.log("Waterbase Base URL", baseURL)
    this.client = axios.create({baseURL})
    Waterbase.instance = this
    onAuthStateChanged(this.authenticate)
  }

  authenticate = (authData) => {
    const token = authData && authData.token
    this.client.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null
  }

  lastUpdated (path, query): number {
    path = pathToArray(path)
    const node = get(this.updatedTree, path)
    if (node && node['.']) {
      return node['.'][query] || node['.'][emptyQuery] || 0
    } else {
      if (path.length <= 1) { return 0 }
      return this.lastUpdated(ancestors(path), query)
    }
  }

  get = async (path: string, query = {}, force = false) => {
    // console.info('Getting Waterbase data', path, query, force)
    if (force || Date.now() - this.lastUpdated(path, query) > interval) {
      return await this.refresh(path, query)
    }

    return this.fetchCache(path, query)
  }

  async refresh (path: string, query = {}) {
    const result = postProcessResponseHeaders(await this.client.get(`${path}${qs.stringify(query, { addQueryPrefix: true })}`))
    // console.log("Fetch result", path, result, query)
    const value = result.data.value
    this.updateCache(path, value, query)
    return value
  }

  fetchCache (path, query = {}) {
    path = pathToArray(path)
    return executeQuery(get(this.tree, path), query)
  }

  updateCache (path: string, value: any, query = {}, original = true) {
    // console.log("Updating cache", path, value, query, original)
    const pathArray = pathToArray(path)
    const previous = this.fetchCache(path, query)
    if (isEqual(previous, value)) { return }

    if (!isEmpty(query) && typeof value === 'object') {
      forEach(value, (subValue, subKey) => this.updateCache(`${path}/${subKey}`, subValue, {}, false))
    } else {
      // console.log("wb setting authoritatively", path, value)
      if (value === null || (typeof value === 'object' && isEmpty(value))) {
        unset(this.tree, pathArray)
      } else {
        set(this.tree, pathArray, value)
      }
    }

    set(this.updatedTree, [...pathArray, '.', stringifyQuery(query)], Date.now())

    if (original) {
      // inside out like a pied piper
      this.broadcastToParentsOf(pathArray, previous, value)
      this.broadcastToChildren(pathArray, previous, value)
    }
  }

  broadcastToSubscriptionsAtPath (pathArray: string[], previous: any, current: any) {
    const subNode: SubscriptionTreeNode = get(this.subscriptionTree, pathArray)

    if (subNode && subNode['.']) {
      forEach(subNode['.'], sub => {
        const currentForSub = executeQuery(current, sub.query)
        const previousForSub = executeQuery(previous, sub.query)

        if (!isEqual(currentForSub, previousForSub)) {
          sub.broadcast(currentForSub)
        }
      })
    }

    return subNode
  }

  broadcastToParentsOf (pathArray: string[], previous, current) {
    if (pathArray.length === 0) { return }

    const parentsPath = ancestors(pathArray)
    const parentContent = get(this.tree, parentsPath)
    if (parentContent) {
      const key = last(pathArray)

      const parentPrev = {...parentContent, [key]: previous}
      const parentCurrent = {...parentContent, [key]: current}
      // this one
      this.broadcastToSubscriptionsAtPath(parentsPath, parentPrev, parentCurrent)
      this.broadcastToParentsOf(parentsPath, parentPrev, parentCurrent)
    }
  }

  broadcastToChildren (pathArray: string[], previous: {}, current: {}) {
    // this one
    if (isEqual(previous, current)) {
      console.log("Skipping broadcast", pathArray, previous, current)
      return
    }

    const subNode = this.broadcastToSubscriptionsAtPath(pathArray, previous, current)

    const subKeys = without(keys(subNode), '.')
    console.log("Broadcasting to subkeys", subKeys)

    if (subKeys.length > 0) {
      forEach(subKeys, subKey => {
        this.broadcastToChildren([...pathArray, subKey], get(previous, subKey), get(current, subKey))
      })
    }

  }

  update = async (path: string, updates: {}) => {
    postProcessResponseHeaders(await this.client.patch(path, updates))
    forEach(updates, (value, subPath) => {
      // console.log("updating update path",`${path}/${subPath}`, value )
      // if (value === null) {debugger}
      this.updateCache(`${path}/${subPath}`, normalisePrewriteData(value))
    })
  }

  remove = async (path: string) => {
    postProcessResponseHeaders(await this.client.delete(path))
    this.updateCache(path, null)
  }

  push = (path: string, data = emptyPush) => {
    const key = firebaseKey.key()
    if (data === emptyPush) {
      return {key}
    } else {
      const promise = this.set(`${path}/${key}`, data)
      promise.key = key
      return promise
    }
  }

  set = async (path: string, value: any) => {
    postProcessResponseHeaders(await this.client.post(path, {value}))
    this.updateCache(path, normalisePrewriteData(value))
    return value
  }

  subscribe (path: string, callback: (any) => any, query: {} = {}) {
    const queryString = stringifyQuery(query)
    const pathArray = [...pathToArray(path), '.', queryString]
    if (pathArray.includes('undefined')) {
      console.log("Bailing from subscription since it contains an undefined entry", path, query)
      return () => {}
    }
    pathArray.includes('threads') && console.info('Subscribing', path, query)

    let sub = get(this.subscriptionTree, pathArray)
    if (!sub) {
      pathArray.includes('threads') && console.log("Creating new subscription to", path, query)
      sub = new Subscription(this, path, query)
      set(this.subscriptionTree, pathArray, sub)
      window.subTree = this.subscriptionTree
    }

    return sub.sub(callback)
  }
}
