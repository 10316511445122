import * as React from 'react'
import type { Attachments } from '../types/types'
import { Button, Modal } from '@upgrowth/react-fulcrum'
import './AttachmentsModal.scss'
import * as classnames from 'classnames'
import EditableProfilePicture from '../compositions/EditableProfilePicture'
import MentionsEditor from "./MentionsEditor";

type Props = { isOpen: boolean, onRequestClose: () => void, value: Attachments, onChange: (Attachments) => void }


const ImageAttachmentEditor = ({value, onChange}: {value?: string | null, onChange: (string | null) => void}) => (
  <div className='ImageAttachmentsEditor'>
    <EditableProfilePicture value={value} onChange={onChange}/>
    {value && <Button className='shape-pill' onClick={() => onChange(null)}>Clear</Button>}
  </div>
)


const mentionsTab = 'mentions'
const imageTab = 'image'

export default class AttachmentsModal extends React.Component<Props> {
  state = {tab: 'mentions'}

  render () {
    const {onRequestClose, value, onChange, isOpen} = this.props
    const {tab} = this.state
    return (
      <Modal className='Modal AttachmentsModal' isOpen={isOpen} onRequestClose={onRequestClose} closeElement={<>&times;</>}>
        <div className='tabs'>
          <div onClick={() => this.setState({tab: imageTab})} className={classnames('tab', {active: tab === imageTab})}>Add an Image</div>
          <div onClick={() => this.setState({tab: mentionsTab})} className={classnames('tab', {active: tab === mentionsTab})}>Tag a Friend</div>
        </div>

        <div className='editor'>
          {tab === mentionsTab && <MentionsEditor value={(value || {}).mentions}
                                                  onChange={mentions => onChange({...(value || {}), mentions})} />}
          {tab === imageTab && <ImageAttachmentEditor value={(value || {}).image}
                                               onChange={image => onChange({...(value || {}), image})} />}
        </div>
        <div className='actions'>
          <Button className='shape-pill variant-secondary' onClick={onRequestClose}>Done</Button>
        </div>
      </Modal>
    )
  }
}
