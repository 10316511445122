// @flow

import * as React from 'react'
import UnreadMessageCount, { UpdatedThreadCount } from './UnreadMessageCount'

type UnreadBadgeProps = {
  sourceType: 'user' | 'group',
  sourceId: string
}

const UnreadBadge = (props: UnreadBadgeProps) => {
  const As = props.sourceType === 'group' ? UpdatedThreadCount : UnreadMessageCount
  return (
    <As {...props} render={count =>
      count < 1
        ? null
        : <span className="UnreadBadge">{count < 10 ? '0' : ''}{count}</span>
    } />
  )
}

export default UnreadBadge
