import React from 'react'
import FroalaEditorComponent from 'react-froala-wysiwyg'

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'

// Plugins
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/char_counter.min.js'

// Configuration
import FROALA_CONFIG from './config'

function Editor({ body, handleChange, maxCharacterCount }) {
  return (
    <FroalaEditorComponent
      tag="textarea"
      model={body}
      onModelChange={handleChange}
      config={{ ...FROALA_CONFIG, charCounterMax: maxCharacterCount }}
    />
  )
}

Editor.defaultProps = {
  maxCharacterCount: 512
}
export default Editor
