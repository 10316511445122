// @flow

import React from 'react'
import Tab from '../compositions/Tab'
import Tabs from '../compositions/Tabs'
import GroupPreview from '../compositions/GroupPreview'
import { Button, FormRow } from '@upgrowth/react-fulcrum'
import { map } from 'lodash'
import { setOnboarded } from '../services/users'
import type { History } from 'react-router-dom'

import GroupsMagazine from '../groups/GroupsMagazine'
import { firebind } from '../services/database'

const TopTenGroups = firebind(
  ({ topTenInterestGroups }) => (
    <div className="TopTenGroups">
      {map(topTenInterestGroups, (group, groupId) => (
        <GroupPreview groupId={groupId} key={groupId} />
      ))}
    </div>
  ),
  {
    topTenInterestGroups: () => ({
      ref: 'groups/count/interest',
      asArray: false,
      queries: {
        orderByChild: 'count',
        limitToLast: 10
      }
    })
  }
)

const leader = (
  <h5>
    Here are some popular interest groups. Find more using the search, or add
    more later.
  </h5>
)

export default firebind(({ history }: { history: History }) => {
  return (
    <div className="OnboardingGroupsInterest">
      <Tabs>
        <Tab>1</Tab>
        <Tab active>2</Tab>
      </Tabs>

      <GroupsMagazine
        render={GroupPreview}
        emptyQueryRender={() => <TopTenGroups />}
        leader={leader}
      />

      <FormRow className="nextBtn">
        <Button
          variant="secondary"
          onClick={() => setOnboarded().then(() => history.push('/'))}
          name="groups"
        >
          Finish
        </Button>
      </FormRow>
    </div>
  )
})
