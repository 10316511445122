import { Button } from '@upgrowth/react-fulcrum'
import React from 'react'
import { deleteReply, deleteThread, deleteUserMessage, report } from '../services/messaging'
import { setGlobalModerator } from '../services/users'

export const ReportButton = ({sourceType, sourceId, messageId, threadId}) =>
  <Button onClick={() => report(messageId, sourceType, sourceId, threadId)} className="shape-pill ban active">Report</Button>

export const DeleteButton = ({sourceType, sourceId, messageId, threadId, to}) =>
  <Button
    className="shape-pill ban active"
    onClick={() => {
      console.log("Deleting post", sourceType, sourceId, messageId, threadId)
      return sourceType === 'thread'
        ? deleteReply(messageId, threadId, sourceId)
        : (sourceType === 'group'
            ? deleteThread(messageId, sourceId)
            : deleteUserMessage(messageId, sourceId, to)
        )
    }}
  >Delete Message</Button>

  export const PromoteGlobalModButton = ({userId}) =>
  <Button
    className="shape-pill ban active"
    onClick={() => {
      if (!userId) console.warn('No user id provided to promote global mod')
      setGlobalModerator(userId, true)
    }}
  >
    Promote to Global Mod
  </Button>

export const DemoteGlobalModButton = ({userId}) =>
  <Button
    className="shape-pill ban active"
    onClick={() => {
      if (!userId) console.warn('No user id provided to demote global mod')
      setGlobalModerator(userId, false)
    }}
  >
    Remove Global Mod status
  </Button>