// @flow
import React, { useState, useEffect } from 'react'
import { withUserId } from '@upgrowth/firekit'
import { firebind } from '@upgrowth/firekit'

import EventsList from './EventsList'

function UpcomingEvents({ events, userId }) {
  const [upcomingEvents, setUpcomingEvents] = useState()

  useEffect(() => {
    if (events) {
      setUpcomingEvents(
        Object.keys(events)
          .map(id => events[id])
          .filter(event => event && new Date(event.time).getTime() > Date.now())
      )
    }
  }, [events])

  return (
    <EventsList
      title="Upcoming events"
      emptyMsg="No upcoming events"
      events={upcomingEvents}
      loading={!Boolean(upcomingEvents)}
      userId={userId}
      upcomingFirst
    />
  )
}

export default withUserId(firebind(UpcomingEvents, { events: 'events' }))
