// @flow

import React from 'react'
import ProfilePicture from './ProfilePicture'
import Dropzone from 'react-dropzone'
import { uploadProfilePic } from '../services/users'

type Props = {
    value: string,
    onChange: (string) => any
}

type State = {
    loading: boolean
}

class EditableProfilePicture extends React.Component<Props, State> {
    props: Props
    state = {
        loading: false
    }

    upload = async (files: Array<File>) => {
        const {onChange} = this.props
        this.setState({loading: true})
        const file = files[0]
        const url = await uploadProfilePic(file)
        this.setState({loading: false})
        url && onChange(url)
    }

    render() {
        const {loading} = this.state
        const {value} = this.props


        let text = <b style={{color: 'white'}}>Update</b>
        if (!value) {
            text = <b>Choose a photo</b>
        } else if (loading) {
            text = "Uploading..."
        }

        return (
            <div className="EditableProfilePicture">
                <Dropzone className="Dropzone" onDrop={this.upload} multiple={false} accept="image/jpeg, image/png">
                    <ProfilePicture photoURL={value}>
                        <div>{text}</div>
                    </ProfilePicture>
                </Dropzone>
            </div>
        )
    }

}

export default EditableProfilePicture