// @flow

import React from "react";
import Avatar from "../compositions/Avatar";
import {firebind} from "@upgrowth/firekit";
import {Link} from "@upgrowth/reactkit"
import './Mention.scss'

const Mention = ({userId, profile = {}, showName}) => {
  const { name, firstName, lastName } = profile;
  return <Link className='Mention' name='friend' friendId={userId} onClick={(e) => e.stopPropagation()}>
    <Avatar {...profile} />
    {showName && (
      <div className='name'>
        {name || [firstName, lastName].filter(x => x).join(' ') || 'A Person'}
      </div>
    )}
  </Link>
}

export default firebind(Mention, {
  profile: ({userId}) => `users/${userId}/profile`
})