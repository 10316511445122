export default {
  YOUTUBE_REGEX: /(http(?:s?):\/\/|)(?:www\.)?(youtu|y2u)?(?:be\.com\/(watch|playlist)\?(v|list)=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/,
  HOSTNAMES: {
    SHORT: "youtu.be",
    SHORT_Y2U: "y2u.be"
  },
  PATHNAMES: {
    VIDEO: "/watch",
    PLAYLIST: "/playlist"
  },
  SEARCHPARAMS: {
    VIDEO: "v",
    PLAYLIST: "list"
  },
  TYPES: {
    VIDEO: "video",
    PLAYLIST: "playlist"
  }
};
