// @flow

import React, { useEffect, useState } from 'react'
import type { History } from 'react-router-dom'
import TagsInput from 'react-tagsinput'
import * as classnames from 'classnames'
import { isEmpty, map, values } from 'lodash'
import { FaWindowClose } from 'react-icons/fa'
import { Button, Field, Form, Select } from '@upgrowth/react-fulcrum'
import { withParams } from '@upgrowth/reactkit/lib/router/index'

import { updateGroupDetails } from '../services/groups'
import { firebind } from '../services/database'
import Screen from '../compositions/Screen'
import SectionNav from '../compositions/SectionNav'
import EditableProfilePicture from '../compositions/EditableProfilePicture'

import type { GroupDetails, GroupId } from '../types/types'
import { GroupTypes } from '../types/types'

import 'react-tagsinput/react-tagsinput.css'
import './GroupDetailsEditor.scss'

type Props = { groupId: GroupId, details: GroupDetails, history: History }

// Deafult group details
const DEFAULT_DETAILS = {
  name: '',
  type: 'interest',
  description: '',
  hidden: false,
  sortKey: 'default',
  tags: []
}

// Map all available group types
const GROUP_TYPE_OPTIONS = map(GroupTypes, (label, value) => ({ label, value }))

/**
 * Edit a group's details with this component.
 */
const GroupDetailsEditor = (props: Props) => {
  const { loading, sortKeys, groupId, history } = props

  const [details, setDetails] = useState<GroupDetails>(DEFAULT_DETAILS)
  const [error, setError] = useState([])
  const [sortKeyLabels, setSortKeyLabels] = useState([])

  const isCreatingGroup = isEmpty(props.details)
  const title = isCreatingGroup ? 'Create' : 'Edit'

  useEffect(() => {
    if (!loading && !isEmpty(sortKeys)) {
      // Cache a copy of the sort keys
      setSortKeyLabels(
        sortKeys.map(sortKey => ({
          label: sortKey,
          value: sortKey
        }))
      )
    }
  }, [loading, sortKeys, setSortKeyLabels])

  useEffect(() => {
    // Load default values if they exist
    if (!loading && !isEmpty(props.details)) {
      setDetails(props.details)
    }
  }, [loading, props.details, setDetails])

  // Update a group field in the local state
  const updateField = (field: $Keys<GroupDetails>) => (value: *) => {
    setDetails(details => ({
      ...details,
      [field]: value
    }))
  }

  // Save the group details
  const submit = async () => {
    let valid = true
    const errors = []

    // Validation

    if (!details.name) {
      valid = false
      errors.push('name')
    }

    // If more validation is needed, add it here.

    if (!valid) {
      setError(errors)
      console.error(`Required fields missing: ${errors.join(', ')}`)
      return
    }

    await updateGroupDetails(groupId, details)
    history.push(`/groups/${groupId}`)
  }

  const {
    name,
    type,
    description,
    photo,
    tags,
    hidden = false,
    sortKey = 'default'
  } = details

  return (
    <Screen
      header={
        <SectionNav
          historyBack
          header={
            <span>
              <strong>{title} Chat Group</strong>
            </span>
          }
        />
      }
      className="GroupDetailsEditor"
    >
      <Form onSubmit={submit}>
        <EditableProfilePicture value={photo} onChange={updateField('photo')} />
        <div className="text-info">
          <h3>Group ID: {groupId}</h3>
          <h5 className="field-heading">
            Choose a name and description that will make your group easy to
            identify.
          </h5>
          <Field
            type="text"
            value={name}
            onChange={updateField('name')}
            label="Name *"
            placeholder="Name the group (2-3 words)"
          />
          <Field
            type="textarea"
            value={description}
            onChange={updateField('description')}
            label="Description *"
            placeholder="Describe the group. You can add a description and links."
          />
          <Field
            type="radiogroup"
            label="Group Type"
            value={type}
            onChange={updateField('type')}
            options={GROUP_TYPE_OPTIONS}
          />

          <h5 className="field-heading">
            {isCreatingGroup ? (
              `Should this group be hidden?`
            ) : hidden ? (
              <>
                This is a <b>HIDDEN</b> group.
              </>
            ) : (
              <>
                This is a <b>PUBLIC</b> group.
              </>
            )}
          </h5>
          <p>
            {isCreatingGroup
              ? `This setting cannot be changed once group has been created.`
              : `This setting was decided when the group was created and cannot be changed.`}
          </p>
          {isCreatingGroup && (
            <Field
              type="switch"
              onChange={updateField('hidden')}
              value={hidden}
              label={hidden ? 'Hidden group' : 'Public group'}
            />
          )}

          <h5 className="field-heading">
            Select how this group should be sorted for users.
          </h5>
          <p>
            You can add new sort keys and change the sorting order of these sort
            keys in the admin panel.
          </p>
          <Field label="Sort key">
            <Select
              options={sortKeyLabels}
              value={sortKey}
              // This flag doesn't seem to work.
              // See https://react-select.com/props
              // Workarounds:
              // - don't update the field if the user tries to clear the field.
              // - hide the clear icon with css (through the .Select-clear class)
              isClearable={false}
              onChange={value => value && updateField('sortKey')(value)}
            />
          </Field>

          <h5 className="field-heading">
            Add tags so that people can search for your group.
          </h5>
          <p>To add a tag type a word and press enter.</p>
          <Field label="Tags">
            <TagsInput
              renderTag={Tag}
              value={Array.isArray(tags) ? tags : values(tags)}
              inputProps={{ placeholder: '' }}
              onChange={updateField('tags')}
              addKeys={[9, 13, 188, 59]}
            />
          </Field>

          <div className="spread-footer">
            <Button onClick={submit} className="shape-pill" variant="secondary">
              Save group
            </Button>
            {error && error.length > 0 && (
              <p className="error">
                <b>Required: </b> {error.join(', ')}
              </p>
            )}
          </div>
        </div>
      </Form>
    </Screen>
  )
}

const Tag = ({
  tag,
  key,
  disabled,
  onRemove,
  classNameRemove,
  getTagDisplayValue,
  ...other
}) => (
  <span className="Tag" key={key} {...other}>
    <span className="tag-label">{getTagDisplayValue(tag)}</span>

    {!disabled && (
      <Button
        variant="light"
        size="small"
        className={classnames(classNameRemove, 'shape-round outline')}
        onClick={e => onRemove(key)}
      >
        <FaWindowClose />
      </Button>
    )}
  </span>
)

export default withParams(
  firebind(GroupDetailsEditor, {
    details: ({ groupId }) => `groups/details/${groupId}`,
    sortKeys: `groups/sortOrder`
  })
)
