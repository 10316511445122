import * as React from 'react'
import Screen from '../compositions/Screen'
import SectionNav from '../compositions/SectionNav'
import UnreadBadge from "../messages/UnreadBadge";
import './UsersMagazine.scss'
import UsersMagazine, {UserPhoneCard} from './UsersMagazine'
import {withParams} from '@upgrowth/reactkit/lib/router/index'
import UnreadMessageCount from '../messages/UnreadMessageCount'
import type {UserId} from '../types/types'
import {UserMagazineCard} from './UserMagazineCard'
import {FriendRoles} from '../types/types'
import {pickBy} from 'lodash'
import {Badge} from '@upgrowth/react-fulcrum'
import { firebind, withUserId } from '../services/database'


const HideIfRead = ({userId, friendId, children}: {userId: UserId, children: React.Children}) => (
  <UnreadMessageCount userId={userId} sourceId={friendId} sourceType="user" render={count => count > 0 ? children : null}/>
)

const FriendsMagazine = ({friends, hideNav, userId}) => (
    <Screen header={!hideNav && <SectionNav backRoute="friends" header="Friends with Unread Messages"/>}>
      <UsersMagazine users={pickBy(friends, (role) => role === FriendRoles.friend)}
                     phoneRender={ props => <HideIfRead userId={userId} friendId={props.userId}><UserPhoneCard {...props}/></HideIfRead> }
                     desktopRender={ props => <HideIfRead userId={userId} friendId={props.userId}><UserMagazineCard {...props}/></HideIfRead> }

                     actionProvider={friendId =>
                       <Badge className="unread-badge"><UnreadBadge sourceId={friendId} sourceType="user"/></Badge>
                     }/>
    </Screen>
)

export default withParams(withUserId(firebind(FriendsMagazine, {
  friends: ({userId}) => `users/${userId}/friends`
})))
