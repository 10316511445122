// @flow

import * as React from 'react'
import { Button, Flex, Form, FormRow } from '@upgrowth/react-fulcrum'
import { Link } from '@upgrowth/reactkit'
import { FaAngleDoubleRight } from 'react-icons/fa'

import EditableProfilePicture from '../compositions/EditableProfilePicture'
import withProfile from '../services/withProfile'
import { setAvatar, setName } from '../services/users'
import { currentUser } from '../services/database'
import SafetyField from '../elements/SafetyField'
import { UserProfile } from '../types/types'

import OnboardingTabs from './OnboardingTabs'

import './Onboarding.scss'

class OnboardPicture extends React.Component<{
  profile: UserProfile,
  loading: boolean
}> {
  componentDidUpdate(prevProps) {
    if (this.isProfileLoaded(prevProps)) {
      this.setDefaultAvatar()
    }
  }

  isProfileLoaded = (prevProps): boolean => {
    return !prevProps.profile && this.props.profile
  }

  setDefaultAvatar = () => {
    const {
      profile: { avatar }
    } = this.props
    const user = currentUser()

    if (!avatar && user.photoURL) {
      setAvatar(user.photoURL)
    }
  }

  //https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg
  //https://lh4.googleusercontent.com/-QUkxRbzZMZw/AAAAAAAAAAI/AAAAAAAAAAA/AAnnY7pZNTgqqyMvZmYIso_2IOxyyoo-1Q/mo/photo.jpg

  render() {
    const { profile: { avatar, name, firstName, lastName } = {} } = this.props

    return (
      <div className="Picture">
        <OnboardingTabs currentTab="OnboardPicture" />
        <h5>Add a profile picture.</h5>
        <p>
          You can change you preferred name and profile picture at any time.
        </p>
        <Form onSubmit={this.next}>
          <EditableProfilePicture value={avatar} onChange={setAvatar} />
          <p />
          <FormRow>
            <SafetyField
              label="Your preferred name"
              placeholder="Preferred name"
              value={name}
              onChange={value => {
                // If the user enters an empty name, reset to use system preferred name
                // If the user does not have a first name, fallback to their last name
                if (value === '') setName(firstName || lastName, 'system')
                // Otherwise, set the preferred name and make the mode "custom"
                else setName(value, 'custom')
              }}
            />
          </FormRow>
          <h5 className="help-text">
            Your preferred name and profile picture will be visible to everyone.
            This is how you will be recognised, so use your name or your nick
            name.
          </h5>
          <Flex />
          <FormRow className="nextBtn">
            <Button
              as={Link}
              variant="secondary"
              name="onboardGroupsLocationMonash"
            >
              Next <FaAngleDoubleRight className="nextBtnIcon" />
            </Button>
          </FormRow>
        </Form>
      </div>
    )
  }
}

export default withProfile(OnboardPicture)
