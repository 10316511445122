// @flow

import React, { useState, useCallback, useEffect } from 'react'
import { Modal, Input, Button } from '@upgrowth/react-fulcrum'

import { firebind, setDB, updateDB } from '../../services/database'
import OrderableListItem from '../../compositions/OrderableListItem'

const DEFAULT_KEY = 'default'

/**
 * This component renders a sort key and provides
 * controls to re-order or delete the sort key.
 */
const SortKey = ({ value, children, onDelete }) => {
  return (
    <div className="SortKey">
      {value}
      <div className="actions">
        {// Don't show the delete button if it is the default key
        value !== DEFAULT_KEY && (
          <span className="delete" onClick={onDelete}>
            delete
          </span>
        )}
        {children}
      </div>
    </div>
  )
}

/**
 * This modal displays the current order of the sort keys.
 * The user can re-order the sort keys, delete or add new
 * sort keys.
 */
const SortOrderModal = ({
  open = false,
  onClose = () => {},
  loading,
  sortOrder = []
}) => {
  const [value, setValue] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    // Clear the error when the value is updated
    setError('')
  }, [value, setError])

  // This function sets the sort keys to the given list.
  const setSortOrder = useCallback(list => {
    setDB(`groups/sortOrder`, list)
  }, [])

  // This function allows the user to create a new key.
  const createNewKey = async () => {
    // Don't allow duplicate keys
    if (sortOrder.includes(value)) {
      setError(`The sort key "${value}" already exists.`)
      return
    }

    // Append the new key to the end of the list
    setValue('')
    await updateDB(`groups/sortOrder`, {
      [sortOrder.length]: value
    })
  }

  // This function removes the key at the specified index.
  const deleteKey = (index: number) => () => {
    const newOrder = [...sortOrder]
    newOrder.splice(index, 1)
    setDB(`groups/sortOrder`, newOrder)
  }

  return (
    <Modal
      className="SortOrderModal main"
      isOpen={open}
      onRequestClose={onClose}
      appElement={document.body}
      closeElement="Close"
    >
      <h1>Manage sort order</h1>
      <p>
        Each group can be assigned a <b>sort key</b>. Groups are sorted
        according to their sort key in the order defined here.
      </p>
      <p>
        Groups belonging to the same sort key will be sorted alphabetically.
      </p>
      <p>
        <b>IMPORTANT: Changes take effect in real time.</b>
      </p>
      <div className="list">
        {sortOrder.map((value, index) => (
          <OrderableListItem
            key={value}
            getId={v => v}
            data={sortOrder[index]}
            display={SortKey}
            displayProps={{
              value,
              onDelete: deleteKey(index)
            }}
            dataList={sortOrder}
            setDataList={setSortOrder}
          />
        ))}
      </div>
      <div className="new-key">
        <Input
          placeholder="New sort key..."
          value={value}
          onChange={setValue}
        />
        {error && <p className="error">{error}</p>}
        <Button onClick={createNewKey} disabled={!Boolean(value)}>
          Create sort key
        </Button>
      </div>
    </Modal>
  )
}

export default firebind(SortOrderModal, {
  sortOrder: `groups/sortOrder`
})
