// @flow

import * as React from 'react'
import Tabs from '../compositions/Tabs'
import Tab from '../compositions/Tab'

class OnboardingTabs extends React.Component {
  props: {
    currentTab: ''
  }

    render() {
      const tabsList = ['OnboardProfile', 'OnboardPicture', 'OnboardLocation'];

      const tabs = tabsList.map((item, index) =>
          <Tab key={index} active={this.props.currentTab === item}>{index+1}</Tab>
      );
      
      return (
          <Tabs>
            {tabs}
          </Tabs>
        )
    }
}

export default OnboardingTabs