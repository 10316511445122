// @flow

import React from 'react'
import Screen from '../compositions/Screen'
import {filter, zipObject} from 'lodash'
import AddFriendButton from '../compositions/AddFriendButton'
import SectionNav from '../compositions/SectionNav'
import type {Location} from 'react-router-dom'
import {withParams} from '@upgrowth/reactkit/lib/router/index'
import {PlaceName} from '../services/geocoding'
import qs from 'qs'
import './StudentCluster.scss'
import UsersMagazine from '../friends/UsersMagazine'
import type {UserId} from '../types/types'
import { withUserId } from '../services/database'

export default withParams(withUserId((({userId, location: {search, state: {userIds, groupName = 'Students'}}}: { userId: UserId, location: Location }) => {
  const {lat, lng, zoom} = qs.parse(search, {ignoreQueryPrefix: true})

  userIds = filter(userIds, id => id !== userId)
  console.log("Got lat lng zoom", lat, lng, zoom)
  return (
    <Screen className="StudentCluster"
            header={
              <SectionNav historyBack header={
                <div className="Cluster-header">
                  <div className="group-name">{groupName} in:</div>
                  <div className="place-name"><PlaceName {...{lat, lng, zoom}}/></div>
                </div>
              }/>}>
      <UsersMagazine users={zipObject(userIds, userIds)} actionProvider={friendId => friendId !== userId && <AddFriendButton friendId={friendId}/>}/>
    </Screen>
  )
})))