// @flow


import { firebind, withUserId } from './database'

export default withUserId(firebind(
  ({isAdmin, children}) => (isAdmin === true && children)
  , {
    isAdmin: ({userId}) => `users/${userId}/standing/admin`
  }
))