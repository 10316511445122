// @flow

import React, { useState } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { Modal } from '@upgrowth/react-fulcrum'

import { firebind } from '../../services/database'

const LIMIT = 10

/**
 * This function concatenates a number and the correct
 * word depending on whether the word should be plural
 * or not.
 *
 * By default, an "s" is added to the word to create the
 * plural form. Pass in a third argument to give a
 * custom plural form.
 */
const pluralise = (
  count: number,
  singular: string,
  plural?: string = `${singular}s`
) => {
  if (count === 1) return `${count} ${singular}`
  return `${count} ${plural}`
}

/**
 * This modal shows the 10 most recent files that were
 * uploaded for processing.
 */
const ProcessQueueModal = ({
  open = false,
  onClose = () => {},
  files = {}
}) => {
  const [showInfo, setShowInfo] = useState(false)
  const [info, setInfo] = useState()

  /**
   * This function sets the title and data to show in the info modal
   */
  const openInfo = (title: string, data: any) => {
    setShowInfo(true)
    setInfo({ title, data })
  }

  return (
    <Modal
      className="ProcessQueueModal main"
      isOpen={open}
      onRequestClose={onClose}
      appElement={document.body}
      closeElement="Close"
    >
      <h1>Processing queue</h1>
      <p>
        This queue shows the 10 most recent CSV files that have been uploaded
        for processing by the system.
      </p>
      {Object.keys(files).length === 0 ? (
        <p className="no-file">No files uploaded for processed</p>
      ) : (
        <div className="Files">
          {Object.keys(files)
            .reverse()
            .map(key => files[key])
            .map(
              ({
                key,
                type,
                filename,
                location,
                status,
                startedAt,
                finishedAt,
                successes = {},
                warnings = {},
                errors = [],
                error
              }) => {
                const start = moment(new Date(startedAt))
                const finished = finishedAt && moment(new Date(finishedAt))
                const inProgress = ['PARSING', 'PROCESSING'].includes(status)

                return (
                  <div key={key} className="File">
                    <div className="heading">
                      <div>
                        <div className="title">
                          <b>{filename}</b>
                        </div>
                        <div className="subtitle">Job ID: {key}</div>
                      </div>
                      <div>
                        <b
                          className={clsx('status', {
                            inProgress,
                            processed: status === 'PROCESSED',
                            failed: status === 'FAILED'
                          })}
                        >
                          {status || 'UNKNOWN'}
                        </b>
                      </div>
                    </div>
                    <p>
                      <b>{type}</b>
                    </p>
                    <p>
                      {finished ? (
                        <>
                          Finished {finished.fromNow()} (took{' '}
                          {finished.diff(start, 'seconds', true)} seconds)
                        </>
                      ) : (
                        <>Started {start.fromNow()}</>
                      )}
                    </p>
                    {error && <div className="error">{error}</div>}{' '}
                    {status && !inProgress && (
                      <div className="buttons">
                        <span onClick={() => openInfo('Successes', successes)}>
                          {pluralise(
                            Object.keys(successes).length,
                            'success',
                            'successes'
                          )}
                        </span>
                        <span onClick={() => openInfo('Warnings', warnings)}>
                          {pluralise(Object.keys(warnings).length, 'warning')}
                        </span>
                        <span
                          onClick={() =>
                            openInfo(
                              'Errors',
                              // Convert errors array to an object
                              errors.reduce(
                                (acc, cur, idx) => ({ ...acc, [idx]: cur }),
                                {}
                              )
                            )
                          }
                        >
                          {pluralise(Object.keys(errors).length, 'error')}
                        </span>
                      </div>
                    )}
                  </div>
                )
              }
            )}
        </div>
      )}
      {info && (
        <Modal
          className="ProcessQueueModal info"
          isOpen={showInfo}
          onRequestClose={() => setShowInfo(false)}
          appElement={document.body}
          closeElement="Close"
        >
          <h3>{info.title}</h3>
          {Object.keys(info.data).length === 0 && (
            <p className="subtitle">None</p>
          )}
          <ul className="list">
            {Object.keys(info.data).map(key => (
              <li key={key}>
                <p>{info.data[key].message}</p>
                <p className="code">{info.data[key].type}</p>
              </li>
            ))}
          </ul>
        </Modal>
      )}
    </Modal>
  )
}

export default firebind(ProcessQueueModal, {
  // Get the 10 latest file processing objects
  files: {
    ref: `files`,
    queries: {
      orderByChild: 'startedAt',
      limitToLast: LIMIT
    }
  }
})
