// @flow

import * as React from 'react'
import { isEqual } from 'lodash'
import { Link, Redirect, Route, Switch } from '@upgrowth/reactkit/lib/router'
import { Button, Modal } from '@upgrowth/react-fulcrum'

import {
  confirmTermsAccepted,
  confirmUpdatedTermsAccepted,
  setOnboarded
} from '../services/users'
import { firebind, withUserId } from '../services/database'
import Screen from '../compositions/Screen'

import OnboardPicture from './Picture'
import OnboardProfile from './Profile'
import { activeWrapperRender, Spread } from './Spread'
import OnboardGroupsLocationMonash from './OnboardGroupsLocationMonash'
import OnboardGroupsInterest from './OnboardGroupsInterest'
import TermsAndConditionsCheckbox from './TermsAndConditionsCheckbox'

const DetailSpread = () => (
  <Spread
    header={<h2>Let’s get you started with your profile.</h2>}
    footer={
      <Button
        as={Link}
        onClick={confirmTermsAccepted}
        className="shape-pill"
        variant="secondary"
        name="onboardGroupsLocationMonash"
      >
        Confirm details
      </Button>
    }
    screens={{
      onboardProfile: activeWrapperRender(OnboardProfile),
      onboardPicture: activeWrapperRender(OnboardPicture)
    }}
  />
)

const GroupSpread = ({ history }) => (
  <Spread
    className="GroupSpread"
    header={
      <h2>Join chat groups to meet other students and people like you.</h2>
    }
    footer={
      <Button
        onClick={() => setOnboarded().then(() => history.push('/'))}
        className="shape-pill"
        variant="secondary"
        name="groups"
      >
        I’m finished
      </Button>
    }
    screens={{
      onboardGroupsLocationMonash: activeWrapperRender(
        OnboardGroupsLocationMonash
      ),
      onboardGroupsInterest: activeWrapperRender(OnboardGroupsInterest)
    }}
  />
)

const Onboarding = ({ loading, onboarded, children, termsUpdated }) => {
  if (loading) {
    return <Screen></Screen>
  }

  if (onboarded && !isEqual(onboarded, {}) && children) {
    return (
      <Switch>
        <Route name="onboarding">{React.Children.only(children)}</Route>
        <Route>
          <>
            {React.Children.only(children)}
            <Modal
              className="Modal UpdatedTermsModal"
              isOpen={termsUpdated && onboarded < termsUpdated}
              onRequestClose={() => {}}
              closeElement=""
            >
              <h3>Terms and Conditions Updated</h3>
              <p>
                To continue to use the platform, you must read and accept them.
              </p>
              <p>
                <TermsAndConditionsCheckbox />
              </p>
              <div className="actions">
                <Button
                  className="shape-pill"
                  onClick={confirmUpdatedTermsAccepted}
                >
                  Continue
                </Button>
              </div>
            </Modal>
          </>
        </Route>
      </Switch>
    )
  }

  console.log('Failed onboarding', loading, onboarded)
  return (
    <Screen className="Onboarding">
      <Switch>
        <Route name="onboardProfile" component={DetailSpread} />
        <Route name="onboardPicture" component={DetailSpread} />
        <Route name="onboardGroupsLocationMonash" component={GroupSpread} />
        <Route name="onboardGroupsInterest" component={GroupSpread} />
        <Route>
          <Redirect name="onboardProfile" />
        </Route>
      </Switch>
    </Screen>
  )
}

export default withUserId(
  firebind(Onboarding, {
    onboarded: ({ userId }) => `users/${userId}/onboarded`,
    termsUpdated: `public/updatedTerms`
  })
)
