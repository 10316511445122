// @flow

import * as React from 'react'
import '../compositions/Profile.scss'
import type {Group} from '../types/types'
import './GroupProfile.scss'
import Linkify from 'react-linkify'

// const nameLabels = {
//   interest: 'Name of interest',
//   location: 'Name of location',
//   monash  : 'Name of group'
// }

const descriptionLabels = {
  interest: 'Interest description',
  location: 'Location information',
  monash  : 'Group information'
}

const linkProperties = {
  target: '_blank',
  rel: "noopener noreferrer nofollow"
}


const gradient = `linear-gradient(to bottom, rgba(68, 61, 55, 0.75), rgba(68, 61, 55, 0.75))`

export const backgroundWithGradient = (photo: string) => ({backgroundImage: `url(${photo}), ${gradient}`, backgroundBlendMode: 'multiply', backgroundSize: 'cover', backgroundPosition: 'center'})
const GroupProfile = ({count, details: {name, description, photo, type} = {}, children}: { details: $PropertyType<Group, 'details'> }) => (
  <div className="GroupProfile" style={backgroundWithGradient(window.rewriteUrl(photo))}>
    <div className="profile-data">
      <div className="group-name-count">
        {/*<label className="profile-label">{nameLabels[type]}</label>*/}
        <div className="group-name-text">{name}</div>
        {typeof count === 'number' && <div className='member-count'>{count} Member{count === 1 ? '': 's'}</div>}
      </div>

      <div className="group-description">
        <label className="profile-label">{descriptionLabels[type]}</label>
        <Linkify className='text' properties={linkProperties}>
          {description || <em>No description set</em>}
        </Linkify>
      </div>

      <div className='actions'>
        {children}
      </div>
    </div>
  </div>
)

export default GroupProfile