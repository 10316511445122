// @flow

import type { GroupDetails, GroupRole, GroupType } from '../types/types'
import React from 'react'
import { Button } from '@upgrowth/react-fulcrum'
import { isEmpty, isNumber } from 'lodash'
import './GroupPreview.scss'
import { GroupRoles, MODERATORS_GROUP_ID } from '../types/types'
import { FaCheck, FaSpinner } from 'react-icons/fa'
import * as classnames from 'classnames'
import { Link } from '@upgrowth/reactkit'
import { firebind, withUserId } from '../services/database'
import { joinGroup, leaveGroup } from '../services/groups'
import SmallCard from "./SmallCard";

type Props = {
  groupId: string,
  groupDetails?: GroupDetails,
  count: number,
  defaults?: { name: string, type: GroupType },
  linked?: boolean,
  create?: boolean,
  loading: boolean,
  membershipRole?: GroupRole | {}
}

class GroupPreview extends React.Component<Props, *> {
  state = { joining: false }
  joined = false

  async UNSAFE_componentWillReceiveProps(props: Props) {
    if (props.loading) { return }
    props.create && this.join(props)
  }

  join = (props: Props = this.props) => {
    const { create, defaults = {}, groupId, membershipRole } = props
    if (this.joined || this.state.joining || !isEmpty(membershipRole)) { return }

    this.setState({ joining: true }, async () => {
      await (create ? joinGroup(groupId, defaults.name, defaults.type) : joinGroup(groupId))
      this.setState({ joining: false })
      this.joined = true
    })
  }

  leave = () => {
    const { groupId } = this.props
    this.setState({ joining: true }, async () => {
      await leaveGroup(groupId)
      this.setState({ joining: false })
      this.joined = false
    })
  }

  render() {
    const { groupDetails: { name, photo } = {}, defaults = {}, count, linked, membershipRole, groupId, isAdmin } = this.props
    // console.log("Rendering group preview", this.props)
    if (isAdmin !== true && isEmpty(membershipRole) && groupId === MODERATORS_GROUP_ID) { return null }

    let action = (
      <Button className="shape-round outline join-group-button" variant="neutral" onClick={() => this.join()}><div>+</div></Button>
    )

    if (this.state.joining) {
      action = <div className="joined-indicator joining"><FaSpinner /></div>
    }

    if (membershipRole === GroupRoles.user || membershipRole === GroupRoles.mod) {
      action = <Button onClick={this.leave} className="shape-round joined-indicator"><FaCheck /></Button>
    }

    const link = linked ? { as: Link, name: 'group', groupId } : {}

    return (
      <SmallCard {...link} className={classnames("GroupPreview", membershipRole)}
        title={name || (defaults || {}).name || 'Group'}
        subtitle={`${isNumber(count) ? count : 0} members`}
        action={action}
        backgroundImage={{ backgroundImage: photo && `url(${window.rewriteImageUrl(photo)})` }}
      />
    )
  }
}

export default withUserId(firebind(
  GroupPreview, {
  isAdmin: ({ userId }) => userId && `users/${userId}/standing/admin`,
  membershipRole: ({ groupId, userId }) => groupId && userId && `users/${userId}/groups/${groupId}/role`,
  groupDetails: ({ groupId }) => groupId && `groups/details/${groupId}`,
  count: ({ groupId, groupDetails: { type = 'interest' } = {} }) => (type && groupId && `groups/count/${type}/${groupId}/count`)
}))
