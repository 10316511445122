// @flow

import * as React from 'react'
import Post from './Post'
import { isEmpty, keys } from 'lodash'
import { firebind } from '../services/database'

const LatestPost = ({ groupId, latestThreads, onChanged }) => {
  const [latestThread, setLatestThread] = React.useState(null)

  React.useEffect(() => {
    if (!isEmpty(latestThreads)) {
      const id = (keys(latestThreads) || [])[0]
      const data = id && latestThreads[id]
      onChanged(-data.updatedAt)
      setLatestThread({ id, data })
    }
  }, [latestThreads, setLatestThread, onChanged])

  return latestThread ? (
    <Post groupId={groupId} postId={latestThread.id} post={latestThread.data} />
  ) : null
}

export default firebind(LatestPost, {
  latestThreads: ({ groupId }) => ({
    ref: `groups/threads/${groupId}`,
    queries: {
      orderByChild: 'updatedAt',
      limitToLast: 1
    }
  })
})
