import * as React from 'react'
import Screen from '../compositions/Screen'
import Profile from '../compositions/Profile'
import Section from './Section'
import { Button, Checkbox, Column } from '@upgrowth/react-fulcrum'
import { setLocationSharing, setUnsubscribed } from '../services/users'
import './ProfileScreen.scss'
import { logout } from '../services/auth'
import LocationUpdater from "./LocationUpdater";
import { firebind, withUserId } from '../services/database'

const ProfileScreen = ({profile, groups, secrets = {} }) => (
  <Section className="ProfileScreen">
    <Screen>
      <Profile profile={profile} groups={groups} editable>
        <div className='profile-actions'>
          <Column>
            <div><Checkbox label="Don't notify me of new activity" value={secrets.unsubscribed === true} onChange={setUnsubscribed} /></div>
            <div><Checkbox label="Share my current location" value={secrets.locationSharing === true} onChange={setLocationSharing}/></div>
            {
              secrets.locationSharing === true &&
              <LocationUpdater
                  renderError={(_, retry) => (
                      <div className="location-error">
                        <h3>Either your browser doesn't support location, or you've chosen not to share your location with us.</h3>
                        <Button variant='secondary' className='shape-pill retry-button' onClick={retry}>Retry</Button>
                      </div>
                  )}
                  render={() => null}
              />
            }
            <div>Other users will not see your exact location, only an approximation to about a sq. kilometre. You can change location sharing whenever you want.</div>
          </Column>
          <Column>
            <Button variant="light" className="outline signout-button" onClick={logout}>Signout</Button>
          </Column>
        </div>
      </Profile>
    </Screen>
  </Section>
)

export default withUserId(firebind(ProfileScreen, {
  profile: ({ userId }) => `users/${userId}/profile`,
  groups: ({ userId }) => `users/${userId}/groups`,
  secrets: ({ userId }) => `users/${userId}/secrets`
}))