import type {UserId, UserProfile} from '../types/types'
import MagazineCard from '../compositions/MagazineCard'
import type {ActionProvider} from './UsersMagazine'
import React from 'react'
import { firebind } from '../services/database'

type CardProps = { userId: UserId, profile?: UserProfile, actionProvider: ActionProvider }
export const UserMagazineCard = firebind(({userId, profile = {}, actionProvider}: CardProps) => {
    const { avatar, name, firstName, lastName, location } = profile;
    return (
      <MagazineCard className="UserMagazineCard"
                    picture={avatar}
                    title={name || [firstName, lastName].filter(x => x).join(' ') || 'A Person'}
                    data={location || ''}
                    badge={actionProvider(userId)}
                    route="friend"
                    friendId={userId}/>
    )
  },
  {
    profile: ({userId}) => `users/${userId}/profile`
  }
)