import * as classnames from 'classnames'
import React from 'react'
import './Magazine.scss'

const Magazine = ({className, phoneView, children, ...props}) => (
  <div className={classnames('Magazine', className)} {...props}>
    {React.Children.count(children) === 0 && (
      <div className="notice-section">
        <div className="notice">None found</div>
      </div>
    )}

    <div className={classnames("desktop-view", {alsoPhone: !phoneView})}>
      {children}
    </div>

    {phoneView &&
      <div className="phone-view">
        {phoneView}
      </div>
    }
  </div>
)
export default Magazine