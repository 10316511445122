// @flow

import {Button, Form, Input, Flex, FormRow} from '@upgrowth/react-fulcrum'
import Screen from '../compositions/Screen'
import React from 'react'
import './AddFriend.scss'
import { getUserByEmail } from '../services/friends'
import UsersMagazine from './UsersMagazine'
import AddFriendButton from '../compositions/AddFriendButton'
import type { History, Location } from 'react-router-dom'
import qs from 'qs'
import type {UserId} from '../types/types'
import SectionNav from "../compositions/SectionNav";

const SUFFIX = '@student.monash.edu'

type State = { emailDraft?: string, searchResult?: string, status?: 'searching' | 'displaying' | 'error'}


export default class AddFriend extends React.Component<{ location: Location, history: History, friendId: UserId }, State> {
  state: State = {}

  search = async () => {
    let email = this.state.emailDraft || qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).email || ''

    if (email.length > 0) {
      if (email.indexOf('@') === -1) { email += SUFFIX }

      this.setState({status: 'searching'})
      try {
        const searchResult = ((await getUserByEmail(email)) || {}).data
        this.setState({status: 'displaying', searchResult})  
        this.props.history.push(`?email=${email}`)
      }
      catch (error) {
        this.setState({status: 'error'})
      }
    }
  }

  render() {
    const {status, searchResult} = this.state
    const {location} = this.props
    const {email} = qs.parse(location.search, {ignoreQueryPrefix: true})

    let fieldValue = this.state.emailDraft
    if (typeof fieldValue !== 'string') { fieldValue = (email || "").replace(SUFFIX, "") }

    return (
      <Screen className="AddFriend" header={<SectionNav header="Add a friend by email" backRoute="friends"/>}>
        <p>If you already know someone you'd like to add, find out their Monash email address and type it here:</p>
        <Form className="input-with-suffix" onSubmit={this.search}>
          <FormRow className="email-line">
            <Input name="email" type="text" value={fieldValue || ''} onChange={(emailDraft) => this.setState({emailDraft: emailDraft || ''})}/>
            <div className="suffix">{SUFFIX}</div>
          </FormRow>
          <FormRow>
            <Flex/>
            <Button type="submit">Search</Button>
          </FormRow>
        </Form>

        {status === 'searching' && <div className="notice">Searching...</div>}
        {status === 'error' && <div className="notice">Not found</div>}
        {status === 'displaying' && searchResult &&
          <UsersMagazine users={{[searchResult]: 'Result'}} actionProvider={(userId) => (searchResult === userId) ? null : <AddFriendButton friendId={searchResult}/>} />
        }
        
      </Screen>
    )
  }
}

